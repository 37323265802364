import React from "react";
import FaChevronDown from 'react-icons/lib/fa/chevron-down';
import { Link } from 'react-router-dom';
import style from "./grid.scss";

export default class ImageGrid extends React.Component {
    constructor(props) {
        super(props);

        this.onRemoveClick = this.onRemoveClick.bind(this);
    }

    onRemoveClick(index){
        this.props.onImageRemoveClick(index);
    }


    render() {
        let onRemoveClick = this.onRemoveClick;
        return (
            <div className="p-4">
                {typeof(this.props.images) != typeof(undefined) && (this.props.images) != null && this.props.images.length > 0 &&
                    <div className="shadow p-2">
                        <div className="grid-container">
                            {this.props.images.map(function name(image, index) {
                            return <div key={index} className="grid-image-box d-inline-block p-2">
                                    <img src={image.url} className="m-1 shadow rounded grid-image mx-auto" alt="" />
                                    <button className="btn btn-danger btn-block" onClick={() => onRemoveClick(index)}>
                                        Remove
                                    </button>
                                </div>
                            })
                            }
                        </div>
                    </div>}
                { (typeof(this.props.images) == typeof(undefined) || this.props.images == null ? true : this.props.images.length == 0) &&
                    <div className="p-2 text center">
                        NO Images Uploaded.
                    </div>}
            </div>
        );
    }
}