import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPostTestReport, getPostTestsByModule } from "./../../../actions/posttest";
import APIServices from "./../../../services";
import SearchPanel from "./../components/searchPanel";
import ConfirmDialog from './../../components/models/confirm-dialog';
import { string } from 'prop-types';
import { processResponse } from "./../../../actions";

class PostTestReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        postTestModelShow : false,
        postTestModelBody: null
    }

    this.toggleModel = this.toggleModel.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(getPostTestReport());
  }

  
  onToggleDropDown = (toggleDropDown) => {
    toggleDropDown();
  }

  renderSizePerPageDropDown = (props) => {
    return (
      // eslint-disable-next-line react/jsx-no-undef
      <SizePerPageDropDown
        className='my-size-per-page'
        btnContextual='btn-info'
        variation='dropup'
        onClick={() => this.onToggleDropDown(props.toggleDropDown)} />
    );
  }


  renderCustomClearSearch = (onClick) => {
    return (
      <button
        className='ml-2 btn btn-success'
        onClick={onClick}>
        clear
          </button>
    );
  }

  cellButton(cell, row, enumObject, rowIndex) {
    return (
      <div>
        <button className="btn btn-info" onClick={this.setPostTestModel.bind(this,cell)}> View </button>
      </div>
    );
  }

  toggleModel(bool) {
    if (bool) {
    }
    this.setState({
      postTestModelShow: !this.state.postTestModelShow,
      modelId: 0
    });
  }

  setPostTestModel(id, $this) {
    let me = this;


    APIServices.getPostTestUserFeedBackReportQuestions(id)
      .then(processResponse)
      .then(response => {
        // redirect user to 
        var questions = response.data.questions;
        const userFeedBack = response.data.userFeedBack;
        console.log(questions);
        console.log(userFeedBack);
        let modal = {};
        modal.questions = [];
        modal.Name = [];
        questions.forEach(function (que, i) {
          let _Name = [];
          let _question = [];

          const _userfeedback = userFeedBack.find((o) => { return o["postTestQuestionId"] == que.id })

          console.log(_userfeedback);
          console.log(_userfeedback.answer);
          console.log(que.rightAnswer);

          que.answers.forEach(function (ans, j) {
            _question.push(
              <div className="form-check d-flex" key={j}>
                <input className="form-check-input" type="radio" name={"question" + i} value={j + 1} checked={(j+1) == _userfeedback.answer} readOnly/>
                <label className={"form-check-label" + (_userfeedback.answer ==  (j+1) ? " user-answer" : "")  + (que.rightAnswer ==  (j+1) ? " correct-answer" : "")} htmlFor={"question" + i}>
                  {ans.answer}
                </label>
              </div>);
          });
          
        
          _Name.push(
            <div key={i}>
              <h4 className="card-title text-left" user-answer={userFeedBack.find(x=>x.postTestQuestionId = que.id).answer} right-answer={que.rightAnswer}>{que.name}</h4>
              {_question}
              <hr />
            </div>
          );
          modal.Name.push(_Name)
        });
    
        me.setState({
          postTestModelShow: !me.state.postTestModelShow,
          postTestModelBody: (<div>{modal.Name}</div>),
          modelId: id
        });

      })
      .catch(err => {
        console.log(err);
      })
    
  }


  createCustomExportCSVButton = (onClick) => {
    return (
      <button className="btn btn-info" onClick={onClick}>Custom Export CSV Btn</button>
    );
  }

  renderShowsTotal(start, to, total) {
    return (
      <p style={{ color: 'blue' }}>
        From {start} to {to}, totals is {total}&nbsp;&nbsp;(its a customize text)
          </p>
    );
  }
  filterType(cell, row) {
    // just return type for filtering or searching.
    return cell.type;
  }

  userNameFormatter(cell) {
    return cell.lastName + ', ' + cell.firstName;
  }

  customerFormatter(cell) {
    return cell.customer.name;
  }

  moduleNameFormatter(cell) {
    return cell.name;
  }

  resultsFormatter(cell) {
    if(cell == true){
        return (<div className='valid-feedback'> <strong>  Pass</strong> </div>);
    }else {
        return (<div className='invalid-feedback'> <strong> Fail </strong> </div>);

    }
  }

  resultsCSVFormatter(cell) {
    return cell == true ? 'Pass' : 'Fail';
  }

  render() {
    const options = {
      page: 1,  // which page you want to show as default
      sizePerPageList: [{
		  text: '25', value: 25
	  }, {
			  text: '50', value: 50
	  }, {
        text: 'All', value: this.props.model.report.length
      }], // you can change the dropdown list for size per page
	  sizePerPage: this.props.model.report.length,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      exportCSVBtn: this.createCustomExportCSVButton,
      clearSearch: true,
      clearSearchBtn: this.renderCustomClearSearch,
      searchPanel: (props) => (<SearchPanel { ...props } />),
      sizePerPageDropDown: this.renderSizePerPageDropDown
    };


    if (this.props.model.report.length > 0) {
      return (<div className='post-test-report'>
        <div className="p-3">
          <Link to='/' className="btn btn-dark mr-2"> Back to Home</Link>
        </div>
        <div className="p-3">
          <BootstrapTable data={this.props.model.report} exportCSV search hover info version='4' className="thead-inverse" options={options} pagination>
            <TableHeaderColumn isKey dataField='id' dataSort caretRender={this.getCaret}>#</TableHeaderColumn>
            <TableHeaderColumn dataField="userName" filterFormatted csvHeader="User Name" dataSort>User Name</TableHeaderColumn>
            <TableHeaderColumn dataField="customerName" filterFormatted csvHeader="Customer Name" dataSort>Customer Name</TableHeaderColumn>
            <TableHeaderColumn dataField="moduleName" filterFormatted csvHeader="Module Name" dataSort>Module Name</TableHeaderColumn>
            <TableHeaderColumn dataField='attempt'>Attempt</TableHeaderColumn>
            <TableHeaderColumn dataField="result" filterFormatted dataFormat={this.resultsFormatter} csvFormat={this.resultsCSVFormatter} csvHeader="Pass/Fail" dataSort>Pass/Fail</TableHeaderColumn>
            <TableHeaderColumn dataField="dateTaken" csvHeader="Date Taken" dataSort>Date Taken</TableHeaderColumn>
            <TableHeaderColumn
              dataField='id'
              dataFormat={this.cellButton.bind(this)}
              >View Test Results</TableHeaderColumn>
          </BootstrapTable>
          <ConfirmDialog name="post-test-model" isOpen={this.state.postTestModelShow} toggle={this.toggleModel} modelTitle={"Post Test Results"} className="post-test-model" modelBody={this.state.postTestModelBody}/>
        </div>
      </div>
      );
    } else {
      return (<div>
        <div className="p-3 text-center">
          Loading ...
        </div>
      </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    model: state.store.posttest
  };
}

export default connect(mapStateToProps)(PostTestReport);