import React from 'react';
import { getPostTestsByModule, addPosttestFeedBack } from "./../../actions/posttest";
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import APIServices from "../../services";
import { processResponse } from "./../../actions";

class PostTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answers: []
    };

    this.onSiteChanged = this.onSiteChanged.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.props.dispatch(getPostTestsByModule(this.props.match.params.moduleId));
  }

  onSubmit() {
    let model = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: this.state.answers
    }
    APIServices.addPosttestFeedBack(model)
      .then(processResponse)
      .then(response => {
        // redirect user to 
        NotificationManager.success('Post Test Added Successfully.', 'Success');
        this.props.history.push("/module/" + this.props.match.params.moduleId);;
      })
      .catch(err => {
        console.log(err);
      })
  }
  onSiteChanged($this, question, answer) {
    let answers = $this.state.answers;
    const ans = {
      postTestQuestionId: question,
      moduleId: $this.props.match.params.moduleId,
      aspNetUserId: 0,
      answer: answer
    };
    if (answers.filter(x => x.postTestQuestionId == question).length > 0) {
      answers = answers.filter(x => x.postTestQuestionId != question);
    }
    answers.push(ans);
    $this.setState({
      ...$this.state,
      answers
    })

  }
  render() {
    let Qustions = this.props.model.postTests;
    const $this = this;
    const { onSiteChanged } = this;
    let modal = {};
    modal.Qustions = [];
    modal.Name = [];
    Qustions.forEach(function (que, i) {
      let _Name = [];
      let _question = [];
      que.answers.forEach(function (ans, j) {
        _question.push(
          <div className="form-check d-flex">
            <input className="form-check-input" type="radio" name={"question" + i} value={j + 1} onChange={() => onSiteChanged($this, que.id, j + 1)} />
            <label className="form-check-label" for={"question" + i}>
              {ans.answer}
            </label>
          </div>);
      });
      _Name.push(
        <div>
          <h4 className="card-title text-left">{que.name}</h4>
          {_question}
          <hr />
        </div>
      );
      modal.Name.push(_Name)
    });


    return (<div>
      <div className="container bg-white">
        <h2 className="text-center py-3">Post Test</h2>
        <div className="card-body">
          {modal.Name}
        </div>
        {modal.Name.length > 0 &&
          <div className="d-flex justify-content-center mb-4">
            <button className="btn btn-dark btn-small" onClick={this.onSubmit.bind(this)}>Submit</button>
          </div>
        }
        {modal.Name.length == 0 &&
          <div className="d-flex justify-content-center mb-4">
            No Post Test Questions available.
            </div>
        }
      </div>
    </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    model: state.store.posttest
  };
}

export default connect(mapStateToProps)(PostTest);