import * as actions from "../../actiontypes/modules";
import APIServices from "../../services";
import { processResponse } from "../../actions";
import {NotificationManager} from 'react-notifications';

export const getAllModules = () => {
    return dispatch => APIServices.getAllModules()
        .then(response => response.json())
        .then(response => {
            dispatch(modulesReceived(response));
        })
        .catch(err => {
            console.log(err);
        })
};

export const modulesReceived = (response) => {
    return {
        type: actions.MODULES,
        payload: {
            response: response
        }
    };
};


export const addModule = (model) => {
    console.log(model);
    return dispatch => APIServices.addModule(model)
        .then(response => response.json())
        .then(data => {
            dispatch(getAllModules());
            NotificationManager.success('Module is added Successfully..', 'Success');
            
        })
        .catch(err => {
            // dispatch(errorUser("error"))
        })
};

export const deleteModule = (model) => {
    return dispatch => APIServices.deleteModule(model)
        .then(response => response.json())
        .then(data => {
            dispatch(getAllModules());
            NotificationManager.success('Module is deleted Successfully..', 'Success');
        })
        .catch(err => {
            
        })
};

export const updateModule = (model) => {
    
    return dispatch => APIServices.updateModule(model)
        .then(response => response.json())
        .then(data => {
            dispatch(getAllModules());
            NotificationManager.success('Module is updated Successfully..', 'Success');
        })
        .catch(err => {
            
        })
};
export const getModule = (model) => {
   
    return dispatch => APIServices.getModule(model)
        .then(processResponse)
        .then(data => {
            // if (data.response === "success") {
            dispatch(getModuleReceived(data));
            NotificationManager.success('Module is loaded Successfully..', 'Success');
            
            // } else {
            //     dispatch(errorUser("error"))
            // }
        })
        .catch(err => {
            // dispatch(errorUser("error"))
        })
};

export const getModuleStart = () => {
    return {
        type: actions.MODULES_GET_START
    };
};


export const getModuleReceived = (response) => {
    return {
        type: actions.MODULES_GET,
        payload: {
            response: response
        }
    };
};

export const getModuleSearch = (searchText) => {
    return APIServices.getModuleSearch(searchText)
      .then(response => response.json())
      .then(response => {
        return {
          options: response.map(function (elem) {
            return { "name": elem.name, "id": elem.id }
          })
        };
      })
      .catch(err => {
        return { options: [] };
      });
  
  };
