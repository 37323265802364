import React from "react";
import { Link } from 'react-router-dom';

export default class SideBar extends React.Component {

  render() {
    return (
        <div className="sidebar bg-dark">
        <ul className="list-unstyled">
            <li>
                <Link to='/admin'> Dashboard</Link>
                </li>
                {this.props.user.user.role == "superadmin" && <li><a href="#submenu5" data-toggle="collapse"> Customer</a>
                    <ul id="submenu5" className="list-unstyled collapse">
                        <li>
                            <Link to='/admin/customer/customerList'> Cusomers List</Link>
                        </li>
                        <li>  <Link to='/admin/customer/addCustomer'> Add Customer</Link></li>
                    </ul>
                    </li>
                }

                {this.props.user.user.role == "superadmin" && <li><a href="#submenu4" data-toggle="collapse"> User</a>
                    <ul id="submenu4" className="list-unstyled collapse">
                        <li>
                            <Link to='/admin/users/usersList'> Users List</Link>
                        </li>
                        <li>  <Link to='/admin/users/addUser'> Add User</Link></li>
                        <li>  <Link to='/admin/users/addQuickUser'> Add Quick User</Link></li>
                    </ul>
                </li>
                }

                {this.props.user.user.role == "superadmin" && <li>
                    <a href="#submenu1" data-toggle="collapse"> Modules</a>
                    <ul id="submenu1" className="list-unstyled collapse">
                        <li>
                            <Link to='/admin/modules/moduleList'> Module List</Link>
                        </li>
                        <li>  <Link to='/admin/modules/addModule'> Add Module</Link></li>
                    </ul>
                </li>
                }

                {this.props.user.user.role == "superadmin" && <li>
                    <a href="#submenu2" data-toggle="collapse">  Approaches  </a>
                    <ul id="submenu2" className="list-unstyled collapse">
                        <li>
                            <Link to='/admin/approaches/approachList'> Approach List</Link>
                        </li>
                        <li>  <Link to='/admin/approaches/addApproach'> Add Approach</Link></li>
                    </ul>
                </li>
                }
           
                {this.props.user.user.role == "superadmin" && <li>
                    <a href="#submenu3" data-toggle="collapse"> Techniques</a>
                    <ul id="submenu3" className="list-unstyled collapse">
                        <li>
                            <Link to='/admin/techniques/techniquesList'> Techniques List</Link>
                        </li>
                        <li>  <Link to='/admin/techniques/addTechniques'> Add Techniques</Link></li>
                    </ul>
                </li>
                }

                {this.props.user.user.role == "superadmin" && <li>
                    <a href="#submenu6" data-toggle="collapse"> Seminar Companies</a>
                    <ul id="submenu6" className="list-unstyled collapse">
                        <li>
                            <Link to='/admin/companies/companyList'> Companies List</Link>
                        </li>
                        <li>  <Link to='/admin/companies/addCompany'> Add Company</Link></li>
                    </ul>
                </li>
                }

                {this.props.user.user.role == "superadmin" && <li>
                    <a href="#submenu7" data-toggle="collapse"> Seminars</a>
                    <ul id="submenu7" className="list-unstyled collapse">
                        <li>
                            <Link to='/admin/seminars/seminarList'> Seminars List</Link>
                        </li>
                        <li>  <Link to='/admin/seminars/addSeminar'> Add Seminar</Link></li>
                    </ul>
                </li>
                }

                {this.props.user.user.role == "superadmin" && <li>
                    <a href="#submenu8" data-toggle="collapse"> Videos</a>
                    <ul id="submenu8" className="list-unstyled collapse">
                        <li>
                            <Link to='/admin/videos/videoList'> Video List</Link>
                        </li>
                        <li>  <Link to='/admin/videos/addVideo'> Add Video</Link></li>
                    </ul>
                </li>
				}
				{this.props.user.user.role == "superadmin" && <li>
					<a href="#submenu9" data-toggle="collapse"> Reports</a>
					<ul id="submenu9" className="list-unstyled collapse">
						<li>
							<Link to='/admin/reports/postTest'> Post Test Report </Link>
						</li>
					</ul>
				</li>
				}
            
           
          

            {/* <li>
                <a href="#submenu6" data-toggle="collapse"> Segments</a>
                <ul id="submenu6" className="list-unstyled collapse">
                    <li>
                    <Link to='/admin/segments/segmentList'> Segments List</Link>
                    </li>
                    <li>  <Link to='/admin/segments/addSegment'> Add Segment</Link></li>
                </ul>
            </li> */}
        </ul>
</div>
    );
  }
}