import "whatwg-fetch";
import * as API from "./config.js";
import Auth from "./../app/login/auth";
import fetchIntercept from 'fetch-intercept';
import { configure, authorize } from '@shoutem/fetch-token-intercept';
import history from './../history';

let config = {
  // (Required) Prepare fetch request for renewing new access token
  createAccessTokenRequest: (refreshToken) => {
    return new Request(API.AUTH_LOGIN), {
      headers: {
        Authorization: 'Bearer '+refreshToken,
      },
      method: 'POST',
    };
  },
     
  // (Required) Parses access token from access token response
  parseAccessToken: (response) => {
    return response.clone().json().then(json => json.accessToken);
  },
     
  // (Required) Defines whether interceptor will intercept this request or just let it pass through
  shouldIntercept: (request) => true,
     
  // (Required) Defines whether access token will be invalidated after this response
  shouldInvalidateAccessToken: (response) => true,
    
  // When set, response which invalidates token will be resolved after the token has been renewed
  // in effect, token will be loaded in sync with response, otherwise renew will run async to response
  shouldWaitForTokenRenewal: true,
    
  // Checks if response should be considered unauthorized (by default only 401 responses are 
  // considered unauthorized). Override this method if you need to trigger token renewal for 
  // other response statuses. Check API reference for helper method which defines default behaviour
  isResponseUnauthorized: (response) => true,
     
  // (Required) Adds authorization for intercepted requests
  authorizeRequest: (request, accessToken) => (request, accessToken) => {
    request.headers.set('Authorization', 'Bearer '+accessToken);
    return request;
  },
     
  // Number of retries after initial request was unauthorized
  fetchRetryCount: 0,
    
  // Event invoked when access token has changed
  onAccessTokenChange: null,
     
  // Event invoked when response is resolved
  onResponse: null,
};

configure(config);
// // perform authentication with user credentials against your auth server
// // when you recieve refresh token (and optionally access token) provide them to interceptor lib
// authorize(refreshToken, accessToken);

const unregister = fetchIntercept.register({
  request: function (url, config) {
    // Modify the url or config here
    return [url, config];
  },

  requestError: function (error) {
    // Called when an error occurred during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: function (response) {
    let data = {
      status : response.status
    };
    switch(response.status){
    case 400: // Bad Request.
      //sentMessageToBrokerageWithData(CM_ACTIONS.CM_LOGOUT,data);
      break;
    case 401: // UnAuthorized Request.
      //sentMessageToBrokerageWithData(CM_ACTIONS.CM_LOGOUT, data);
      //window.location.href = "/login";
      //console.log(history.location.pathname.toString());
      //const test = history.location.pathname;
      //console.log(history.location.pathname.toString().toLowerCase().trim());
      //console.log(history.location.pathname.toString().toLowerCase().trim() != "/login");
      if (history.location.pathname.toString().toLowerCase().trim() != "/login") {
        window.location.href = "/login";
      }
      break;
    case 500:
      //add generic 500 error handler
      break;
    }
    return response;
  },

  responseError: function (error) {
    // Handle an fetch error
    return Promise.reject(error);
  }
});

class APIServices {

  static login(model) {
    return fetch(API.AUTH_LOGIN, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: model
    });
    // .then(response => response.json())
    // .then(data => {
    //     console.log(data);
    //     authorize(data.refresh_token, data.access_token);
    // })
  }

  static logout() {
    return fetch(API.AUTH_LOGOUT, {
      method: 'GET'
    });
  }

  static userInfo() {
    let model = {};
    model = Auth.addAuthHeader(model);
    return fetch(API.AUTH_LOGIN_USERINFO, {
      method: 'GET',
      headers: model.headers
    });
  }

  static addAgreementTimeStamp(userName) {
    let model = {};
    model = Auth.addAuthHeader(model);
    return fetch(API.AUTH_LOGIN_AGREEMENT_TIMESTAMP + userName, {
      method: 'GET',
      headers: model.headers
    });
  }

  static getDashboardData(){
    let model = {};
    model = Auth.addAuthHeader(model);
    return fetch(API.API_USER_DASHBOARD, {
      method: 'GET',
      headers: model.headers
    });
  }

  static getAllUsers(){
    let model = {};
    model = Auth.addAuthHeader(model);
    return fetch(API.API_USER_ALL, {
      method: 'GET',
      headers: model.headers
    });
  }

  static getUser(id){
    return fetch(API.API_USER_GET + id, {
      method: 'GET',
      headers: Auth.addAuthHeader().headers
    });
  }

  static addUser(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_USER_ADD, {
      method: 'POST',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }

  static updateUser(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_USER_UPDATE, {
      method: 'PUT',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }

  static deleteUser(id) {
    let model = Auth.addAuthHeader();
    return fetch(API.API_USER_DELETE + id, {
      method: 'DELETE',
      headers: model.headers
    });
  }

  static getAllApproachesWithDetails() {
    let model = Auth.addAuthHeader();
    return fetch(API.API_APPROACH_ALL_WITHDETAILS, {
      method: 'GET',
      headers: model.headers
    });
  }

  static getAllApproaches() {
    let model = Auth.addAuthHeader();
    return fetch(API.API_APPROACH_ALL, {
      method: 'GET',
      headers: model.headers
    });
  }

  static getFeedData() {
    let model = Auth.addAuthHeader();
    return fetch(API.SLIDER_DATA, {
      method: 'GET',
      headers: model.headers
    });
  }

  static getModulesTechniquesApproachesNames() {
    let model = Auth.addAuthHeader();
    return fetch(API.NAMES_OF_MODULES_TECHNIQUES_APPROACHES, {
      method: 'GET',
      headers: model.headers
    });
  }

  static getApproach(id) {
    return fetch(API.API_APPROACH_GET + id, {
      method: 'GET',
      headers: Auth.addAuthHeader().headers
    });
  }



  static getCustomerSearch(search) {
    return fetch(API.API_CUSTOMER_SEARCH + search, {
      method: 'GET',
      headers: Auth.addAuthHeader().headers
    });
  }

  static addApproach(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_APPROACH_ADD, {
      method: 'POST',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }

  static deleteApproach(id) {
    let model = Auth.addAuthHeader();
    return fetch(API.API_APPROACH_DELETE + id, {
      method: 'DELETE',
      headers: model.headers
    });
  }

  static updateApproach(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_APPROACH_UPDATE, {
      method: 'PUT',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }
  static getPosttest(id) {
    return fetch(API.API_POSTTEST_GET + id, {
      method: 'GET',
      headers: Auth.addAuthHeader().headers
    });
  }

  static getPostTestsByModule(id) {
    return fetch(API.API_POSTTEST_BY_MODULE + id, {
      method: 'GET',
      headers: Auth.addAuthHeader().headers
    });
  }

  static getPostTestUserFeedBackReportQuestions(id) {
    return fetch(API.API_POSTTEST_GetPostTestUserFeedBackReportQuestions + id, {
      method: 'GET',
      headers: Auth.addAuthHeader().headers
    });
  }


  static getPostTestReport() {
    return fetch(API.API_POSTTEST_REPORT, {
      method: 'GET',
      headers: Auth.addAuthHeader().headers
    });
  }

  static getAllPosttest() {
    let model = Auth.addAuthHeader();
    return fetch(API.API_POSTTEST_ALL, {
      method: 'GET',
      headers: model.headers
    });
  }
  static addPosttest(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_POSTTEST_ADD, {
      method: 'POST',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }

  static deletePosttest(id) {
    let model = Auth.addAuthHeader();
    return fetch(API.API_POSTTEST_DELETE + id, {
      method: 'DELETE',
      headers: model.headers
    });
  }
  static updatePosttest(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_POSTTEST_UPDATE, {
      method: 'PUT',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }

  static addPosttestFeedBack(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_POSTTEST_FEEDBACK_ADD, {
      method: 'POST',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }
   
  static getModule(id) {
    return fetch(API.API_MODULE_GET + id, {
      method: 'GET',
      headers: Auth.addAuthHeader().headers
    });
  }
  static getAllModules() {
    let model = Auth.addAuthHeader();
    return fetch(API.API_MODULE_ALL, {
      method: 'GET',
      headers: model.headers
    });
  }

   
  static addModule(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_MODULE_ADD, {
      method: 'POST',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }
  static deleteModule(id) {
    let model = Auth.addAuthHeader();
    return fetch(API.API_MODULE_DELETE + id, {
      method: 'DELETE',
      headers: model.headers,

    });
  }

  static updateModule(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_MODULE_UPDATE, {
      method: 'PUT',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }

  static addSegment(model) {
    model = Auth.addAuthHeader(model);

    return fetch(API.API_SEGMENT_ADD, {
      method: 'POST',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }

  static deleteSegment(id) {
    let model = Auth.addAuthHeader();
    return fetch(API.API_SEGMENT_DELETE + id, {
      method: 'DELETE',
      headers: model.headers,

    });
  }
  static updateSegment(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_SEGMENT_UPDATE, {
      method: 'PUT',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }
  static getTechniques(id) {
    return fetch(API.API_TECHNIQUE_GET + id, {
      method: 'GET',
      headers: Auth.addAuthHeader().headers
    });
  }
  static getAllTechniques() {
    let model = Auth.addAuthHeader();
    return fetch(API.API_TECHNIQUE_ALL, {
      method: 'GET',
      headers: model.headers
    });
  }

  static getAllTechniquesWithDetails() {
    let model = Auth.addAuthHeader();
    return fetch(API.API_TECHNIQUE_ALL_WITH_DETAILS, {
      method: 'GET',
      headers: model.headers
    });
  }

  static addTechniques(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_TECHNIQUE_ADD, {
      method: 'POST',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }

  static deleteTechniques(id) {
    let model = Auth.addAuthHeader();
    return fetch(API.API_TECHNIQUE_DELETE + id, {
      method: 'DELETE',
      headers: model.headers,

    });
  }

  static updateTechnique(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_TECHNIQUE_UPDATE, {
      method: 'PUT',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }

  static getAllSeminarCompanies() {
    let model = Auth.addAuthHeader();
    return fetch(API.API_SEMINAR_COMPANY_ALL, {
      method: 'GET',
      headers: model.headers
    });
  }

  static getSeminarCompany(id) {
    let model = Auth.addAuthHeader();
    return fetch(API.API_SEMINAR_COMPANY_GET + id, {
      method: 'GET',
      headers: model.headers
    });
  }

  static deleteSeminarCompany(id) {
    let model = Auth.addAuthHeader();
    return fetch(API.API_SEMINAR_COMPANY_DELETE + id, {
      method: 'DELETE',
      headers: model.headers,

    });
  }

  static addSeminarCompany(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_SEMINAR_COMPANY_ADD, {
      method: 'POST',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }
  static updateSeminarCompany(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_SEMINAR_COMPANY_UPDATE, {
      method: 'PUT',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }

  static getAllSeminars() {
    let model = Auth.addAuthHeader();
    return fetch(API.API_SEMINAR_ALL, {
      method: 'GET',
      headers: model.headers
    });
  }

  static getSeminar(id) {
    let model = Auth.addAuthHeader();
    return fetch(API.API_SEMINAR_GET + id, {
      method: 'GET',
      headers: model.headers
    });
  }

  static deleteSeminar(id) {
    let model = Auth.addAuthHeader();
    return fetch(API.API_SEMINAR_DELETE + id, {
      method: 'DELETE',
      headers: model.headers,

    });
  }

  static addSeminar(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_SEMINAR_ADD, {
      method: 'POST',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }
  static updateSeminar(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_SEMINAR_UPDATE, {
      method: 'PUT',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }

  static getCustomer(id) {
    return fetch(API.API_CUSTOMER_GET + id, {
      method: 'GET',
      headers: Auth.addAuthHeader().headers
    });
  }
  static getAllCustomers() {
    let model = Auth.addAuthHeader();
    return fetch(API.API_CUSTOMER_ALL, {
      method: 'GET',
      headers: model.headers
    });
  }
  static addCustomer(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_CUSTOMER_ADD, {
      method: 'POST',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }


  static deleteCustomer(id) {
    let model = Auth.addAuthHeader();
    return fetch(API.API_CUSTOMER_DELETE + id, {
      method: 'DELETE',
      headers: model.headers,
    });
  }
  static updateCustomer(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_CUSTOMER_UPDATE, {
      method: 'PUT',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }
    
   
  static getAllSegments() {
    let model = Auth.addAuthHeader();
    return fetch(API.API_SEGMENT_ALL, {
      method: 'GET',
      headers: model.headers
    });
  }
  static getSegment(id) {
    return fetch(API.API_SEGMENT_GET + id, {
      method: 'GET',
      headers: Auth.addAuthHeader().headers
    });
  }
   

   

  static uploadImage(model) {
    let data = new FormData();
    data.append('uploadFile', model.file);
    model = Auth.addAuthHeader(model);
    return fetch(API.API_UPLOAD_IMAGE, {
      method: 'POST',
      body: data
    });
  }

  static getApproachSearch(search) {
    return fetch(API.API_APPROACH_SEARCH + search, {
      method: 'GET',
      headers: Auth.addAuthHeader().headers
    });
  }

  static getModuleSearch(search) {
    return fetch(API.API_MODULE_SEARCH + search, {
      method: 'GET',
      headers: Auth.addAuthHeader().headers
    });
  }

  static getTechniqueSearch(search) {
    return fetch(API.API_TECHNIQUE_SEARCH + search, {
      method: 'GET',
      headers: Auth.addAuthHeader().headers
    });
  }


  static getAllVideos(){
    let model = {};
    model = Auth.addAuthHeader(model);
    return fetch(API.API_VIDEO_ALL, {
      method: 'GET',
      headers: model.headers
    });
  }

  static addVideo(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_VIDEO_ADD, {
      method: 'POST',
      headers: model.headers,
      body: model.data
    });
  }

  static addVideoPoster(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_VIDEO_POSTER_ADD, {
      method: 'POST',
      headers: model.headers,
      body: model.data
    });
  }

  static deleteVideoPoster(id) {
    let model = Auth.addAuthHeader();
    return fetch(API.API_VIDEO_POSTER_DELETE + '?id='+ id +'', {
      method: 'DELETE',
      headers: model.headers
    });
  }

  static deleteVideo(id) {
    let model = Auth.addAuthHeader();
    return fetch(API.API_VIDEO_DELETE + '?id='+ id +'', {
      method: 'DELETE',
      headers: model.headers
    });
  }


  static validateResetPasswordCode(code) {
    return fetch(API.API_USER_VALIDATE_RESET_PASSWORD_TOKEN + '?code='+ code +'', {
      method: 'POST',
    });
  }

  static recoverPassword(model) {
    return fetch(API.API_USER_RECOVER_PASSWORD + '?Email='+ encodeURIComponent(model.data.email) +'', {
      method: 'POST',
    });
  }

  static resetPassword(model) {
    return fetch(API.API_USER_RESET_PASSWORD, {
      method: 'POST',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }

  static changePassword(model) {
    model = Auth.addAuthHeader(model);
    return fetch(API.API_USER_CHANGE_PASSWORD, {
      method: 'POST',
      headers: model.headers,
      body: JSON.stringify(model.data)
    });
  }

}

export default APIServices;