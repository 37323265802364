import React, { Component, PropTypes } from 'react';
import { BrowserRouter as Router, Route, Link, hashHistory, Switch } from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { getAllVideos } from "./../../../actions/video";
import ManageVideo from './manageVideo';
import VideoList from './videoList';

class Video extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (<div className="video">
            <Switch>
                <Route component={VideoList} path="/admin/videos/videoList"></Route>
                <Route component={ManageVideo} path="/admin/videos/addVideo"></Route>
            </Switch>
        </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        model: state.store.video
    };
}

export default connect(mapStateToProps)(Video);