import React, { Component } from 'react';
import { withRouter  } from 'react-router-dom';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { connect } from 'react-redux';
import { addApproach, updateApproach} from "./../../../actions/approaches";
import { processResponse } from "./../../../actions";
import ImageGrid from "./../components/imageGrid";
import VideoGrid from "./../components/videoGrid";
import APIServices from "../../../services";
import * as API from "../../../services/config";
import Select from 'react-select';
import Loader from './../../components/loader';
import { getDescription, onInputChange, 
  onSelectChange, addVideo,
  onImageRemoveClick, onVideoRemoveClick,
  loadModules, loadTechniques } from './../components/helper';

class ManageApproach extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      id: -1,
      name: "",
      description: "",
      content: "",
      images: [],
      videos: [],
      modules: [],
      techniques: []
    };

    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onImageRemoveClick = this.onImageRemoveClick.bind(this);
    this.onVideoRemoveClick = this.onVideoRemoveClick.bind(this);

    this.resetApproach = this.resetApproach.bind(this);
  }

    UNSAFE_componentWillMount() {
    // this.props.dispatch(uploadImageReset());

    if (typeof (this.props.match.params.id) !== typeof (undefined) && Number(this.props.match.params.id) > 0) {
      APIServices.getApproach(this.props.match.params.id)
        .then(processResponse)
        .then(response => {
          // response.data
          const contentBlock = htmlToDraft(response.data.approach.content);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);

            this.setState({
              ...this.state,
              editorState,
              name: response.data.approach.name,
              description: response.data.approach.description,
              id: response.data.approach.id,
              images: response.data.approach.images,
              videos: response.data.approach.videos,
              modules: response.data.modules.map(function name(_module) {
                return {
                    id: _module.moduleId,
                  name : _module.module.name
                };
              }),
              techniques: response.data.techniques.map(function name(_technique) {
                return {
                    id: _technique.techniqueId,
                  name : _technique.technique.name
                };
              })
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.resetApproach();
    }
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState
    });
  }

    resetApproach(){
    this.setState({
      editorState: EditorState.createEmpty(),
      id: 0,
      name: "",
      description: "",
      content: "",
      images: [],
      videos: [],
      modules: [],
      techniques: []
    });
  }

  onInputChange(event) {
    onInputChange(this, event);
  }

  addVideo(event) {
    addVideo(this, event);
  }

  onSaveButtonClick() {
    let model = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        approach : {
          name: this.state.name,
          description: this.state.description,
          content: getDescription(this),
          images: this.state.images,
          videos: this.state.videos
        },
        techniques : this.state.techniques.map(function name(technique) {
          return {
            techniqueId : technique.id
          };
        }),
        modules : this.state.modules.map(function name(_module) {
          return {
            moduleId : _module.id
          };
        })
      }
    };

    if(Number(this.state.id) > 0){
      model = {
        ...model,
        data : {
          ...model.data,
            approach: {
                ...model.data.approach,
                id: this.state.id
            }
        }
      };
      this.props.dispatch(updateApproach(model));
    }else{
      this.props.dispatch(addApproach(model));
        this.resetApproach();
      }
      this.props.history.push('/admin/approaches/approachList');
  }

  handleChange(e) {
    let model = {
      file: e.target.files[0]
    };
    APIServices.uploadImage(model).then(response => response.json())
      .then(data => {
        let images = this.state.images;
        images.push({ url: API.ROOT + data.url, name: data.fileName, id: 0 });
        this.setState({
          images: images
        });
      })
      .catch(() => {
      });

  }

  onImageRemoveClick(index) {
    onImageRemoveClick(this, index);
  }

  onVideoRemoveClick(index) {
    onVideoRemoveClick(this, index);
  }

  onSelectChange(name, multi, option) {
    onSelectChange(this, name, multi, option);
  }

  render() {
    const { editorState } = this.state; 

    if (this.state.id > -1) {
      return (
        <div>
          <div>
            <div className="p-4 text-center h2">
              { (Number(this.state.id) > 0) ? "Edit" : "Add" } Approach
            </div>
            <div className="p-4">
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Header</label>
                    <input type="text" name="name" value={this.state.name}
                      className="form-control" id="name"
                      onChange={this.onInputChange} placeholder="Enter Approach Header" />
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <textarea name="description" value={this.state.description} className="form-control" id="name"
                      onChange={this.onInputChange} placeholder="Enter Approach Description" rows="8" />
                  </div>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label>Modules</label>
                        <Select.Async clearable={false} autoload={false}
                          cache={false} multi
                          value={this.state.modules}
                          onChange={this.onSelectChange.bind(this, "modules", true)}
                          valueKey="id" labelKey="name" isLoading={false}
                          loadOptions={loadModules} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label>Techniques</label>
                        <Select.Async clearable={false} autoload={false}
                          cache={false} multi
                          value={this.state.techniques}
                          onChange={this.onSelectChange.bind(this, "techniques", true)}
                          valueKey="id" labelKey="name" isLoading={false}
                          loadOptions={loadTechniques} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-4">
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor bg-white demo-editor-apporach"
                onEditorStateChange={this.onEditorStateChange}
              />
            </div>

            {!this.props.model.addApproachImageUploading &&
                            <div className="p-4">
                              <div className="form-group">
                                <label>Upload Image</label>
                                <input type="file" name="uploadedFile" value={this.state.uploadedFile} className="form-control" id="uploadedFile" onChange={(e) => {
                                  this.handleChange(e);
                                }} ref={(input) => this.fileinput = input} />
                              </div>
                            </div>
            }

            <ImageGrid images={this.state.images} onImageRemoveClick={this.onImageRemoveClick} />

            <div className="p-4">
              <div className="form-group">
                <label>Video</label>
                <input type="text" name="video" value={this.state.video} className="form-control" id="video"
                  onChange={this.onInputChange} placeholder="Enter Video Url" />
              </div>
              <div className="form-group">
                <button className="btn btn-info" onClick={this.addVideo.bind(this)}>
                                    Add Video
                </button>
              </div>
            </div>

            <VideoGrid videos={this.state.videos} onVideoRemoveClick={this.onVideoRemoveClick} />

            <div className="p-4">
              <div className="btn-group" role="group" aria-label="">
                <button className="btn btn-dark mr-2" onClick={this.onSaveButtonClick.bind(this)}> Save </button>
                               
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Loader />;
    }
  }
}


function mapStateToProps(state) {
  return {
    model: state.store.approach
  };
}


export default withRouter(connect(mapStateToProps)(ManageApproach));