import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery'; 

class Banner extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        $(document).ready(function () {
            // Add smooth scrolling to all links
            $("a.smooth-scroll").on('click', function (event) {

                // Make sure this.hash has a value before overriding default behavior
                if (this.hash !== "") {
                    // Prevent default anchor click behavior
                    event.preventDefault();

                    // Store hash
                    var hash = this.hash;

                    // Using jQuery's animate() method to add smooth page scroll
                    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                    $('html, body').animate({
                        scrollTop: $(hash).offset().top
                    }, 800, function () {

                        // Add hash (#) to URL when done scrolling (default click behavior)
                        window.location.hash = hash;
                    });
                } // End if
            });
        });
    }

    render() {

            return (
                <div className="justify-content-center align-items-center banner">
                    <img src={require("./../../assets/images/banner.jpg")} className="img-responsive" />
                    {/* <a href="#welcome-text" className="btn btn-outline-info smooth-scroll welcome">WELCOME to StratPsych ™ Learning</a>
                    <a href="#process" className="btn btn-outline-info smooth-scroll benefit">The Process, The Training and Who Can Benefit</a> */}
					<a href='/learning' className="btn btn-primary learning-process"> 
                    {this.props.user.user.lastLogin === "N/A" && "Start your learning process"}
                    {this.props.user.user.lastLogin !== "N/A" && "Continue learning process"}
                    </a>
                </div>
            )
    };
}


function mapStateToProps(state) {
    return {
      user: state.store.user
    };
  }
  
  export default connect(mapStateToProps)(Banner);