import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import {changePassword , closeChangePassword} from '../../../actions/user';

class ChangePassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.onChangePasswordClick = this.onChangePasswordClick.bind(this);
  }

  onInputChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value
    }, () => {
      // callbacks
    });
  }

  onChangePasswordClick() {
    let model = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword,
      }
    };
    this.props.dispatch(changePassword(model));
  }

  onCloseClick() {
    this.props.dispatch(closeChangePassword());
}

  render() {
    let { changePasswordRequired, resetPasswordRequired } = this.props;
    const modalBodyStyle = {
      maxHeight: '400px',
      overflow: 'auto'
    };

    console.log(changePasswordRequired, resetPasswordRequired);
    
    return (
      <div>
        <Modal isOpen={(resetPasswordRequired || changePasswordRequired) == true ? true : false} className={this.props.className}>
          <ModalHeader>  Change Password </ModalHeader>
          <ModalBody style={modalBodyStyle}>
            <div id="login-form" method="post">
              <div className="form-group">
                <label className="label-material">Old Password</label>
                <input id="oldPassword" type="password"
                  value={this.state.password}
                  onChange={this.onInputChange}
                  name="oldPassword" required="" className="form-control" />
              </div>
              <div className="form-group">
                <label className="label-material">New Password</label>
                <input id="newPassword" type="password"
                  value={this.state.password}
                  onChange={this.onInputChange}
                  name="newPassword" required="" className="form-control" />
              </div>
              <div className="form-group">
                <label className="label-material">Confirm Password</label>
                <input id="confirmPassword" type="password"
                  value={this.state.confirmPassword}
                  onChange={this.onInputChange}
                  name="confirmPassword" required="" className="form-control" />
                  <small className="form-text text-muted">At least 8 characters long, 1 Uppercase, 1 Number, 1 special character</small>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onChangePasswordClick}>Change Password</Button>{' '}
            {changePasswordRequired && <Link to="/" className="btn btn-secondary" onClick={this.onCloseClick}> Close</Link> }
            {resetPasswordRequired &&  <Link to="/logout" className="btn btn-secondary"> Logout</Link> }
          </ModalFooter>
        </Modal>
        {resetPasswordRequired && <div className="vh-100"></div>}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.store.user
  };
}
  
export default connect(mapStateToProps)(ChangePassword);