import * as actions from "../../actiontypes/seminars";
import APIServices from "../../services";
import { processResponse } from "../../actions";
import { NotificationManager } from 'react-notifications';

export const getAllSeminarCompanies = () => {
    return dispatch => APIServices.getAllSeminarCompanies()
        .then(response => response.json())
        .then(response => {
            dispatch(seminarCompaniesReceived(response));
        })
        .catch(err => {
            console.log(err);
        })
};

export const seminarCompaniesReceived = (response) => {
    return {
        type: actions.SEMINARCOMPANIES,
        payload: {
            response: response
        }
    };
};

export const deleteSeminarCompany = (model) => {
    return dispatch => APIServices.deleteSeminarCompany(model)
        .then(response => response.json())
        .then(data => {
            dispatch(getAllSeminarCompanies());
            NotificationManager.success('Company deleted Successfully.', 'Success');
        })  
        .catch(err => {
        })
};

export const addSeminarCompany = (model) => {
    console.log(model);
    return dispatch => APIServices.addSeminarCompany(model)
        .then(response => response.json())
        .then(data => {
            dispatch(getAllSeminarCompanies());
            NotificationManager.success('Company added Successfully.', 'Success');
        })
        .catch(err => {
            NotificationManager.success('Error in adding seminar company details.', 'Error');
            // dispatch(errorUser("error"))
        })
};

export const updateSeminarCompany = (model) => {
    
    return dispatch => APIServices.updateSeminarCompany(model)
        .then(response => response.json())
        .then(data => {
            dispatch(getAllSeminarCompanies());
            NotificationManager.success('Company updated Successfully.', 'Success');
        })
        .catch(err => {
            
        })
};

export const getAllSeminars = () => {
    return dispatch => APIServices.getAllSeminars()
        .then(response => response.json())
        .then(response => {
            dispatch(seminarsReceived(response));
        })
        .catch(err => {
            console.log(err);
        })
};

export const seminarsReceived = (response) => {
    return {
        type: actions.SEMINARS,
        payload: {
            response: response
        }
    };
};

export const deleteSeminar = (model) => {
    return dispatch => APIServices.deleteSeminar(model)
        .then(response => response.json())
        .then(data => {
            dispatch(getAllSeminars());
            NotificationManager.success('Seminar deleted Successfully.', 'Success');
        })  
        .catch(err => {
            NotificationManager.success('Error in deleting seminar.', 'Error');
        })
};

export const addSeminar = (model) => {
    console.log(model);
    return dispatch => APIServices.addSeminar(model)
        .then(response => response.json())
        .then(data => {
            dispatch(getAllSeminars());
            NotificationManager.success('Seminar added Successfully.', 'Success');
        })
        .catch(err => {
            console.log(err);
        })
};

export const updateSeminar = (model) => {
    
    return dispatch => APIServices.updateSeminar(model)
        .then(response => response.json())
        .then(data => {
            dispatch(getAllSeminars());
            NotificationManager.success('Seminar updated Successfully.', 'Success');
        })
        .catch(err => {
            
        })
};