import React from 'react';
import { connect } from 'react-redux';
import APIServices from "../../../services";


class AdminWidgets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeUsersInLastSevenDays: 0,
      totalNoOfUsers: 0,
      totalCustomers: 0
    };
    this.initDashboard = this.initDashboard.bind(this);
    // this.toggleTermsModel = this.toggleTermsModel.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.initDashboard();
  }

  // toggleTermsModel(bool) {
  //   const { user } = this.props.user;
  //   if (bool) {
  //     this.props.dispatch(addAgreementTimeStamp(user.name));  
  //   }
  // }

  initDashboard() {
    APIServices.getDashboardData()
      .then(response => response.json())
      .then(response => {
        this.setState({
          ...this.state,
          activeUsersInLastSevenDays: response.activeUsersInLastSevenDays,
          totalNoOfUsers: response.totalNoOfUsers,
          totalCustomers: response.totalCustomers
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    return (
      <div>
        {/* <TermsConditionDialog name="Terms and Condition Dialog" isOpen={user.agreementTimeStamp === null? true : false} toggle={this.toggleTermsModel} className="modal-dialog modal-lg" /> 
          use if want to show popup on role other than user than only uncomment all  things in this file
        */}
        <div className="container-fluid bg-color">
          <div className="row py-3">
            <div className="col-md-3 px-4 py-2">
              <div className="rounded shadow bg-colorLead d-flex flex-row">
                <div className="d-flex align-items-center p-2">
                  <i className="fa fa-users fa-3x text-danger" />
                </div>
                <div>
                  <div className="lead p-3 ">
                    <h4>  <strong> {this.state.activeUsersInLastSevenDays}</strong></h4>
                    <small className="text-muted">
                                        Active users in the past 7 days
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 px-4 py-2">
              <div className="rounded shadow bg-colorLead d-flex flex-row">
                <div className="d-flex align-items-center p-2">
                  <i className="fa fa-users fa-3x text-info" />
                </div>
                <div>
                  <div className="lead p-3 ">
                    <h4><strong> {this.state.totalNoOfUsers}</strong></h4>
                    <small className="text-muted">
                                        Total number of users
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 px-4 py-2 ">
              <div className="rounded shadow bg-colorLead d-flex flex-row">
                <div className="d-flex align-items-center p-2">
                  <i className="fa fa-users fa-3x text-danger" />
                </div>
                <div>
                  <div className="lead p-3 ">
                    <h4>  <strong> {this.state.totalCustomers}</strong></h4>
                    <small className="text-muted">
                                        Total customers
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.store,
    user: state.store.user
  };
}

export default connect(mapStateToProps)(AdminWidgets);
