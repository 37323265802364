import React from 'react';
import { Redirect } from 'react-router-dom';
import LoaderFull from './../components/loader-full';
import { connect } from 'react-redux';

class RedirectPage extends React.Component {

  render() {

    if (localStorage.getItem("redirect") === "true" && this.props.user.initDone) {
      localStorage.removeItem('redirect');
      if (this.props.user.user.role === "user") {
        return <Redirect to="/" />;
      } else {
        return <Redirect to="/admin" />;
      }
    } 
    return (
      <LoaderFull />
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.store.user
  };
}

export default connect(mapStateToProps)(RedirectPage);