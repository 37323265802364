import React from 'react';
import { BrowserRouter as Router, Route, Link, hashHistory, Switch } from 'react-router-dom';
import ManagePostTest from './managePostTest';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { getPostTestsByModule, deletePosttest } from "./../../../actions/posttest";
import SearchPanel from "./../components/searchPanel";

class PostTestList extends React.Component {
    constructor(props) {
        super(props);
        this.DeletebuttonClick = this.DeletebuttonClick.bind(this);
    }
    UNSAFE_componentWillMount() {
        this.props.dispatch(getPostTestsByModule(this.props.match.params.moduleId));
        //dispatch action to get postTests by module
    }

    onToggleDropDown = (toggleDropDown) => {
        // do your stuff here
        toggleDropDown();
    }

    renderSizePerPageDropDown = (props) => {
        return (
            // eslint-disable-next-line react/jsx-no-undef
            <SizePerPageDropDown
                className='my-size-per-page'
                btnContextual='btn-info'
                variation='dropup'
                onClick={() => this.onToggleDropDown(props.toggleDropDown)} />
        );
    }
    renderCustomClearSearch = (onClick) => {
        return ( <button className='ml-2 btn btn-success' onClick={onClick}> clear </button>);
    }
    createCustomExportCSVButton = (onClick) => {
        return (
            <button className="btn btn-info" onClick={onClick}>Custom Export CSV Btn</button>
        );
    }

    DeletebuttonClick(id, row, rowIndex) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are you sure you want to delete posttest ?")) {
            this.props.dispatch(deletePosttest(id));
        }
    }

    cellButton(cell, row, enumObject, rowIndex) {
        return (
            <div>
                <Link to={"/admin/modules/editModule/" + this.props.match.params.moduleId + "/postTest/"+cell}
                 className="btn btn-info"> Edit </Link>
                <Link to={"#"} className="btn btn-danger" onClick={() =>
                    this.DeletebuttonClick(cell, row, rowIndex)}> Delete </Link>
            </div>
        )
    }


    render() {
        const options = {
            page: 1,  // which page you want to show as default
            sizePerPageList: [{
                text: '2', value: 2
            }, {
                text: '5', value: 5
            }, {
                text: '10', value: 10
            }, {
                text: 'All', value: this.props.model.postTests.length
            }], // you can change the dropdown list for size per page
            sizePerPage: 5,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            //paginationPosition: 'top'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
            exportCSVBtn: this.createCustomExportCSVButton,
            clearSearch: true,
            clearSearchBtn: this.renderCustomClearSearch,
            searchPanel: (props) => (<SearchPanel { ...props } />),
            sizePerPageDropDown: this.renderSizePerPageDropDown
        };
            return (<div>
               
                <div className="p-3">
                    <Link to={"/admin/modules/editModule/" + this.props.match.params.moduleId + "/postTest/0"} className="btn btn-info"> Add Question </Link>
                </div>
                <div className="p-3">
                    <Link to={"/admin/modules/editModule/" + this.props.match.params.moduleId} className="btn btn-dark mr-2"> Back to Module</Link>
                </div>
                { this.props.model.postTests.length > 0 &&
                    <div className="p-3">
                        <BootstrapTable data={this.props.model.postTests} exportCSV search striped hover info version='4' className="thead-inverse" options={options} pagination>
                            <TableHeaderColumn isKey dataField='id' dataSort caretRender={this.getCaret}>No</TableHeaderColumn>
                            <TableHeaderColumn dataField='name' dataSort caretRender={this.getCaret}>Questions</TableHeaderColumn>
                            <TableHeaderColumn dataField='id' dataFormat={this.cellButton.bind(this)}
                            />
                        </BootstrapTable>
                    </div>
                }
            </div>
            );
    }
}

function mapStateToProps(state) {
    return {
      model: state.store.posttest
    };
  }
  
  export default connect(mapStateToProps)(PostTestList);