import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllModules } from "./../../../actions/modules";
import SearchPanel from "./../components/searchPanel";
import { deleteModule } from "./../../../actions/modules";
import { convertFirstCharacterCapital } from './../components/helper';
import ConfirmDialog from './../../components/models/confirm-dialog';
import { renderSizePerPageDropDown, renderCustomClearSearch, createCustomExportCSVButton } from './../../adminDashboard/components/helper';

class ModuleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModelShow: false
    }
    this.toggleDelete = this.toggleDelete.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(getAllModules());
  }

  cellButton(cell) {
    return (
      <div>
        <Link to={"/admin/modules/EditModule/" + cell} className="btn btn-info p-1"> <small>Edit </small></Link>
        <button className="btn btn-danger p-1" onClick={this.setDelete.bind(this, cell)}> <small>Delete </small></button>
      </div>
    )
  }

  setDelete(id) {
    let me = this;
    me.setState({
      deleteModelShow: !me.state.deleteModelShow,
      modelId: id
    });
  }

  toggleDelete(bool) {
    if (bool) {
      this.props.dispatch(deleteModule(this.state.modelId));
    }
    this.setState({
      deleteModelShow: !this.state.deleteModelShow,
      modelId: 0
    });
  }

  convertFirstCharacterCapital(cell) {
      if (cell == "basic"){
        return "Foundational";
      }
      return (convertFirstCharacterCapital(cell))
  }

  render() {
    const options = {
      page: 1,  // which page you want to show as default
      sizePerPageList: [{
		  text: '25', value: 25
	  }, {
		text: '50', value: 50
	  }, {
        text: 'All', value: this.props.model.modules.length
      }], // you can change the dropdown list for size per page
	  sizePerPage: this.props.model.modules.length,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      exportCSVBtn: createCustomExportCSVButton,
      clearSearch: true,
      clearSearchBtn: renderCustomClearSearch,
      searchPanel: (props) => (<SearchPanel { ...props } />),
      sizePerPageDropDown: renderSizePerPageDropDown
    };


    if (this.props.model.modules.length > 0) {
      return (<div>
        <div className="p-3">
          <Link to='/' className="btn btn-dark mr-2"> Back to Home</Link>
          <a href='/api/posttest/postTestUserFeedBack/getCsv' download className="btn btn-dark mr-2"> Export PostTest Feedback</a>
        </div>
        <div className="p-3">
          <BootstrapTable data={this.props.model.modules} exportCSV search striped hover info version='4' className="thead-inverse" options={options} pagination>
            <TableHeaderColumn isKey dataField='id' dataSort>#</TableHeaderColumn>
            <TableHeaderColumn dataField='name' dataSort>Header</TableHeaderColumn>
            <TableHeaderColumn dataField='type' dataFormat={this.convertFirstCharacterCapital.bind(this)}>Type</TableHeaderColumn>
            <TableHeaderColumn dataField='description' dataSort>Description</TableHeaderColumn>
            <TableHeaderColumn dataField='id' dataFormat={this.cellButton.bind(this)} />
          </BootstrapTable>
          <ConfirmDialog name="wow" isOpen={this.state.deleteModelShow} toggle={this.toggleDelete} modelTitle={"Confirm"} modelBody={"Are you sure, you want to delete module?"} />
        </div>
      </div>
      );
    } else {
      return (<div>
        <div className="p-3 text-center">
          Loading ...
        </div>
      </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    model: state.store.module
  };
}

export default connect(mapStateToProps)(ModuleList);