import * as actions from "../../actiontypes/techniques";
import APIServices from "../../services";
import { processResponse } from "../../actions";
import { NotificationManager } from 'react-notifications';

export const getAllTechniques = () => {
    return dispatch => APIServices.getAllTechniques()
        .then(response => response.json())
        .then(response => {
            dispatch(techniquesReceived(response));
            // if (data.response === "success") {
            //     dispatch(successUser("success"))
            // } else {
            //     dispatch(errorUser("error"))
            // }
        })
        .catch(err => {
            console.log(err);
        })
};

export const techniquesReceived = (response) => {
    return {
        type: actions.TECHNIQUES,
        payload: {
            response: response
        }
    };
};

export const getTechniquesWithDetailsReceived = (response) => {
    return {
        type: actions.TECHNIQUES_WITH_DETAILS,
        payload: {
            response: response
        }
    };
};


export const addTechniques = (model) => {
    console.log(model);
    return dispatch => APIServices.addTechniques(model)
        .then(response => response.json())
        .then(data => {
            // if (data.response === "success") {
            //     dispatch(successUser("success"))
            // } else {
            //     dispatch(errorUser("error"))
            // }
            dispatch(getAllTechniques());
            NotificationManager.success('Technique added Successfully.', 'Success');
        })
        .catch(err => {
            // dispatch(errorUser("error"))
        })
};

export const deleteTechniques = (model) => {
    console.log(model);
    return dispatch => APIServices.deleteTechniques(model)
        .then(response => response.json())
        .then(data => {
            dispatch(getAllTechniques());
            NotificationManager.success('Technique deleted Successfully.', 'Success');
        })  
        .catch(err => {
        })
};

export const updateTechnique = (model) => {
    
    return dispatch => APIServices.updateTechnique(model)
        .then(response => response.json())
        .then(data => {
            dispatch(getAllTechniques());
            NotificationManager.success('Technique updated Successfully.', 'Success');
        })
        .catch(err => {
            
        })
};

export const getTechniques = (model) => {
   
    return dispatch => APIServices.getTechniques(model)
        .then(processResponse)
        .then(data => {
            // if (data.response === "success") {
            dispatch(getTechniquesReceived(data))
            // } else {
            //     dispatch(errorUser("error"))
            // }
        })
        .catch(err => {
            // dispatch(errorUser("error"))
        })
};

export const getAllTechniquesWithDetails = () => {
   
    return dispatch => APIServices.getAllTechniquesWithDetails()
        .then(processResponse)
        .then(data => {
            dispatch(getTechniquesWithDetailsReceived(data))
        })
        .catch(err => {
        })
};


export const getTechniquesStart = () => {
    return {
        type: actions.TECHNIQUES_GET_START
    };
};


export const getTechniquesReceived = (response) => {
    return {
        type: actions.TECHNIQUES_GET,
        payload: {
            response: response
        }
    };
};

export const getTechniqueSearch = (searchText) => {
    return APIServices.getTechniqueSearch(searchText)
      .then(response => response.json())
      .then(response => {
        return {
          options: response.map(function (elem) {
            return { "name": elem.name, "id": elem.id }
          })
        };
      })
      .catch(err => {
        return { options: [] };
      });
  
  };
