import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
// import FaSort from 'react-icons/lib/fa/sort';
// import FaSortAsc from 'react-icons/lib/fa/sort-asc';
// import FaSortDesc from 'react-icons/lib/fa/sort-desc';
import { Scrollbars } from 'react-custom-scrollbars';

//
export default class DashboardBottom extends React.Component {
  constructor(props) {
    super(props);
  }
  //this.handleCategoryCheckboxChange = this.handleCategoryCheckboxChange.bind(this);
  //this.handleSegmentCheckboxChange = this.handleSegmentCheckboxChange.bind(this);
  // }

  // renderShowsTotal(start, to, total) {
  //     return (
  //         <p className="text-strat">
  //             From {start} to {to}, totals is {total}&nbsp;&nbsp;(its a customize text)
  //       </p>
  //     );
  // }

  // getCaret(direction) {
  //     if (direction === 'asc') {
  //         return (
  //             <FaSortAsc className="ml-2" />
  //         );
  //     }
  //     if (direction === 'desc') {
  //         return (
  //             <FaSortDesc className="ml-2" />
  //         );
  //     }
  //     return (
  //         <FaSort className="ml-2" />
  //     );
  // }

  // renderSizePerPageDropDown = props => {
  //     return (
  //       <div className='btn-group'>
  //         {
  //            props.sizePerPageList.map((n, idx) => {
  //             const isActive = (Number(n.value) === Number(props.currSizePerPage)) ? 'active' : null;
  //             return (
  //               <button key={ idx } type='button' className={ `btn btn-outline-primary ${isActive}` } onClick={ () => props.changeSizePerPage(n.value) }>{ n.text }</button>
  //             );
  //           })
  //         }
  //       </div>
  //     );
  // }

  // renderThumb({ style, ...props }) {
  //     let styleParent = {...style, width : 15}
  //     const thumbStyle = { width : 15 };
  //     return (
  //         <div className="bg-info"
  //             style={{ ...styleParent,...thumbStyle}}
  //             {...props}/>
  //     );
  // }

    
  // onCategorySelectedAll(bool){
  //     this.setState({
  //         selectedCategories : [],
  //         selectCategoryAll : bool
  //     });
  // }

  // onSegmentSelectedAll(bool){
  //     this.setState({
  //         selectedSegments : [],
  //         selectSegemetAll : bool
  //     });
  // }

  // handleCategoryCheckboxChange(id, event) {
  //     let selectedCategories = this.state.selectedCategories;
  //     if(event.target.checked){
  //         selectedCategories.push(id)
  //     }else{
  //         selectedCategories.pop(id)
  //     }
  //     this.setState({
  //         selectedCategories : selectedCategories
  //     });
  // }

  // handleSegmentCheckboxChange(id, event) {
  //     let selectedSegments = this.state.selectedSegments;
  //     if(event.target.checked){
  //         selectedSegments.push(id)
  //     }else{
  //         selectedSegments.pop(id)
  //     }
  //     this.setState({
  //         selectedSegments : selectedSegments
  //     });
  // }

  render() 
  {
    // let { segments, selectCategoryAll, selectedCategories, selectSegemetAll, selectedSegments } = this.state;
    // let model = {};
    // model.modules = [];
    // model.segments = [];

    // let handleCategoryCheckboxChange = this.handleCategoryCheckboxChange;
    // let handleSegmentCheckboxChange = this.handleSegmentCheckboxChange;

    // let products = [
    //     {
    //         id: 1,
    //         title: "Title 01",
    //         module: "FEELINGS",
    //         segment: "Basic Skill"
    //     }, {
    //         id: 2,
    //         title: "Title 02",
    //         module: "ASSESSMENT",
    //         segment: "Convergence"
    //     }, {
    //         id: 3,
    //         title: "Title 03",
    //         module: "EMOTION",
    //         segment: "Therapeutic Tasks"
    //     }, {
    //         id: 4,
    //         title: "Title 04",
    //         module: "BRAIN",
    //         segment: "Principles"
    //     }, {
    //         id: 5,
    //         title: "Title 05",
    //         module: "FAMILY",
    //         segment: "Feelings"
    //     }, {
    //         id: 6,
    //         title: "Title 06",
    //         module: "DEFENSES",
    //         segment: "Basic Skill"
    //     },
    //     {
    //         id: 7,
    //         title: "Title 07",
    //         module: "COGNITION",
    //         segment: "Convergence"
    //     }, {
    //         id: 8,
    //         title: "Title 08",
    //         module: "ANXIETY",
    //         segment: "Therapeutic Tasks"
    //     }, {
    //         id: 9,
    //         title: "Title 09",
    //         module: "SOCIAL",
    //         segment: "Principles"
    //     },
    //     {
    //         id: 10,
    //         title: "Title 10",
    //         module: "ATTACHMENT",
    //         segment: "Feelings"
    //     }, {
    //         id: 11,
    //         title: "Title 11",
    //         module: "TRIADS",
    //         segment: "Basic Skill"
    //     }, {
    //         id: 12,
    //         title: "Title 12",
    //         module: "DYADS",
    //         segment: "Convergence"
    //     }, {
    //         id: 13,
    //         title: "Title 13",
    //         module: "DYADS",
    //         segment: "TREATMENT PLANNING"
    //     }, {
    //         id: 14,
    //         title: "Title 14",
    //         module: "DYADS",
    //         segment: "TREATMENT PACKAGES"
    //     }, {
    //         id: 15,
    //         title: "Title 15",
    //         module: "DYADS",
    //         segment: "TREATMENT FORMATS"
    //     }, {
    //         id: 16,
    //         title: "Title 16",
    //         module: "DYADS",
    //         segment: "CLINICAL DECISION MAKING"
    //     }, {
    //         id: 17,
    //         title: "Title 17",
    //         module: "DYADS",
    //         segment: "THERAPEUTIC STRATEGIES"
    //     }, {
    //         id: 18,
    //         title: "Title 18",
    //         module: "DYADS",
    //         segment: "TREATMENT MONITORING"
    //     }, {
    //         id: 19,
    //         title: "Title 19",
    //         module: "DYADS",
    //         segment: "ALLIANCE MAINTENANCE"
    //     }, {
    //         id: 20,
    //         title: "Title 20",
    //         module: "DYADS",
    //         segment: "PRINCIPLES OF TREATMENT"
    //     },
    //     {
    //         id: 21,
    //         title: "Title 21",
    //         module: "DYADS",
    //         segment: "TECHNOLOGY BASED APPROACHES"
    //     }
    // ];

    // const options = {
    //     page: 1,  // which page you want to show as default
    //     sizePerPageList: [ {
    //         text: '10', value: 10
    //     },
    //     {
    //         text: '20', value: 20
    //     }, 
    //      {
    //         text: 'All', value: products.length
    //     }], // you can change the dropdown list for size per page
    //     sizePerPage: 10,  // which size per page you want to locate as default
    //     pageStartIndex: 1, // where to start counting the pages
    //     paginationSize: 3,  // the pagination bar size.
    //     prePage: 'Prev', // Previous page button text
    //     nextPage: 'Next', // Next page button text
    //     firstPage: 'First', // First page button text
    //     lastPage: 'Last', // Last page button text,
    //     sizePerPageDropDown: this.renderSizePerPageDropDown
    //     //,paginationShowsTotal: this.renderShowsTotal  // Accept bool or function
    //     //paginationPosition: 'top'  // default is bottom, top and both is all available
    //     // hideSizePerPage: true > You can hide the dropdown for sizePerPage
    //     // alwaysShowAllBtns: true // Always show next and previous button
    //     // withFirstAndLast: false > Hide the going to First and Last page button
    // };

    return (
      <div>
        {/*<div className="container-fluid text-info text-center p-5 h1 mb-0">
                    ALL MODULE AND LESSONS
                 </div>
                    
                <div className="container-fluid text-secondary">
                    <div className="row">
                        <div className="col">
                            <hr className="hr-color"/>
                            <div className="justify-content-center">
                                <div className="text-center h4 text-dark"> BY MODULE </div>
                                <div className="text-center h5"> 
                                    <button type="button" onClick={this.onCategorySelectedAll.bind(this,true)} className="btn btn-info mr-1">Select ALL</button>
                                    <button type="button" onClick={this.onCategorySelectedAll.bind(this,false)} className="btn btn-info">Deselect All</button>
                                </div>
                            </div>
                            <hr className="hr-color"/>
                            <div className="d-flex justify-content-center">
                                <Scrollbars style={{ width: 200, height: 200 }} renderThumbHorizontal={this.renderThumb} renderThumbVertical={this.renderThumb}>
                                    { 
                                        modules.map((module, i) => {
                                            let checked = selectCategoryAll ? selectCategoryAll : (typeof(selectedCategories.find(x=>x == module.id)) != typeof(undefined) ? true : false);
                                            return (
                                                <div key={i} className="col-auto">
                                                    <label className="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
                                                        <input type="checkbox" className="custom-control-input" onChange={ handleCategoryCheckboxChange.bind(this, module.id) } checked={checked}/>
                                                        <span className="custom-control-indicator"></span>
                                                        <span key={i} className="custom-control-description h6 text-dark">{module.name}</span>
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }
                                </Scrollbars>
                            </div>
                        </div>
                        <hr />
                        <div className="col">
                             <hr className="hr-color"/>
                            <div className="justify-content-center">
                                <div className="text-center h4 text-dark"> BY SEGMENT </div>
                                <div className="text-center h5"> 
                                    <button type="button" onClick={this.onSegmentSelectedAll.bind(this,true)} className="btn btn-info mr-1">Select ALL</button>
                                    <button type="button" onClick={this.onSegmentSelectedAll.bind(this,false)} className="btn btn-info">Deselect All</button>
                                </div>
                            </div>
                            <hr className="hr-color"/>
                            <div className="d-flex justify-content-center">
                                <Scrollbars style={{ width: 200, height: 200 }} renderThumbHorizontal={this.renderThumb} renderThumbVertical={this.renderThumb}>
                                    { 
                                        segments.map((segment, i) => {
                                            let checked = selectSegemetAll ? selectSegemetAll : (typeof(selectedSegments.find(x=>x == segment.id)) != typeof(undefined) ? true : false);
                                            return (
                                                <div key={i} className="col-auto">
                                                    <label className="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
                                                        <input type="checkbox" className="custom-control-input" onChange={ handleSegmentCheckboxChange.bind(this, segment.id) } checked={checked}/>
                                                        <span className="custom-control-indicator"></span>
                                                        <span key={i} className="custom-control-description h6 text-dark">{segment.name}</span>
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                </div>*/}
        <hr className="hr-color"/>
        {/*<div className="p-3">
                    <BootstrapTable data={products} striped hover className="thead-inverse" version='4' options={options} pagination>
                        <TableHeaderColumn isKey dataField='id' dataSort caretRender={this.getCaret}>#</TableHeaderColumn>
                        <TableHeaderColumn dataField='title' dataSort caretRender={this.getCaret}>Title</TableHeaderColumn>
                        <TableHeaderColumn dataField='module' dataSort caretRender={this.getCaret}>Module</TableHeaderColumn>
                        <TableHeaderColumn dataField='segment' dataSort caretRender={this.getCaret}>Segment</TableHeaderColumn>
                    </BootstrapTable>
                </div>*/}
      </div>
    );
  }
}