import React from 'react';
import Spinner from 'react-spinkit';
import style from './loader.scss';

export default class LoaderFull extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {

            return (
                <div className="loader-container">
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
             </div>
            )
    };
}
