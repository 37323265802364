import * as actions from "../../actiontypes/approaches";
import APIServices from "../../services";
import { processResponse } from "..";
import {NotificationManager} from 'react-notifications';    

export const getAllApproaches = () => {
  return dispatch => APIServices.getAllApproaches()
    .then(response => response.json())
    .then(response => {
      dispatch(approachesReceived(response));
    })
    .catch(err => {
      console.log(err);
    });
};

export const approachesReceived = (response) => {
  return {
    type: actions.APPROACHES,
    payload: {
      response: response
    }
  };
};

export const getFeedData = () => {
  return dispatch => APIServices.getFeedData()
    .then(response => response.json())
    .then(response => {
      dispatch(feedDataReceived(response));
    })
    .catch(err => {
      console.log(err);
    });
};

export const getModulesTechniquesApproachesNames = () => {
  return dispatch => APIServices.getModulesTechniquesApproachesNames()
    .then(response => response.json())
    .then(response => {
      dispatch(ModulesTechniquesApproachesNamesReceived(response));
    })
    .catch(err => {
      console.log(err);
    });
};

export const ModulesTechniquesApproachesNamesReceived = (response) => {
  return {
    type: actions.MODULES_TECHNIQUES_APPROACHES_NAMES,
    payload: {
      response: response
    }
  };
};


export const feedDataReceived = (response) => {
  return {
    type: actions.FEEDDATA,
    payload: {
      response: response
    }
  };
};

export const addApproach = (model) => {
  console.log(model);
  return dispatch => APIServices.addApproach(model)
    .then(response => response.json())
    .then(data => {
      // if (data.response === "success") {
      //     dispatch(successUser("success"))
      // } else {
      //     dispatch(errorUser("error"))
      // }
      dispatch(getAllApproaches());
      NotificationManager.success('Approach added Successfully..', 'Success');

    })
    .catch(err => {
      // dispatch(errorUser("error"))
    });
};

export const updateApproach = (model) => {
    
  return dispatch => APIServices.updateApproach(model)
    .then(response => response.json())
    .then(data => {
      dispatch(getAllApproaches());
      NotificationManager.success('Approach updated Successfully..', 'Success');
    })
    .catch(err => {
            
    });
};

export const deleteApproach = (model) => {
  console.log(model);
  return dispatch => APIServices.deleteApproach(model)
    .then(response => response.json())
    .then(data => {
      dispatch(getAllApproaches());
      NotificationManager.success('Approach deleted Successfully..', 'Success');
    })
    .catch(err => {
          
    });
};

export const getApproach = (model) => {
   
  return dispatch => APIServices.getApproach(model)
    .then(processResponse)
    .then(data => {
      // if (data.response === "success") {
      dispatch(getApproachReceived(data));
      // } else {
      //     dispatch(errorUser("error"))
      // }
    })
    .catch(err => {
      // dispatch(errorUser("error"))
    });
};

export const getAllApproachesWithDetails = (model) => {
   
  return dispatch => APIServices.getAllApproachesWithDetails(model)
    .then(processResponse)
    .then(data => {
      dispatch(getApproachWithDetailsReceived(data));
    })
    .catch(err => {
    });
};

export const getApproachStart = () => {
  return {
    type: actions.APPROACHES_GET_START
  };
};

export const getApproachReceived = (response) => {
  return {
    type: actions.APPROACHES_GET,
    payload: {
      response: response
    }
  };
};

export const getApproachWithDetailsReceived = (response) => {
  return {
    type: actions.APPROACHES_WITH_DETAILS,
    payload: {
      response: response
    }
  };
};

export const getApproachSearch = (searchText) => {
  return APIServices.getApproachSearch(searchText)
    .then(response => response.json())
    .then(response => {
      return {
        options: response.map(function (elem) {
          return { "name": elem.name, "id": elem.id };
        })
      };
    })
    .catch(err => {
      return { options: [] };
    });
  
};
