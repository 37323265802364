import React, { } from 'react';
import { Link } from 'react-router-dom';

export default class CompaniesHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (<div className="p-3">
            <div className="btn-group" role="group" aria-label="">
                <Link to='/admin/companies/addCompany' className="btn btn-dark mr-2"> Add Companies</Link>
                <Link to='/admin/companies/companyList' className="btn btn-dark mr-2"> List</Link>
            </div>
        </div>
        );
    }
}