class Auth {
	static IsAuthenticated() {
		if (localStorage.expiry) {
			const expiry = new Date(localStorage.expiry);
			const currentTime = new Date();
			if (expiry < currentTime) {
				return false;
			}
		} else {
			return false;
		}
    return Boolean(!!localStorage.IsAuthenticated);
  }

  static logOut() {
    localStorage.removeItem('IsAuthenticated');
  }

  static getToken() {
    let token = null;
    try {
      token = JSON.parse(localStorage.getItem('token')).access_token
    } catch (error) {
      token = null;
    }
    return token
  }

  static addAuthHeader(model) { 
    let token = Auth.getToken();
    let _model = {};
    if(typeof(undefined) == typeof(model) || model == null || model == ""){
      _model = {
          headers : {
            "Authorization" : "Bearer " +token
          }
        }
    }else if(typeof(undefined) == typeof(model.headers) || model.headers == null || model.headers == ""){
      _model = {
        ...model,
        headers : {
          Authorization : "Bearer " +token
        }
      }
    }else{
      _model = {
        ...model,
        headers : {
          ...model.headers,
          Authorization : "Bearer " +token
        }
      }
	  }
    return _model;
  }
}

export default Auth;