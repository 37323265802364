import React from 'react';
import { Redirect } from 'react-router-dom';
import Slider from 'react-slick';
import { getAllModules } from "./../../actions/modules";
import { connect } from 'react-redux';
import Loader from './../components/loader';
import { toSeoUrl } from './../adminDashboard/components/helper';
import Select from 'react-select';
import $ from 'jquery'; 
 
class LearningPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedOption: ''
        }
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        window.location.href = window.location.origin + selectedOption.url;
    }

    UNSAFE_componentWillMount() {
        this.props.dispatch(getAllModules());
    }

	componentDidMount() {
		
    }

    componentWillUnmount() {
    }

    render() {

		// eslint-disable-next-line no-undef
		FreshWidget.init("", { "queryString": "&widgetType=popup&formTitle=Ask+the+Experts&submitTitle=Ask+Question&submitThanks=Thank+you+for+your+question.++Our+experts+will+review+and+will+follow-up+shortly.&attachFile=no", "utf8": "?", "widgetType": "popup", "buttonType": "text", "buttonText": "Ask the Experts", "buttonColor": "white", "buttonBg": "#0000FF", "alignment": "2", "offset": "235px", "submitThanks": "Thank you for your question.  Our experts will review and will follow-up shortly.", "formHeight": "500px", "url": "https://stratpsychexperts.freshdesk.com" });

        let basicCategory = {
            id: 1,
            title: "FOUNDATIONAL",
            categories: this.props.model.modules.filter(x => x.type == "basic").map((_module) => {
                return {
                    id: _module.id,
                    name: _module.name,
                    url: toSeoUrl(String(_module.name + "-" + _module.id))
                };
            })
        }

        let intermediateCategory = {
            id: 2,
            title: "INTERMEDIATE",
            categories: this.props.model.modules.filter(x => x.type == "intermediate").map((_module) => {
                return {
                    id: _module.id,
                    name: _module.name,
                    url: toSeoUrl(String(_module.name + "-" + _module.id))
                };
            })
        }

        let advancedCategory = {
            id: 3,
            title: "ADVANCED",
            categories: this.props.model.modules.filter(x => x.type == "advanced").map((_module) => {
                return {
                    id: _module.id,
                    name: _module.name,
                    url: toSeoUrl(String(_module.name + "-" + _module.id))
                };
            })
        }

        let categories = [basicCategory, intermediateCategory, advancedCategory];

        let settings = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            // the magic
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    dots: true
                }
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    dots: true
                }
            }]
        };

        let model = {};
        model.categories = [];

        categories.forEach(function (category, i) {
            var _titleshow = (i == 0 ? "" : " collapsed");
            let _title = <div className="card-header bg-white border-bottom-0" id={"heading"+i}>
                <h5 className="mb-0">
                    <button className={"btn btn-info btn-block" + _titleshow} data-toggle="collapse" data-target={"#collapse" + i} aria-controls={"#collapse" + i} aria-expanded={i == 0 ? "true" : ""}>
                        {category.title} 
                    </button>
                </h5>
            </div>;

            let _categories = [];
            category.categories.forEach(function (_subcategory, i) {
                _categories.push(
					<li key={i} className="list-group-item list-group-item-action p-0">
						<a href={'module/' + _subcategory.id} className="text-secondary p-2 d-block"> {_subcategory.name} </a>
                    </li>
                );
            });
            let show = (i == "0" ? " show" : "");
            
            let _category = <div key={i} className="card">
                                {_title}
                <div id={"collapse" + i} className={"collapse " + show} aria-labelledby={"#heading" + i} data-parent="#accordion">
                                    <div className="card-body">
                                        <ul className="list-group">
                                            {_categories}
                                        </ul>
                                    </div>
                                </div>
                            </div>;

            model.categories.push(_category);

        });

        $(function () {
            $('[data-toggle="popover"]').popover()
        })

        const { selectedOption } = this.state;
        const value = selectedOption && selectedOption.value;

        return (<div>
            <div className="container-fluid  text-center text-info bg-white pt-3">
               
                <div className="row">
                    <div className="col-md-4">
                        <div className="h3 py-2 bg-white ">StratPsych <sup>&reg;</sup> Navigator</div>
                        {/* <div className="text-left bg-white text-dark p-3 text-over-flow">
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Description" data-content="This is a brief description of the module" data-placement="top" className="no-uderline pb-2 text-over-flow"><strong>Description. </strong> This is a brief description of the module</a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Learning Objectives" data-content="Each module states the objectives you will achieve." data-placement="top" className="no-uderline  pb-2 text-over-flow"><strong>Learning Objectives. </strong>Each module states the objectives you will achieve.</a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Definition" data-content="Each domain includes a brief definition to orient you to the topic." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Definition. </strong>Each domain includes a brief definition to orient you to the topic.</a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Orientation Diagram" data-content="Where it is appropriate we will visually depict inter-relationships among modules to better orient your learning." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Orientation Diagram.</strong>Where it is appropriate we will visually depict inter-relationships among modules to better orient your learning.</a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Module" data-content="This is a summary of the basic information for each domain." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Module. </strong>This is a summary of the basic information for each domain.</a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Convergence" data-content="This summarizes the commonalities that various approaches and research suggest are important." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Convergence. </strong>This summarizes the commonalities that various approaches and research suggest are important.</a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Therapeutic Goals" data-content="This presents the basic general goals derived from various approaches relating to the specific domain." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Therapeutic Goals. </strong>This presents the basic general goals derived from various approaches relating to the specific domain.</a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Therapeutic Tasks" data-content="This refers to specific therapeutic tasks that you should try to achieve in your work." data-placement="top" className="no-uderline text-over-flow  pb-2"><strong>Therapeutic Tasks. </strong>This refers to specific therapeutic tasks that you should try to achieve in your work.</a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Principles" data-content="This is a summary of the essential principles of working in the domain." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Principles. </strong>This is a summary of the essential principles of working in the domain.</a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Basic Skills" data-content="Here we highlight the basic skills for you to master and give you some suggestions for practicing them." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Basic Skills.</strong>Here we highlight the basic skills for you to master and give you some suggestions for practicing them.</a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Pattern Recognition Tools" data-content="These are helpful guides that visually depict processes and component parts of domains, as well as inter-relationships necessary for understanding the domains." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Pattern Recognition Tools. </strong>These are helpful guides that visually depict processes and component parts of domains, as well as inter-relationships necessary for understanding the domains.</a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Heuristics—Clinical Rules of Thumb" data-content="There are certain decision making rules that develop from extensive clinical experience." data-placement="top" className="no-uderline text-over-flow  pb-2"><strong>Heuristics—Clinical Rules of Thumb.</strong>There are certain decision making rules that develop from extensive clinical experience. </a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Psychotherapedia" data-content="We will select a group of techniques from this compendium that are helpful to consider when working in this domain." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Psychotherapedia<sup>&reg;</sup>.</strong>We will select a group of techniques from this compendium that are helpful to consider when working in this domain.</a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Approaches" data-content=" We offer a list of approaches that are useful in the domain and generally offer training for those interested." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Approaches. </strong> We offer a list of approaches that are useful in the domain and generally offer training for those interested.</a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Module Post-Test" data-content="Each module ends with a post-test that you take to receive CEs and confirm you have completed the objectives." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Module Post-Test. </strong>Each module ends with a post-test that you take to receive CEs and confirm you have completed the objectives</a></div>

                        </div> */}

                        <div className="text-left bg-white text-dark p-3 text-over-flow">
                            <div className="pb-2 text-over-flow">
                                <a href="#" data-toggle="popover" data-trigger="focus" title="Description" data-content="This is a brief description of the module" data-placement="top" className="no-uderline pb-2 text-over-flow">
                                    <strong>Description</strong> </a></div>
                            <div className="pb-2 text-over-flow">
                                <a href="#" data-toggle="popover" data-trigger="focus" title="Learning Objectives" data-content="Each module states the objectives you will achieve." data-placement="top" className="no-uderline  pb-2 text-over-flow">
                                    <strong>Learning Objectives </strong></a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Definition" data-content="Each domain includes a brief definition to orient you to the topic." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Definition </strong></a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Orientation Diagram" data-content="Where it is appropriate we will visually depict inter-relationships among modules to better orient your learning." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Orientation Diagram</strong></a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Module" data-content="This is a summary of the basic information for each domain." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Module </strong></a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Convergence" data-content="This summarizes the commonalities that various approaches and research suggest are important." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Convergence </strong></a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Therapeutic Goals" data-content="This presents the basic general goals derived from various approaches relating to the specific domain." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Therapeutic Goals </strong></a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Therapeutic Tasks" data-content="This refers to specific therapeutic tasks that you should try to achieve in your work." data-placement="top" className="no-uderline text-over-flow  pb-2"><strong>Therapeutic Tasks </strong></a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Principles" data-content="This is a summary of the essential principles of working in the domain." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Principles </strong></a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Basic Skills" data-content="Here we highlight the basic skills for you to master and give you some suggestions for practicing them." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Basic Skills</strong></a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Pattern Recognition Tools" data-content="These are helpful guides that visually depict processes and component parts of domains, as well as inter-relationships necessary for understanding the domains." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Pattern Recognition Tools </strong></a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Heuristics—Clinical Rules of Thumb" data-content="There are certain decision making rules that develop from extensive clinical experience." data-placement="top" className="no-uderline text-over-flow  pb-2"><strong>Heuristics—Clinical Rules of Thumb</strong></a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Psychotherapedia" data-content="We will select a group of techniques from this compendium that are helpful to consider when working in this domain." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Psychotherapedia<sup>&reg;</sup></strong></a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Approaches" data-content=" We offer a list of approaches that are useful in the domain and generally offer training for those interested." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Approaches </strong> </a></div>
                            <div className="pb-2 text-over-flow"><a href="#" data-toggle="popover" data-trigger="focus" title="Module Post-Test" data-content="Each module ends with a post-test that you take to receive CEs and confirm you have completed the objectives." data-placement="top" className="no-uderline text-over-flow pb-2"><strong>Module Post-Test </strong></a></div>

                        </div>

                    </div>
                    <div className="col-md-8 border border-hr border-top-0 border-right-0 border-bottom-0 border-hide-mobile border-mobile-top" id="accordion">
                        <div className="h5 p-3 text-bold text-info text-center">
                            <p className="p-0 m-0">
                                <span className="h4"> <strong>Welcome! </strong></span>Get started by selecting a level or searching for a topic.
                            </p>
                        </div>
                        <div>
                            <div className="p-3">
                                <Select
                                    name="form-field-name"
                                    value={value}
                                    onChange={this.handleChange}
                                    options={this.props.model.modules.map((_module) => {
                                        return {
                                            value: _module.id,
                                            label: _module.name,
                                            url: "/module/" + _module.id
                                        };
                                    })}
                                    placeholder={"Search topics here..."}
                                    openOnFocus={false}
                                    autoload={false}
                                />
                            </div>
                           
                        </div>
                        {this.props.model.modules.length > 0 && model.categories}
                        {this.props.model.modules.length == 0 && <Loader />}
                    </div>

                </div>
            </div>
        </div>);
    };
}



function mapStateToProps(state) {
    return {
        model: state.store.module
    };
}

export default connect(mapStateToProps)(LearningPage);