import * as actions from "../../actiontypes/posttests";
import APIServices from "../../services";
import { processResponse } from "../../actions";
import { NotificationManager } from 'react-notifications';

export const getPostTestsByModule = (id) => {
  return dispatch => APIServices.getPostTestsByModule(id)
    .then(response => response.json())
    .then(response => {
      dispatch(posttestReceived(response));
    })
    .catch(err => {
      console.log(err);
    })
};

export const posttestReceived = (response) => {
  return {
    type: actions.POSTTEST,
    payload: {
      response: response
    }
  };
};

export const addPosttest = (model) => {
  console.log(model);
  return dispatch => APIServices.addPosttest(model)
    .then(response => response.json())
    .then(data => {
      NotificationManager.success('Test Question added Successfully.', 'Success');
    })
    .catch(err => {
           
    })
};

export const updatePosttest = (model) => {
    
  return dispatch => APIServices.updatePosttest(model)
    .then(response => response.json())
    .then(data => {
      NotificationManager.success('Test Question updated Successfully.', 'Success');
    })
    .catch(err => {
            
    })
};

export const deletePosttest = (model) => {
  console.log(model);
  return dispatch => APIServices.deletePosttest(model)
    .then(response => response.json())
    .then(data => {
      NotificationManager.success('Test Question deleted Successfully.', 'Success');
      window.location.reload();
    })
    .catch(err => {
    });
};

export const getPosttest = (model) => {
   
  return dispatch => APIServices.getPosttest(model)
    .then(processResponse)
    .then(data => {           
      dispatch(getPosttestReceived(data))
    })
    .catch(err => {
    });
};

export const getPosttestStart = () => {
  return {
    type: actions.POSTTEST_GET_START
  };
};

export const getPosttestReceived = (response) => {
  return {
    type: actions. POSTTEST_GET,
    payload: {
      response: response
    }
  };
};

export const addPosttestFeedBack = (model) => {
  console.log(model);
  return dispatch => APIServices.addPosttestFeedBack(model)
    .then(response => response.json())
    .then(data => {
      NotificationManager.success('Post Test Saved Successfully.', 'Success');

    })
    .catch(err => {
    });
};


export const getPostTestReport = () => {
  return dispatch => APIServices.getPostTestReport()
    .then(response => response.json())
    .then(response => {
      dispatch(posttestReportReceived(response));
    })
    .catch(err => {
      console.log(err);
    })
};

export const posttestReportReceived = (response) => {
  return {
    type: actions.POSTTEST_REPORT,
    payload: {
      response: response
    }
  };
};
