import {combineReducers} from "redux";
import * as actions from "../actiontypes/user";

const initialState = {
    initDone : false,
    isFetching: false,
    hasError: false,
    error : "",
    user : {},
    users :[],
    recover :[]
};

export default function Auth(state = initialState, action) {
    switch (action.type) {

        case actions.USER_INFO_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case actions.USER_INFO_ERROR:
        
            return {
                ...state,
                initDone: true,
                isFetching: false,
                isAuthenticated: false,
                hasError : true,
                error : action.error
            };
        case actions.USER_INFO_SUCCESS:
         
            return {
                ...state,
                initDone: true,
                isFetching: false,
                hasError : false,
                error : "",
                user : action.data
            };

        case actions.USER_LOGOUT:

            return {
                ...state,
                initDone: false,
                isFetching: false,
                hasError: false,
                error: "",
                user: {},
                users :[]
            };

        case actions.USERS:
            return {
                ...state,
                initDone: true,
                users : action.payload.response
            };

        case actions.USER_RECOVERY_PASSWORD:
            return {
                ...state,
                recover : action.payload.response
            };

        case actions.USER_CHANGE_PASSWORD:
            return {
                ...state,
                user: {
                    ...state.user,
                    resetPasswordRequired: false,
                    changePasswordRequired: false
                }
            };
        case actions.USER_OPEN_CHANGE_PASSWORD:
                return {
                    ...state,
                    user: {
                        ...state.user,
                        changePasswordRequired: true
                    }
                };
        case actions.USER_CLOSE_CHANGE_PASSWORD:
            return {
                ...state,
                user: {
                    ...state.user,
                    changePasswordRequired: false
                }
            };

        default:
            return state;
    }
};