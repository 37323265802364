import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { validateResetPasswordCode, resetPassword } from './../../actions/user';
import { Link, URLSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import LoaderFull from './../components/loader-full';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isCodeValid: false,
      password: '',
      confirmPassword: ''
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    let params = queryString.parse(this.props.location.search)
    validateResetPasswordCode(params.code.replaceAll(" ", "+")).then((response) => {
      this.setState({
        ...this.state,
        isLoaded: true,
        isCodeValid: response,
      });
      this.forceUpdate();
    });
  }

  componentDidMount() {
  }

  onInputChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value
    }, () => {
      // callbacks
    });
  }

  onSaveButtonClick() {
    let params = queryString.parse(this.props.location.search)
    let model = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        email: "reset@resetpassd.com",
        code: params.code,
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
      }
    };
    this.props.dispatch(resetPassword(model, this.props.history));
  }
  render() {

    console.log(this.state.isCodeValid);

    if (this.state.isLoaded) {
      return (
        <div className="login-page recovery">
          <div className="container d-flex align-items-center">
            <div className="form-holder has-shadow">
              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex align-items-center justify-content-center login-logo-box">
                    <div className="content">
                      <div className="logo">
                        <a href="/" >
                          <h1 className="h1 text-white">
                            <strong>
                              <img src={require('./../../assets/images/logo-new.png')} className="img-responsive login-image" /></strong>
                          </h1>
                        </a>
                      </div>
                      <div className="text-info login-logo-text">
                        The on-line learning center for mental health professionals.</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 bg-white">
                  <div className="form d-flex align-items-center justify-content-center">
                    <div className="content">
                      {this.state.isCodeValid &&
                        <div id="login-form" method="post">
                          <div className="form-group">
                            <label className="label-material">Set New Password</label>
                            <input id="password" type="password"
                              value={this.state.password}
                              onChange={this.onInputChange}
                              name="password" required="" className="form-control" />
                            <small className="form-text text-muted">At least 8 characters long, 1 Uppercase, 1 Number, 1 special character</small>
                           </div>
                          <div className="form-group">
                            <label className="label-material">Confirm Password</label>
                            <input id="confirmPassword" type="password"
                              value={this.state.confirmPassword}
                              onChange={this.onInputChange}
                              name="confirmPassword" required="" className="form-control" />
                          </div>

                          <button id="login" onClick={this.onSaveButtonClick} className="btn btn-primary btn-block">
                            Change Password
                          </button>
                        </div>
                      }
                      {!this.state.isCodeValid &&
                        <div className="form-group">
                          <label className="label-material">Sorry !! Reset link is invalid! </label>
                          <Link className="btn btn-primary btn-block" to={`/login`}>  Back to Login </Link>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <LoaderFull />
      )
    }

  }
}
function mapStateToProps(state) {
  return {
    user: state.store.user
  };
}

export default withRouter(connect(mapStateToProps)(ResetPassword));