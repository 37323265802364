export function processResponse(response) {
    return new Promise((resolve, reject) => {
      // will resolve or reject depending on status, will pass both "status" and "data" in either case
      let status =  response.status;
      let func;
      status < 400 ? func = resolve : func = reject;

      if(status == 401){
        reject({'status': response.status, 'data': "User is UnAuthorized"});
      }else{
        response.json().then(data => func({'status': response.status, 'data': data}));
      }
    });
  }