import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { addVideoPoster } from '../../../actions/video';
import { NotificationManager } from 'react-notifications';
import Loader from '../loader';
import { withRouter } from 'react-router-dom';

class VideoPoster extends React.Component {

  constructor(props) {
    super(props); 
    this.state = {
      poster: null
    };
    this.changeHandlerPoster = this.changeHandlerPoster.bind(this);

  }
  toggle(bool, e) {
    e.stopPropagation();

    if(!bool){
      var data = [];
      this.props.toggle(bool, data);
    } else {
      if (!this.state.poster) {
        NotificationManager.error('Please add poster...', 'Error');
        return false;
      }
      var data = new FormData();
      data.append('poster', this.state.poster);
      data.append('id', this.props.modelId);
  
      let model = {
        headers: {
        },
        data: data
      };
      this.props.dispatch(addVideoPoster(model, this.props.history));
      this.setState({
        isLoader: true
      });
      this.props.toggle(bool, data);
    }
  }

  changeHandlerPoster = (event) => {
    this.setState({ poster: event.target.files[0] });
	};

  render() {
    let { isOpen } = this.props;
          
    return (
      <div>
        <Modal isOpen={isOpen} toggle={this.toggle.bind(this, false)} className={this.props.className}>
          <ModalHeader toggle={this.toggle.bind(this, false)}> {this.props.modelTitle} </ModalHeader>
          <ModalBody>
                <div className="form-group">
                  <label> Poster*</label>
                  <input type="file" name="poster" onChange={this.changeHandlerPoster} className="form-control" id="poster" placeholder="Poster" />
                </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle.bind(this, true)}>Save</Button>{' '}
            <Button color="secondary" onClick={this.toggle.bind(this, false)}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
                
    );
  }
}

function mapStateToProps(state) {
  return {
    model: state.store.customer
  };
}
export default withRouter(connect(mapStateToProps)(VideoPoster));
