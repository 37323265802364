import {combineReducers} from "redux";
import * as actions from "../actiontypes/segments";

const initialState = {
    isFetching: false,
    name: '',
    addSegmentImage : "",
    addSegmentImageUploading : false,
    segment : null,
    segments :[]
};

export default function Segment(state = initialState, action) {
    switch (action.type) {
        case actions.SEGMENTS:
            return {
                ...state,
                segments: action.payload.response
            };
        case actions.UPLOADING_IMAGE_RESET:
            return {
                ...state, 
                addSegmentImage : "",
                addSegmentImageUploading : false
        };
        case actions.UPLOADING_IMAGE_START:
            return {
                ...state,
                addSegmentImageUploading : true
            };
        case actions.UPLOADING_IMAGE_DONE:
            return {
                ...state,
                addSegmentImage : window.location.origin + action.payload.response.url,
                addSegmentImageUploading : false
            };
        case actions.SEGMENTS_GET:
            return {
                ...state,
                segment : action.payload.response.data
            };
        case actions.SEGMENTS_GET_START:
            return {
                ...state,
                segment : null
            };
        default:
            return state;
    }
};