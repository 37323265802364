export const SEGMENTS = 'SEGMENTS/ALL';
export const SEGMENTS_GET = 'SEGMENTS/GET';
export const SEGMENTS_GET_START =  'SEGMENTS/GET_START';
export const SEGMENTS_GET_DONE = 'SEGMENTS/GET_DONE';
export const UPLOADING_IMAGE_RESET =  'APPROACHES/UPLOADING_IMAGE_RESET';
export const UPLOADING_IMAGE_START = 'APPROACHES/UPLOADING_IMAGE_START';
export const UPLOADING_IMAGE_DONE = 'APPROACHES/UPLOADING_IMAGE_DONE';




    