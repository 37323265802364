import React from 'react';
import Slider from 'react-slick';
import '../dashboard/dashboard.css';

export default class DashboardCarousal extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      // news : [
      //   {
      //     image : 'assets/images/strat.jpg',
      //     publishsedDate : 'Aug 4, 2017',
      //     publishsedBy : 'Jeff Magnavita',
      //     commentsCount : 23,
      //     articleName : 'PsychTrends',
      //     shortDescription : 'Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin.',
      //     paragraphs : [
      //       "Clinicians can augment treatment of a range of issues by teaching patients how to reduce anxiety and stress. Heart Rate Variability Biofeedback (HRV) is a well established and effective strategy that patients can use at home to learn how to experience a state of coherence. Coherence is an optimal balance between sympathetic and parasympathetic parts of the nervous system. For more information visit: <a href='https://www.heartmath.com/'>https://www.heartmath.com/</a>."
      //     ]
      //   }
      // ]
    };
  }
  // componentDidMount() {
  //   fetch("https://blog.apps.secureserver.net/v1/website/ced43d43-c9b6-4b53-986b-755b9ba0c022/feed")
  //     .then(res => res.json())
  //     .then(
  //       (result) => {
  //         this.setState({
  //           isLoaded: true,
  //           feed: result.feed
  //         });
  //       },
  //       (error) => {
  //         this.setState({
  //           isLoaded: true,
  //           error
  //         });
  //       }
  //     );
  // }
  formatDate(string){
    let options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(string).toLocaleDateString([],options);
  }

  render() {
    let settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll:1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: true,
    };

    let model = {};
    model.rows = [];
    model.indicators = [];
    const { feed } = this.props;
    let post = null;
    if (feed !== 0)
    {
      post = feed.slice(0,5);
    }
    { post !== null && post.forEach((item,i) => {

      let _image = item.image !== '' ? 
        <figure className="figure float-left pr-3 wm-100 rounded">
          <img src={item.featuredImage} className="figure-img rounded dashboard-image" alt="" />
          <figcaption className="figure-caption text-center">
            <p className="card-text">
              <small className="text-muted">{this.formatDate(item.date)}</small>
              <small className="text-muted">By PsychTrends <sup>&reg;</sup></small>
            </p>
          </figcaption>
        </figure>
        : "";

      model.rows.push( 
        <div className="card" key={i} >
          {/*{ _image }*/}
          <div className="card-body text-dark">
            <div className="row">
              <div className="col-lg-6">{ _image }</div>
              {/* <p className="card-text">
                                <small className="text-muted">{item.publishsedDate}</small>
                                <small className="text-muted">By {item.publishsedBy}</small>
                                <small className="text-muted"> <span className="badge badge-secondary">{item.commentsCount}</span> Comments</small>
                        </p> */}
              {/* <div className="justify-content-start">
                        </div> */}
              <div className="col-lg-6">
                <span className="card-title h3 d-inline-block">{item.title}</span>
                <p className="card-text">{item.content} <a target="_blank" href={'https://psychtrends.godaddysites.com/f/' + item.slug}>More Info</a></p>
              </div>
            </div>
          </div>
        </div>
      );
      
      model.indicators.push( 
        <li data-target="#carouselExampleControls" data-slide-to={i} key={i} className={i === 0  ? "active" : ""} />);
    });
    }
        
    return (
      <Slider {...settings}>
        { model.rows }
      </Slider>
    );
  }
}