import React from 'react';
import ReactDom from 'react-dom';

export default class SignUp extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        /*let user=[
            {
                id:1,
                name:"tushar",
                city:"bhildi",
                post:"manager"
            },
            {
                 id:2,
                name:"ravi",
                city:"Deesa",
                post:"employee"
            },
        ];

       let model={};
       model.usera=[];
       user.forEach(function(users,i){
          model.usera.push(
            <div>
                  <div className="row py-4">
                <div className="col-sm-4 d-flex justify-content-center">
                 <div className="card shadow ">
                                <img className="card-img-top" src="/assets/images/girl.jpg"  alt="Card image"/>
                                <div className="card-body">
                                    <h4 className="card-title"><b>Name</b>:{users.name}</h4>
                                    <p className="card-text"><b>City</b>:{users.city}</p>
                                     <p className="card-text"><b>Post</b>:{users.post}</p>
                                     <a href="#" className="btn btn-primary">View Full Information</a>
                                </div>
                            </div>
                      </div>
                    </div>
               </div>
          );
       });
        return (
            <div>
                <div className="container">
                  
                        {model.usera}
                    
                </div>
             
         </div>
        );*/
        return (
            <div className="container bg-white">
                <form className="container" id="needs-validation" novalidate>
                    <div>
                        <div className="col-lg-12 d-flex justify-content-center">
                            <div className="m-portlet">
                                <div className="m-portlet__head">
                                    <div className="m-portlet__head-caption">
                                        <div className="m-portlet__head-title">

                                            <div className="m-portlet__head-text py-5 d-flex justify-content-center h2 ">
                                                SignUp
												</div>
                                        </div>
                                    </div>
                                </div>

                                <div className="m-form m-form--fit m-form--label-align-right m-form--group-seperator-dashed">
                                    <div className="m-portlet__body ">
                                        <div className="form-group m-form__group row ">
                                            <div className="col-lg-12">
                                                <div className="">
                                                    <div className="form-group">
                                                        <label for="inputPassword6">User Name:</label>
                                                        <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline" />
                                                        <small id="passwordHelpInline" className="text-muted">
                                                            Pease Enter User Name
                                                         </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label for="inputPassword6">Email:</label>
                                                    <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline" />
                                                    <small id="passwordHelpInline" className="text-muted">
                                                        Pease Enter Email Id
                                                         </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <div className="col-lg-12">
                                                <div className="">
                                                    <div className="form-group">
                                                        <label for="inputPassword6">First Name:</label>
                                                        <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline" />
                                                        <small id="passwordHelpInline" className="text-muted">
                                                            Pease Enter Your First Name
                                                         </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">

                                                <div className="form-group">
                                                    <label for="inputPassword6">Last Name:</label>
                                                    <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline" />
                                                    <small id="passwordHelpInline" className="text-muted">
                                                        Pease Enter Your Last Name
                                                         </small>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label for="inputPassword6">Password</label>
                                                    <input type="password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline" />
                                                    <small id="passwordHelpInline" className="text-muted">
                                                        Must be 8-20 characters long.
                                                          </small>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                    <div className="m-portlet__foot m-portlet__no-border m-portlet__foot--fit d-flex justify-content-center ">

                                        <div className="row py-3">
                                            <div className="col-lg-12">
                                                <span className="px-2">
                                                    <button type="Submit" className="btn btn-dark">
                                                        SignUp
														</button>

                                                </span>
                                                <span className="px-2">
                                                    <button type="reset" className="btn btn-dark">
                                                        Cancel
														</button>
                                                </span>


                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}