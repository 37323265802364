import {combineReducers} from "redux";
import * as authActions from "../actiontypes/auth";
import * as actions from "../actiontypes/user";
import { fail } from "assert";

const initialState = {
    isFetching: false,
    isAuthenticated: false,
    hasError: false,
    token : {},
    error : "",
    user : {}
};

export default function Auth(state = initialState, action) {
    switch (action.type) {
        case authActions.AUTH_LOGIN_REQUEST:
        localStorage.removeItem("token");
        localStorage.removeItem("IsAuthenticated");
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
                hasError : false,
                error : ""            
            };
        case authActions.AUTH_LOGIN_ERROR:
        localStorage.removeItem("token");
        localStorage.removeItem("IsAuthenticated");
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                hasError : true,
                error : action.error
            };
        case authActions.AUTH_LOGIN_SUCCESS:
			localStorage.setItem("token", JSON.stringify(action.token));
			var expiry = new Date();
			expiry.setSeconds(expiry.getSeconds() + action.token.expires_in);
			localStorage.setItem("expiry", expiry);
            localStorage.setItem("IsAuthenticated", true);
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                hasError : false,
                token: action.token,
                error : ""            
            };

            case authActions.AUTH_LOGOUT:
            localStorage.removeItem("token");
            localStorage.removeItem("IsAuthenticated");
                return {
                    ...state,
                    isFetching: false,
                    isAuthenticated: false,
                    hasError : false,
                    token: {},
                    error : ""            
                };
    

        case actions.USER_INFO_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case actions.USER_INFO_ERROR:
        
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                hasError : true,
                error : action.error
            };
        case actions.USER_INFO_SUCCESS:
        
            return {
                ...state,
                isFetching: false,
                hasError : false,
                error : "",
                user : action.data
            };
        default:
            return state;
    }
};