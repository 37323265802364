import {combineReducers} from "redux";
import * as actions from "../actiontypes/posttests";

const initialState = {
    isFetching: false,
    question: '',
    option: [],
    posttest : null,
    postTests : [],
    report: []
};

export default function PostTest(state = initialState, action) {
    switch (action.type) {
        case actions.POSTTEST:
            return {
                ...state,
                postTests: action.payload.response
            };
        case actions. POSTTEST_GET:
            return {
                ...state,
                postTest : action.payload.response.data
            };
        case actions.POSTTEST_GET_START:
            return {
                ...state,
                postTest : null
            };  
        case actions.POSTTEST_REPORT:
            return {
                ...state,
                report: action.payload.response
            };
        default:
            return state;
    }
};