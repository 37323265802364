import React from 'react';
// {this.state.seminars.length > 0 &&
//   this.state.seminars.map((seminar, k) => {
//     return (<div key={k} className="seminar-info">
//       <div className="avatar">
//         <img src={require("../../assets/images/webinar_icon.png")} alt="webinar-image" className="img-fluid" onClick={() => window.open(getValidUrl(seminar.seminar.url), "_blank")} />
//       </div>
//       <div className="info">
//         <p className="seminarName" onClick={() => window.open(getValidUrl(seminar.seminar.url), "_blank")}>{seminar.seminar.title}</p>
//         <span className="seminar-detail font-italic">Presenter: {seminar.seminar.author}</span>
//         <span className="seminar-detail">${seminar.seminar.price}</span>
//         <span className="seminar-detail">Use Coupon Code: <strong>{seminar.seminar.seminarCompany.couponCode}</strong></span>
//       </div>
//       <p className="seminar-detail ml-3">{seminar.seminar.description}</p>
//     </div>);
//   })
// }
const Modalities = () => {
  return (
  <div className="rbt-categories-area bg-color-extra2 rbt-section-gap">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title text-center mb--60">
            <h2 className="title">Modalities</h2>
          </div>
        </div>
      </div>
      <div className="row g-5">
        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
          <div className="rbt-cat-box rbt-cat-box-1 variation-5 text-start">
            <div className="inner">
              <div className="thumbnail">
                <a href="/learning">
                  <img src={require("./images/img1.jpg")} alt="Category Images" />
                </a>
              </div>
              <div className="content">
                <h5 className="title">
                  <a href="/learning">Web Design</a>
                </h5>
                <div className="read-more-btn">
                  <a href="/learning">
                    <i className="feather-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
          <div className="rbt-cat-box rbt-cat-box-1 variation-5 text-start">
            <div className="inner">
              <div className="thumbnail">
                <a href="/learning">
                  <img src={require("./images/img2.jpg")} alt="Category Images" />
                </a>
              </div>
              <div className="content">
                <h5 className="title">
                  <a href="/learning">Graphic Design</a>
                </h5>
                <div className="read-more-btn">
                  <a href="/learning">
                    <i className="feather-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
          <div className="rbt-cat-box rbt-cat-box-1 variation-5 text-start">
            <div className="inner">
              <div className="thumbnail">
                <a href="/learning">
                  <img src={require("./images/img3.jpg")} alt="Category Images" />
                </a>
              </div>
              <div className="content">
                <h5 className="title">
                  <a href="/learning">Personal Development</a>
                </h5>
                <div className="read-more-btn">
                  <a href="/learning">
                    <i className="feather-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
          <div className="rbt-cat-box rbt-cat-box-1 variation-5 text-start">
            <div className="inner">
              <div className="thumbnail">
                <a href="/learning">
                  <img src={require("./images/img4.jpg")} alt="Category Images" />
                </a>
              </div>
              <div className="content">
                <h5 className="title">
                  <a href="/learning">IT and Software</a>
                </h5>
                <div className="read-more-btn">
                  <a href="/learning">
                    <i className="feather-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};
export default Modalities;