import {combineReducers} from "redux";
import * as actions from "../actiontypes/seminars";

const initialState = {
    isFetching: false,
    firstName: '',
    lastName: '',
    seminars: null,
    seminar : null,
    seminarsWithDetails: null,
    seminarCompany: null,
    seminarCompanies: null
};

export default function seminars(state = initialState, action) {
    switch (action.type) {
        case actions.SEMINARCOMPANIES:
            return {
                ...state,
                seminarCompanies: action.payload.response
            };
        case actions.SEMINARS:
            return {
                ...state,
                seminars: action.payload.response
            };
        default:
            return state;
    }
};