import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import AdminWidgets from "./adminWidgets";
import $ from 'jquery'; 
import SideBar from './sidebar';
import Header from './header';
import Approach from './approach';
import Module from './module';
import Segment from './segment';
import Techniques from './technique';
import SeminarCompanies from './seminarCompany';
import Seminars from './seminar';
import Customer from './customer';
import User from './user';
import Video from './video';
import Report from './report';
import LoaderFull from './../components/loader-full';
import NoAccess from './../components/no-access';
import './index.scss';
import { getUserInfo } from "./../../actions/user";

class AdminDashboard extends React.Component {


    UNSAFE_componentWillMount() {
        this.props.dispatch(getUserInfo());
    }

    render() {
        $(".alert").alert();

        if (this.props.user.initDone) {

            if (this.props.user.user.role == "user") {
                return (
                    <NoAccess />
                )
            } else {
                return (
                    <div>

                        <Header />
                        <div className="d-flex align-items-stretch">
                            <SideBar user={this.props.user} />
                            <div className="page-content p-4">
                                <Switch>
                                    <Route exact path="/admin/" component={AdminWidgets} />
                                    {this.props.user.user.role == "superadmin" &&
                                        <Switch>
                                            <Route path="/admin/approaches" render={routeProps => <Approach {...routeProps} />} ></Route>
                                            <Route path="/admin/modules" render={routeProps => <Module {...routeProps} />} ></Route>
                                            <Route path="/admin/techniques" render={routeProps => <Techniques {...routeProps} />} ></Route>
                                            <Route path="/admin/companies" render={routeProps => <SeminarCompanies {...routeProps} />} ></Route>
                                            <Route path="/admin/seminars" render={routeProps => <Seminars {...routeProps} />} ></Route>
                                            <Route path="/admin/segments" render={routeProps => <Segment {...routeProps} />} ></Route>
                                            <Route path="/admin/customer" render={routeProps => <Customer {...routeProps} />} ></Route>
                                            <Route path="/admin/users" render={routeProps => <User {...routeProps} />} ></Route>
                                            <Route path="/admin/videos" render={routeProps => <Video {...routeProps} />} ></Route>
                                            <Route path="/admin/reports" render={routeProps => <Report {...routeProps} />} ></Route>
                                        </Switch>
                                    }
                                    <Route path="/admin/*" component={NoAccess} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                );
            }

           
        } else {
            return (
                <LoaderFull />    
            )
        }
       
    }
}

function mapStateToProps(state) {
    return {
        auth: state.store,
        user: state.store.user
    };
}

export default connect(mapStateToProps)(AdminDashboard);
