import {combineReducers} from "redux";
import * as actions from "../actiontypes/approaches";

const initialState = {
  isFetching: false,
  firstName: '',
  lastName: '',
  approaches: null,
  approachesWithDetails: null,
  addApproachImage : "",
  addApproachImageUploading : false,
  approach : null,
  feedData : "",
  searchNames : null
};

export default function Approach(state = initialState, action) {
  switch (action.type) {
  case actions.APPROACHES:
    return {
      ...state,
      approaches: action.payload.response
    };
  case actions.FEEDDATA:
    return {
      ...state,
      feedData: action.payload.response
    };
  case actions.MODULES_TECHNIQUES_APPROACHES_NAMES:
    return {
      ...state,
      searchNames: action.payload.response
    };
  case actions.UPLOADING_IMAGE_RESET:
    return {
      ...state,
      addApproachImage : "",
      addApproachImageUploading : false
    };
  case actions.UPLOADING_IMAGE_START:
    return {
      ...state,
      addApproachImageUploading : true
    };
  case actions.UPLOADING_IMAGE_DONE:
    return {
      ...state,
      addApproachImage : window.location.origin + action.payload.response.url,
      addApproachImageUploading : false
    };
  case actions.APPROACHES_GET:
    return {
      ...state,
      approach : action.payload.response.data
    };
  case actions.APPROACHES_GET_START:
    return {
      ...state,
      approach : null
    };
  case actions.APPROACHES_WITH_DETAILS:
    return {
      ...state,
      approachesWithDetails: action.payload.response.data
    };
  default:
    return state;
  }
}