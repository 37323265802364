import React from 'react';

import './modalities 2.scss';

const Modalities = () => {
  return (
  <div className="rbt-categories-area rbt-section-gap">
    <div className="m-5">      
      <div className="row g-5">
        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
          <a href="/module/45">
            <div className="rbt-cat-box rbt-cat-box-1 variation-5 text-start">
              <div className="inner">
                <div className="thumbnail">
                    <img className="custom-modalities-img" src={require("./images/img1.jpg")} alt="Category Images" />
                </div>
                <div className="content">
                  <h5 className="title">
                    <span className="title-link" >Group Psychotherapy</span>
                  </h5>
                  <div className="read-more-btn d-flex align-items-center">
                    <span className="title-link" >
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
          <a href="/module/46">          
            <div className="rbt-cat-box rbt-cat-box-1 variation-5 text-start">
              <div className="inner">
                <div className="thumbnail">
                    <img className="custom-modalities-img" src={require("./images/img2.jpg")} alt="Category Images" />
                </div>
                <div className="content">
                  <h5 className="title">
                    <span className="title-link" >Family Psychotherapy</span>
                  </h5>
                  <div className="read-more-btn d-flex align-items-center">
                    <span className="title-link" >
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
          <a href="/module/47">          
            <div className="rbt-cat-box rbt-cat-box-1 variation-5 text-start">
              <div className="inner">
                <div className="thumbnail">
                    <img className="custom-modalities-img" src={require("./images/img3.jpg")} alt="Category Images" />
                </div>
                <div className="content">
                  <h5 className="title">
                    <span className="title-link">Couples Psychotherapy</span>
                  </h5>
                  <div className="read-more-btn d-flex align-items-center">
                    <span className="title-link">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg>                    
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
          <a href="/module/48">          
            <div className="rbt-cat-box rbt-cat-box-1 variation-5 text-start">
              <div className="inner">
                <div className="thumbnail">
                    <img className="custom-modalities-img" src={require("./images/img4.jpg")} alt="Category Images" />
                </div>
                <div className="content">
                  <h5 className="title">
                    <span className="title-link">Individual Psychotherapy</span>
                  </h5>
                  <div className="read-more-btn d-flex align-items-center">
                    <span className="title-link">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  );
};
export default Modalities;