import React from "react";
import { Redirect } from 'react-router-dom';
import { logoutUser } from "./../../actions/user";
import { connect } from 'react-redux';
import Auth from './auth';

class Logout extends React.Component {
    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        localStorage.removeItem('redirect');
        localStorage.removeItem('IsAuthenticated');
        localStorage.removeItem('token');
        Auth.logOut();
        this.props.dispatch(logoutUser());
    }   

    componentDidMount() {
        localStorage.removeItem('redirect');
        localStorage.removeItem('IsAuthenticated');
        localStorage.removeItem('token');
        Auth.logOut();
    }

    render() {
        return <Redirect to="login" />;
    }
}
    
function mapStateToProps(state) {
    return {
       
    };
}

export default connect(mapStateToProps)(Logout);