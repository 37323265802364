import React from 'react';
import { BrowserRouter as Router, Route, Link, hashHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { openChangePassword } from '../../../actions/user';

class HeaderBottom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.initChangePassword = this.initChangePassword.bind(this);
    }

    initChangePassword() {
        this.props.dispatch(openChangePassword());
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark pt-0 pb-0">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <a className="nav-link" href="/">Home</a>
                        </li>
                        <li className="nav-item active">
                            <a className="nav-link dropdown-toggle" href="#" id="dropdown03" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tutorials</a>
                            <div className="dropdown-menu" aria-labelledby="dropdown03">
                                <a className="dropdown-item" href="https://www.youtube.com/watch?v=rzpmjZd0S0k">Psychotherapists</a>
                                <a className="dropdown-item" href="#">Supervisors</a>
                                <a className="dropdown-item" href="#">Trainees</a>
                                <a className="dropdown-item" href="#">Students</a>
                            </div>
                        </li>
                        <li className="nav-item active">
                            <Link to='/approaches' className="nav-link" > Approaches</Link>
                        </li>
                        <li className="nav-item active">
                            <Link to='/techniques'className="nav-link" > Techniques</Link>
                        </li>
                        <li className="nav-item active">
                            <Link to='/learning'className="nav-link" > Modules </Link>
                        </li>
                    </ul>
                    {this.props.user.initDone && <ul className="navbar-nav justify-content-end">
                        <li className="nav-item">
                            {!this.props.user.user.name && <Link className="nav-link" to='/login'> Login</Link>}
                        </li>
                        <li className="nav-item">
                            {!!this.props.user.user.name && <Link className="nav-link" to='/'>  Welcome, {this.props.user.user.name}</Link>}
                        </li>
                        <li className="nav-item">
                            {!!this.props.user.user.name && <Link className="nav-link" to='/' onClick={this.initChangePassword}> Change Password </Link>}
                        </li>
                        <li className="nav-item">
                            {!!this.props.user.user.name &&
                                <Link className="nav-link" to='/logout'> Logout </Link>}
                        </li>
                    </ul>
                    }
                </div>
            </nav>
        );
    }
}
function mapStateToProps(state) {
    return {
      user: state.store.user
    };
  }
    
  export default connect(mapStateToProps)(HeaderBottom);