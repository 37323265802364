import React from 'react';
import Spinner  from 'react-spinkit';
import style from './loader.scss';
import { Number } from 'tcomb';

export default class Loader extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
        isShow : true
    }
  }

  componentDidMount() {
   // this.interval = setTimeout(this.hide.bind(this), this.props.timer);
  }

  componentWillUnmount() {
   // clearInterval(this.interval)
  }

  hide() {
    // this.setState({
    //     isShow : false
    // })
  }

  render() {

    if(this.state.isShow){
        return (
            <div className="spinner-container justify-content-center align-items-center">
                 <div className="spinner-container-box d-flex justify-content-center align-items-center">
                    <Spinner name={this.props.name} 
                    fadeIn={this.props.fadeIn}
                    overrideSpinnerClassName={this.props.overrideSpinnerClassName}
                    className={this.props.className}
                    color={this.props.color}/>
                </div>
            </div>
        )
    }else{
        return (<div></div>);
    }
  };
}
