import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


export default class EditorComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty()
    }

    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }


  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
  };



  render() {
    const { editorState } = this.state;
    return (
      <div>
        <div className="container bg-white">
          <div className="col-md-12 p-4 text-center h2">
            Edit Your Contant
         </div>
          <hr />

          <div className="col-md-6">
            <div className="form-group pl-2">
              <label for="inputPassword6">Name:</label>
              <input type="text" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline" />
              <small id="passwordHelpInline" className="text-muted">
                Please Enter Module Name
                </small>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group pl-2">
              <label for="inputPassword6">Brief Discription:</label>
              <textarea className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
              <small id="passwordHelpInline" className="text-muted">
                Some Discription About Module
                </small>
            </div>
          </div>

          <div className="p-4 col-md-6">

            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={this.onEditorStateChange}
            />
            <textarea className="demo-content no-focus"
              disabled
              value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            />

          </div>
          <div className="p-4 col-md-6 d-flex justify-content-center">
            <div className="border border-light py-3 px-2">
              <div className="form-group pl-2">
                <label for="inputPassword6">Choose Photo:</label>
                <input type="file" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline" />
                <small id="passwordHelpInline" className="text-muted">
                  Upload Photos From Here
                </small>
              </div >
              <picture>
                <source srcset="..." type="image/svg+xml" />
                <div className="d-flex flex-row">
                  <div>
                    <img src="assets/images/learning_20171027_175110.jpg" className="img-fluid img-thumbnail " alt="..." />
                  </div>
                  <div>
                    <img src="assets/images/learning_20171027_175110.jpg" className="img-fluid img-thumbnail" alt="..." />
                  </div>
                  <div>
                    <img src="assets/images/learning_20171027_175110.jpg" className="img-fluid img-thumbnail" alt="..." />
                  </div>
                  <div>
                    <img src="assets/images/learning_20171027_175110.jpg" className="img-fluid img-thumbnail" alt="..." />
                  </div>
                </div>
              </picture>
            </div>
          </div>

          <div className="p-4 col-md-6">
            <div className="border border-light py-3 px-2">

              <div className="form-group pl-2">
                <label for="inputPassword6">Choose Video:</label>
                <input type="file" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline" />
                <small id="passwordHelpInline" className="text-muted">
                  Upload Video From Here
                </small>
              </div>
              <div className="d-flex flex-row">

                <div className="embed-responsive embed-responsive-1by1 px-1 px-2 img-thumbnail">
                  <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/mkWLc0Lx2ps"></iframe>
                </div>
                <div className="embed-responsive embed-responsive-1by1 img-thumbnail">
                  <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/mkWLc0Lx2ps"></iframe>
                </div>
                <div className="embed-responsive embed-responsive-1by1 px-1 img-thumbnail">
                  <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/mkWLc0Lx2ps"></iframe>
                </div>
                <div className="embed-responsive embed-responsive-1by1 img-thumbnail">
                  <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/mkWLc0Lx2ps"></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="row py-3">
            <div className="col-lg-12 d-flex justify-content-center">
              <span className="px-2">
                <button type="Submit" className="btn btn-dark">
                   Save
														</button>
              </span>
              <span className="px-2">
                <button type="reset" className="btn btn-dark">
                  Cancel
														</button>
              </span>


            </div>
          </div>
        </div>
      </div>
    );
  }
}