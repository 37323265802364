import React, { Component, PropTypes } from 'react';
import { BrowserRouter as Router, Route, Link, hashHistory, Switch, Redirect , withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import Header from './../common/header';
import Footer from './../common/footer';
import SideBar from './../sidebar';
import Notfound from './../notfound';
import Dashboard from './../dashboard';
import Module from './../module';
import PostTest from './../post-test';
import SignUp from './../signUp';
import EditorComponent from './../editorComponent';
import LearningPage from './../components/learning-page';
import Approach from './../components/pages/approach';
import Technique from './../components/pages/technique';
import { getUserInfo } from "./../../actions/user";
import TestingPage from './../components/testing-page';
import TestingPage2 from './../components/testing-page copy';
import TestingPage3 from './../components/testing-page 2';

class Home extends Component {

  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
      this.props.dispatch(getUserInfo());
  }

  render() {
        return (
            <div className="container shadow p-0">
                <Header {...this.props}/>
                <Switch>
                    <Route exact path='/' render={routeProps => <Dashboard {...routeProps} />} />
                    <Route path='/sidebar' render={routeProps => <SideBar {...routeProps} />} />
                    <Route exact path='/approaches' component={Approach} />                    
                    <Route path='/approaches/:approachName' component={Approach} />                    
                    <Route exact path='/techniques' component={Technique} />                    
                    <Route path='/techniques/:techniqueName' component={Technique} />                    
                    <Route path='/module/:moduleId' exact render={routeProps => <Module {...routeProps} />} />
                    <Route path='/module/:moduleId/post-test' render={routeProps => <PostTest {...routeProps} />} />
                    <Route path='/signUp' render={routeProps => <SignUp {...routeProps} />} />
                    <Route path='/editorComponent' render={routeProps => <EditorComponent {...routeProps} />} />
                    <Route path='/learning' component={TestingPage3} />
                    <Route path='/testing' component={TestingPage} />
                    <Route path='/learning-new' component={TestingPage2} />
                    <Route path='/learning-new-2' component={TestingPage3} />
                    <Route component={Notfound} />
                </Switch>
                <Footer />
            </div>
        );
    }
}

function requireAuth() {
  return !sessionStorage.jwt;
}

function mapStateToProps(state) {
  return {
  };
}

export default withRouter(connect(mapStateToProps)(Home));
