import React from 'react';
import $ from 'jquery'; 

export default class HeaderModal extends React.Component {

    render() {
        $('#myModal').on('shown.bs.modal', function () {
            $('#myInput').trigger('focus');
        });

        return (
            <div>
                <div className="modal fade bd-example-modal-lg1" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Psychotherapedia <sup>&reg;</sup> </h5>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="py-3 font-weight-bold"> A Compendium of the Techniques of Psychotherapy</div>
                                <div className="py-2 font-weight-bold">  Introduction </div>
                                <div>

                                    It is interesting to note that a definition of what constitutes a <b>technique</b>  of psychotherapy is not mentioned on Wikipedia, as a Google search for psychotherapy technique, which brings one to the definition of psychotherapy. While the word technique is mentioned a concise definition is not offered. This is an important oversight with major implications for science and practice that we provide a remedy.

                                </div>

                                <div className="py-2 font-weight-bold">  Problem </div>
                                <div>
                                    While there may be 400 to 1,000 <b>approaches to psychotherapy</b> (cognitive-behavioral, interpersonal, psychodynamic, existential, humanistic), each having a defined set of techniques, and many sharing techniques, there currently does not exits a compendium of these accessible by clinicians to improve treatment and training. One of the problems is that the terms techniques, methods, and strategies are often used interchangeably. This state of affairs leads to confusion.

                                </div>

                                <div className="py-2 font-weight-bold">  Challenge </div>
                                <div>
                                    In order to advance the field of psychotherapy and clinical science we have compiled a<b> compendium of techniques</b> called<a href="#">  Psychotherapedia<sup>&reg;</sup> </a> organized in a coherent atheoretical framework that while respecting the different approaches allows the user to find these techniques and blend them in the most efficacious manner. This interactive web based product is easily accessible and in the future we believe will be used to create scientifically validated treatment protocols based on the individual needs and clinical considerations of each individual, couple, and family.

                                </div>

                                <div className="py-2 font-weight-bold">  Definitions </div>
                                <div>
                                    <div> As mentioned, there are <i> techniques, methods, strategies,</i> as well as principles of behavioral treatment. </div>
                                    <div className="py-2 pl-4"> <li> <b>Techniques</b> are the smallest units of any type of behavioral and mental health treatment. These units of change specify a certain action on the part of the mental health clinician, such as what Allen Ivey pioneered in the 1960s and which he termed micro-counseling skills. These were the basic techniques of counseling such as reflection of feeling, reflection of content etc. as demonstrated:

                                        <div className="py-1"><b>PT:</b> [welling up of tears]</div>
                                        <div className="py-1"><b>TH:</b> “You seem sad”</div>

                                        This simple interaction is a technique called <b>reflection of feeling.</b> It can be defined and described and demonstrated thru patient-therapist dialogue and then can be modeled as many training programs have done in a variety of formats for decades.</li></div>

                                    <div className="py-2 pl-4">
                                        <li>
                                            <b>Methods</b>
                                            are groups of related techniques that have a certain therapeutic action. For example, the above demonstrated technique reflection of feeling, is one of many used for restructuring emotions called <b>affective restructuring.</b> Groups of techniques can be organized systematically based on the level of the biopsychosocial system in which the technique has its impact.
                                        </li>
                                    </div>
                                    <div className="py-2 pl-4">

                                        <li>
                                            <b>Strategies</b>
                                            represent how a clinician organizes a sequence of techniques in the clinical process. Most contemporary forms of psychotherapy specify how techniques can be offered in a systematic way, usually described in that approaches treatment manual. This allows for researchers to deliver a coherent treatment that follows the tenets of each approach.
                                        </li>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade bd-example-modal-lg2" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">PsychTrends <sup>&reg;</sup></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                PsychTrends <sup>&reg;</sup> explores the intersection of technology, clinical science, and psychotherapeutics. There are a multitude of new developments emerging from research in technology and neuroscience that will be beneficial to clinical work in psychotherapeutics. New theoretical developments and trans-disciplinary collaborations are providing novel frameworks and perspectives for understanding complex phenomena. We offer product reviews, new developments, old technologies with new applications, and technologies that are either experiencing resurgence or where new evidence for efficacy is emerging.
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade bd-example-modal-lg3" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">

                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"> PsychIncubator <sup>&reg;</sup></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                PsychIncubator <sup>&reg;</sup> is a forum that explores new developments in clinical science and psychotherapeutics. All advancements in clinical science and psychotherapeutics have come about because of innovation. Clinicians are all innovators in the sense that, with each patient, it is necessary to compile a unique array of strategies, interventions, and principles to optimize outcome. The process of discovery is what motivates many of us and keeps us open to novel findings. The path that begins with discovering an innovative and promising approach, and ends at an empirical validation of the approach as an effective method, is a long and arduous one. There are many innovators among us who having insight about clinical phenomena and only need to push the envelope in order to develop a novel, more effective or efficient treatment. The experience of clinicians in continual patient encounters is vital to our art and science. Join our experts in learning about some of the innovations and breakthroughs in psychotherapeutics. We will also discuss how to develop and test innovative products and approaches.

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade bd-example-modal-lg4" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">

                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">PsychStrat Dx </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                PsychStrat Dx is a platform for exploring and learning about various aspects of psychodiagnosis and case formulation. Here you can access our team of experts, as well as other clinicians, to discuss issues related to the spectrum of clinical presentations and to receive input from the expert group. While direct consultation on your clinical cases is not permitted due to confidentiality and liability issues, we will discuss considerations and may illustrate examples with published case material.

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade bd-example-modal-lg5" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">

                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"> PsychStrat Tx</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                PsychStrat Tx is our resource for offering treatment strategies for a spectrum of disorders and clinical presentations. Here we offer guides to treatment that are based on unified psychotherapeutics and draw from methodologies from a variety of approaches.
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}