import React from 'react';
import { connect } from 'react-redux';
import DashboardMiddle from './dashbord-middle';
import DashboardBottom from './dashboard-bottom';
import DashboardTop from './dashboard-top';
import DashboardResources from './dashboard-resources';
import Banner from './../components/banner';
import DashboardWelcomeText from './dashboard-welcome-text';
import TermsConditionDialog from '../components/models/terms&condition-dialog';
import ChangePassword from '../components/models/change-password';
import { addAgreementTimeStamp, changePassword } from '../../actions/user';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    document.title = "StratPsych : Home";
    this.toggleTermsModel = this.toggleTermsModel.bind(this);
  }

  toggleTermsModel(bool) {
    const { user } = this.props.user;
    if (bool) {
      this.props.dispatch(addAgreementTimeStamp(user.name));
    }
  }

  render() {
    const { user } = this.props.user;
    let showChangePassword = false;
    let resetPasswordRequired = user.resetPasswordRequired;
    let changePasswordRequired = user.changePasswordRequired;

    if (user.agreementTimeStamp === null) {
      return (
        <div className="bg-white">
          <TermsConditionDialog name="Terms and Condition Dialog" isOpen={user.agreementTimeStamp === null ? true : false} toggle={this.toggleTermsModel} className="modal-dialog modal-lg" />
          <div className="vh-100"></div>
        </div>
      );
    } else {
      return (
        <div className="bg-white">
          <div className="">
            <ChangePassword name="Change Password" changePasswordRequired={changePasswordRequired} resetPasswordRequired={resetPasswordRequired} className="modal-dialog modal-lg" />
          </div>
          <div className="text-dark bg-white pt-3">
            <DashboardTop />
          </div>
          <hr className="hr-color" />
          <Banner />
          <hr className="hr-color" />
          <DashboardResources />
          <DashboardWelcomeText />
          <div>
            <DashboardBottom model={this.props.model} />
          </div>
        </div>
      );
    }
  }
}
function mapStateToProps(state) {
  return {
    auth: state.store,
    user: state.store.user
  };
}

export default connect(mapStateToProps)(Dashboard);