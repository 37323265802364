import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addCustomer, updateCustomer, deleteCustomer } from "./../../../actions/customers";
import { processResponse } from "./../../../actions";
import APIServices from "../../../services";
import * as API from "../../../services/config";
import Loader from './../../components/loader';
import { onInputChange } from './../components/helper';

class ManageCustomer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: -1,
            name: "",
            description: "",
            // active: "",
            address: "",
            email: "",
            phone: "",
            contactPersonName: "",
        }

        this.onInputChange = this.onInputChange.bind(this);
        this.resetCustomer = this.resetCustomer.bind(this);
    }

    UNSAFE_componentWillMount() {

        if (typeof (this.props.match.params.id) != typeof (undefined) && Number(this.props.match.params.id) > 0) {
            APIServices.getCustomer(this.props.match.params.id)
                .then(response => response.json())
                .then(response => {

                    this.setState({
                        ...this.state,
                        name: response.name,
                        description: response.description,
                        id: response.id,
                        // active: response.active,
                        address: response.address,
                        email: response.email,
                        phone: response.phone,
                        contactPersonName: response.contactPersonName,

                    });

                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            this.resetCustomer();
        }
    }


    resetCustomer() {
        this.setState({
            id: 0,
            name: "",
            description: "",
            // active: "",
            address: "",
            email: "",
            phone: "",
            contactPersonName: "",
        });
    }

    onInputChange(event) {
        onInputChange(this, event);
    }


    onSaveButtonClick() {
        let model = {
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                name: this.state.name,
                description: this.state.description,
                // active: this.state.active,
                address: this.state.address,
                email: this.state.email,
                phone: this.state.phone,
                contactPersonName: this.state.contactPersonName,
            }
        }

        if (Number(this.state.id) > 0) {
            model = {
                ...model,
                data: {
                    ...model.data,
                    id: this.state.id
                }
            }
            this.props.dispatch(updateCustomer(model));
        } else {
            this.props.dispatch(addCustomer(model));
            this.resetCustomer();
        }
        this.props.history.push('/admin/customer/customerList');
    }
    render() {

        console.log(this.state);

        if (this.state.id > -1) {
            return (
                <div>
                    <div>
                        <div className="p-4 text-center h2">
                            {(Number(this.state.id) > 0) ? "Edit" : "Add"} Customer
                        </div>
                        <div className="p-4">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label for="name">Name</label>
                                        <input type="text" name="name" value={this.state.name}
                                            className="form-control" id="name"
                                            onChange={this.onInputChange} placeholder="Enter Name" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label for="name">Description</label>
                                        <textarea name="description" value={this.state.description} className="form-control" id="name"
                                            onChange={this.onInputChange} placeholder="Enter Customer Description" rows="8"> </textarea>
                                    </div>
                                </div>
                            </div>
                           
                                {/* <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label for="name">Active</label>
                                            <input type="text" name="active" value={this.state.active}
                                                className="form-control" id="active"
                                                onChange={this.onInputChange} placeholder="Yes or No" />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label for="name">Address</label>
                                            <input type="text" name="address" value={this.state.address}
                                                className="form-control" id="address"
                                                onChange={this.onInputChange} placeholder="Please Enter Address" />
                                        </div>
                                    </div>
                                </div>
                            


                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label for="name">Email</label>
                                        <input type="text" name="email" value={this.state.email} className="form-control" id="email"
                                            onChange={this.onInputChange} placeholder="Enter Email Address" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label for="name">Phone</label>
                                        <input type="text" name="phone" value={this.state.phone} className="form-control" id="phone"
                                            onChange={this.onInputChange} placeholder="Enter Phone Number" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label for="name">Contact Person Name</label>
                                        <input type="text" name="contactPersonName" value={this.state.contactPersonName} className="form-control" id="contactPersonName"
                                            onChange={this.onInputChange} placeholder="Enter Person Contact" />
                                    </div>
                                </div>
                            </div>

                            <div className="btn-group" role="group" aria-label="">
                                <button className="btn btn-dark mr-2" onClick={this.onSaveButtonClick.bind(this)}> Save </button>

                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Loader />
        }
    }
}


function mapStateToProps(state) {
    return {
        model: state.store.customer
    };
}

export default connect(mapStateToProps)(ManageCustomer);