import React from "react";

export default class VideoGrid extends React.Component {
    constructor(props) {
        super(props);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        
    }

    onRemoveClick(index){
        this.props.onVideoRemoveClick(index);
    }


    render() {
        let onRemoveClick = this.onRemoveClick;
        
        return (
            <div className="p-4">
                {typeof(this.props.videos) != typeof(undefined)  && (this.props.videos) != null && this.props.videos.length > 0 &&
                    <div className="shadow p-2">
                        <div className="grid-container">
                            {this.props.videos.map(function name(image, index) {
                            return <div key={index} className="grid-video-box d-inline-block mr-2">
                                    {/* <img src={image.url} className="m-1 shadow rounded grid-image mx-auto" alt="" /> */}
                                    <div class="embed-responsive embed-responsive-16by9">
                                        <iframe class="embed-responsive-item" src={image.url} allowfullscreen></iframe>
                                    </div>
                                    <button className="btn btn-danger btn-block" onClick={() => onRemoveClick(index)}>
                                    Remove
                                </button>
                            </div>
                            })
                            }
                        </div>
                    </div>}
                {(typeof(this.props.videos) == typeof(undefined)  || (this.props.videos) == null ?  true : this.props.videos.length == 0) &&
                    <div className="p-2 text center">
                        No Video Urls Added.
                    </div>}
            </div>
        );
    }
}