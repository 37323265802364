import React from 'react';

export default class SearchPanel extends React.Component {
    render() {
      return (
        <div>
          <div className='input-group'>
            <span className='input-group-btn'>
              <button
                className='btn btn-primary'
                type='button'>
                Search
              </button>
              { this.props.clearBtn }
            </span>
          </div>
          { this.props.searchField }
        </div>
      );
    }
  }
