import React from 'react';
import Spinner from 'react-spinkit';
import style from './loader.scss';

export default class NoAccess extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {

        return (
            <div className="container container-login text-center p-5">
                <br />
                <br />
                <br />
                <h1><span className="fs-xs-4-0 text-danger">You are trying to access unauthorized Page. please contact admin.</span></h1>
            </div>
        )
    };
}
