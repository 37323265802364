import React  from 'react';
import { BrowserRouter as Router, Route, Link, hashHistory, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PostTestReport from './postTestReport';


class Report extends React.Component {
    constructor(props) {
        super(props);
        
      }

    UNSAFE_componentWillMount(){
        
    }

    render() {
     
          return (<div className="report">
                    <Switch>
                            <Route component={PostTestReport} path="/admin/reports/postTest"></Route>
                    </Switch>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state
    };
}

export default connect(mapStateToProps)(Report);