import React, { Component, PropTypes } from 'react';
import { BrowserRouter as Router, Route, Link, hashHistory, Switch } from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { getAllSeminars } from "./../../../actions/seminar";
import SeminarsList  from './seminarsList';
import ManageSeminars from './manageSeminars';
import SeminarsHeader from './seminarsHeader';


class Seminars extends React.Component {
    constructor(props) {
        super(props);
        
      }

    UNSAFE_componentWillMount(){
        this.props.dispatch(getAllSeminars());  
    }

    render() {
          return (<div className="seminar">
                    <Switch>
                            <Route component={SeminarsList} path="/admin/seminars/seminarList"></Route>
                            <Route component={ManageSeminars} path="/admin/seminars/addSeminar"></Route>
                            <Route path="/admin/seminars/EditSeminar/:id" render={routeProps => 
                            <ManageSeminars seminar={this.props.seminar} {...routeProps} />} ></Route>
                            <Route component={SeminarsHeader}/>
                    </Switch>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state,
        seminar: state.store.seminar
    };
}

export default withRouter(connect(mapStateToProps)(Seminars));