import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addSeminarCompany, updateSeminarCompany } from "./../../../actions/seminar";
import APIServices from "../../../services";
import * as API from "../../../services/config";
import Select from 'react-select';
import Loader from './../../components/loader';
import {
  onInputChange,
  onSelectChange
} from './../../adminDashboard/components/helper';
import { NotificationManager } from 'react-notifications';    

class ManageCompanies extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: -1,
      name: "",
      url: "",
      couponCode: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
    };

    this.onInputChange = this.onInputChange.bind(this);

    this.initCompanyInfo = this.initCompanyInfo.bind(this);
    this.resetCompanyInfo = this.resetCompanyInfo.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.initCompanyInfo(this.props.match.params.id);
  }

  initCompanyInfo(companyId) {

    if (typeof (companyId) !== typeof (undefined) && (companyId != (null || ""))) {
      APIServices.getSeminarCompany(companyId)
        .then(response => response.json())
        .then(response => {
          this.setState({
            ...this.state,
            id: companyId,
            name: response.name,
            url: response.url,
            couponCode: response.couponCode,
            contactName: response.contactName,
            contactEmail: response.contactEmail,
            contactPhone: response.contactPhone
          });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.resetCompanyInfo();
    }
  }

  resetCompanyInfo() {
    this.setState({
      id: "",
      name: "",
      url: "",
      couponCode: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
    });
  }

  onInputChange(event) {
    onInputChange(this, event);
  }

    onSaveButtonClick() {

      if (!this.state.name) {
        NotificationManager.error('Please enter Company Name', 'Error');
        return false;
      }
      if (!this.state.url) {
        NotificationManager.error('Please enter URL', 'Error');
        return false;
      }
      if (!this.state.name
        || !this.state.url	) {
          NotificationManager.error('Please enter required fields..', 'Error');
          return false;
        }

      if (this.state.couponCode && !(/^[0-9a-zA-Z]+$/.test(this.state.couponCode))) {
        NotificationManager.error('Please enter valid coupon code..', 'Error');
        return false;
      }

      if (this.state.contactEmail && !(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])/.test(this.state.contactEmail))) {
        NotificationManager.error('Please enter valid email address..', 'Error');
        return false;
      }

      let model = {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          id: "",
          name: this.state.name,
          url: this.state.url,
          couponCode: this.state.couponCode,
          contactName: this.state.contactName,
          contactEmail: this.state.contactEmail,
          contactPhone: this.state.contactPhone
        }
      };

      if (this.state.id != (null || "")) {
        model = {
          ...model,
          data: {
            ...model.data,
            id: this.state.id
          }
        };
        this.props.dispatch(updateSeminarCompany(model));
      } else {
        this.props.dispatch(addSeminarCompany(model));
        this.resetCompanyInfo();
      }
      this.props.history.push('/admin/companies/companyList');
    }

    render() {

      if (this.state.id != -1) {

        return (
          <div>
            <div className="">
              <div className="p-2 text-center h2">
                {this.state.id != (null || "") ? "Edit" : "Add"} Semianr Company
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="p-2">
                    <div className="form-group">
                      <label> Name*</label>
                      <input type="text" name="name" value={this.state.name} className="form-control" id="name" onChange={this.onInputChange} placeholder="Enter Company Name" />
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                <div className="pl-2">
              <div className="form-group">
                <label> URL*</label>
                <input type="text" name="url" value={this.state.url} className="form-control" id="url"
                  onChange={this.onInputChange} placeholder="Enter URL " />
                  </div>
              </div>
              </div>
              <div className="col-md-4">
                  <div className="px-2">
                    <div className="form-group">
                      <label>Coupon Code</label>
                      <input type="text" name="couponCode" value={this.state.couponCode} className="form-control" id="couponCode" onChange={this.onInputChange} placeholder="Enter Coupon Code" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h4>Contact Info</h4>
              </div>
              <div className="row">
                <div className="col-md-6">
              <div className="pl-2 form-group">
                <label> Name</label>
                <input type="text" name="contactName" value={this.state.contactName} className="form-control" id="contactName"
                  onChange={this.onInputChange} placeholder="Enter Person Name " />
                  </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-6">
              <div className="pl-2 form-group">
                <label> Email</label>
                <input type="text" name="contactEmail" value={this.state.contactEmail} className="form-control" id="contactEmail"
                  onChange={this.onInputChange} placeholder="Enter Company Email " />
                  </div>
              </div>
              </div>
              <div className="row">
                <div className="col-md-6">
              <div className="pl-2 form-group">
                <label> Phone </label>
                <input type="text" name="contactPhone" value={this.state.contactPhone} className="form-control" id="contactPhone"
                  onChange={this.onInputChange} placeholder="Enter Company Phone Number " />
                  </div>
              </div>
              </div>
                <div className="p-2">
                <div className="btn-group" role="group" aria-label="">
                  <button className="btn btn-dark mr-2" onClick={this.onSaveButtonClick.bind(this)}> Save </button>
                  <Link to='/' className="btn btn-dark mr-2"> Back to Home</Link>
                </div>
              </div>
            </div>
          </div >
        );
      } else {
        return <Loader />;
      }
    }
}


function mapStateToProps(state) {
  return {
    model: state.store.seminar
  };
}

export default withRouter(connect(mapStateToProps)(ManageCompanies));