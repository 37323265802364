import * as actions from "../../actiontypes/segments";
import APIServices from "../../services";
import { processResponse } from "../../actions";
import {NotificationManager} from 'react-notifications';

export const  getAllSegments = () => {
    return dispatch => APIServices. getAllSegments()
        .then(response => response.json())
        .then(response => {
            dispatch(segmentsReceived(response));
            // if (data.response === "success") {
            //     dispatch(successUser("success"))
            // } else {
            //     dispatch(errorUser("error"))
            // }
        })
        .catch(err => {
            console.log(err);
        })
};

export const segmentsReceived = (response) => {
    return {
        type: actions.SEGMENTS,
        payload: {
            response: response
        }
    };
};


export const addSegment = (model) => {
    console.log(model);
    return dispatch => APIServices.addSegment(model)
        .then(response => response.json())
        .then(data => {
            // if (data.response === "success") {
            //     dispatch(successUser("success"))
            // } else {
            //     dispatch(errorUser("error"))
            // }
            NotificationManager.success('Segment added Successfully.', 'Success');
        })
        .catch(err => {
            // dispatch(errorUser("error"))
        })
};

export const deleteSegment = (model) => {
    console.log(model);
    return dispatch => APIServices.deleteSegment(model)
        .then(response => response.json())
        .then(data => {
            NotificationManager.success('Segment deleted Successfully.', 'Success');
        })
        .catch(err => {
           
        })
};

export const updateSegment = (model) => {
    
    return dispatch => APIServices.updateSegment(model)
        .then(response => response.json())
        .then(data => {
            NotificationManager.success('Segment updated Successfully.', 'Success');
        })
        .catch(err => {
            
        })
};
export const getSegment = (model) => {
   
    return dispatch => APIServices.getSegment(model)
        .then(processResponse)
        .then(data => {
            // if (data.response === "success") {
            dispatch(getSegmentReceived(data))
            // } else {
            //     dispatch(errorUser("error"))
            // }
        })
        .catch(err => {
            // dispatch(errorUser("error"))
        })
};

export const getSegmentStart = () => {
    return {
        type: actions.SEGMENTS_GET_START
    };
};


export const getSegmentReceived = (response) => {
    return {
        type: actions.SEGMENTS_GET,
        payload: {
            response: response
        }
    };
};