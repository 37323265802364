import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import  moment from 'moment';
import { getAllCustomers } from "./../../../actions/customers";
import SearchPanel from "./../components/searchPanel";
import { getAllUsers, deleteUser } from '../../../actions/user/index';
import ConfirmDialog from './../../components/models/confirm-dialog';
import { onToggleDropDown, renderSizePerPageDropDown, renderCustomClearSearch, createCustomExportCSVButton, renderShowsTotal } from './../../adminDashboard/components/helper';

class UserList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteModelShow : false
    };

    this.toggleDelete = this.toggleDelete.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(getAllUsers());
  }

  customerFormatter(cell) {
    return cell.name;
  }

  dateFormatter(cell) {
    const date = new Date(cell);
    return moment(date).format('MM/DD/YYYY hh:mm a');
  }

  cellButton(cell, row, enumObject, rowIndex) {
    return (
      <div>
        <Link to={"/admin/users/editUser/" + cell} className="btn btn-info"> Edit </Link>
        <button className="btn btn-danger" onClick={this.setDelete.bind(this,cell)}> Delete </button>
      </div>
    );
  }

  setDelete(id,$this) {
    let me = this;
    me.setState({
      deleteModelShow: !me.state.deleteModelShow,
      modelId : id
    });
  }
    
  toggleDelete(bool) {
    if(bool){
      this.props.dispatch(deleteUser(this.state.modelId));
    }
    this.setState({
      deleteModelShow: !this.state.deleteModelShow,
      modelId : 0
    });
  }
  render() {

    const options = {
      page: 1,  // which page you want to show as default
      sizePerPageList: [{
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: this.props.model.users.length
      }], // you can change the dropdown list for size per page
      sizePerPage: this.props.model.users.length,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      exportCSVBtn: createCustomExportCSVButton,
      clearSearch: true,
      clearSearchBtn: renderCustomClearSearch,
      searchPanel: (props) => (<SearchPanel {...props} />),
      sizePerPageDropDown: renderSizePerPageDropDown
    };
    
    if (this.props.model.users.length > 0) {
      return (<div>
        <div className="p-3">
          <Link to="/admin" className="btn btn-dark mr-2"> Back to Home</Link>
        </div>
        <div className="p-3">
          <BootstrapTable data={this.props.model.users} exportCSV search striped hover info version="4"
            className="thead-inverse" options={options} pagination>
            <TableHeaderColumn width="5%" isKey dataField="no" dataSort>#</TableHeaderColumn>
            <TableHeaderColumn dataField="userName" dataSort>User Name</TableHeaderColumn>
            <TableHeaderColumn dataField="firstName" dataSort>First Name</TableHeaderColumn>
            <TableHeaderColumn dataField="lastName" dataSort>Last Name</TableHeaderColumn>
            {/* <TableHeaderColumn className="hide" dataField='email' dataSort>Email</TableHeaderColumn> */}
            <TableHeaderColumn dataField="role" dataSort>Role</TableHeaderColumn>
            <TableHeaderColumn dataField="customer" filterFormatted dataFormat={this.customerFormatter} csvFormat={this.customerFormatter} csvHeader="Customer Name" dataSort>Customer</TableHeaderColumn>
            <TableHeaderColumn width="15%"  dataField="dateAdded" dataFormat={this.dateFormatter} csvFormat={this.dateFormatter} csvHeader="Date Created" dataSort>Date Created</TableHeaderColumn>
            <TableHeaderColumn width="15%" dataField="lastLogin" csvHeader="Last Login" dataSort>Last Login</TableHeaderColumn>
            <TableHeaderColumn dataField="id" dataFormat={this.cellButton.bind(this)}
            />
          </BootstrapTable>
        </div>
        <ConfirmDialog name="wow" isOpen={this.state.deleteModelShow} toggle={this.toggleDelete} modelTitle={"Confirm"} modelBody={"Are you sure, you want to delete User?"}/>
      </div>
      );
    } else {
      return (<div>
        <div className="p-3 text-center">
                    Loading ...
        </div>
      </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    model: state.store.user
  };
}

export default connect(mapStateToProps)(UserList);