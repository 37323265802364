import React from 'react';
import DashboardCarousal from './dashboard-carousal';
import { connect } from 'react-redux';
import { getFeedData } from "./../../actions/approaches";

class DashboardTop extends React.Component {
 
  componentDidMount() {
    this.props.dispatch(getFeedData());
  }

  render() {
    const feed = this.props.feedData.feed !== undefined ? this.props.feedData.feed : 0;
    return (
      <div className="d-flex row">
        <div className="col-md-8 col-sm-12 pb-5">
          <DashboardCarousal feed={feed} />
        </div>
        <div className="col-md-4 col-sm-12">
          <div className="text-left bg-white px-3 border border-hr border-top-0 border-right-0 border-bottom-0 pt-3 border-hide-mobile border-mobile-top">
            <div className="FontSize">
              <div className="pb-1"><img src="assets/images/learning_20171027_175110.jpg" className="w-36 wm-100 responsive img-fluid rounded d-block mx-auto"/></div>
              <div className="text-center pb-1">
                {
                  this.props.user.initDone &&
                  <i> You last logged in:  {!!this.props.user.user.name && this.props.user.user.lastLogin} </i>
                }

              </div>
              {/* <div>10/15/2017 – Feelings </div>
                             <span>10/15/2017 – Feelings (Post Test Questionnaire)</span>
                             <div> 10/16/2017 – Cognition</div> */}
            </div>
          </div>
        </div>
      </div >
    );
  }
}


function mapStateToProps(state) {
  const { feedData } = state.store.approach;
  return {
    user: state.store.user,
    feedData
  };
}

export default connect(mapStateToProps)(DashboardTop);