
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux";
import store from "./stores";
import App from './app/';
import SimpleReactLightbox from 'simple-react-lightbox';
import 'bootstrap';
import slick from "./../node_modules/slick-carousel/slick/slick.css"; //eslint-disable-line
import slickTheme from "./../node_modules/slick-carousel/slick/slick-theme.css"; //eslint-disable-line
import reactBootstrapTable from './../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'react-notifications/lib/notifications.css';
// import "~bootstrap/scss/bootstrap";
// import "../node_modules/bootstrap/scss/bootstrap.scss";
import 'react-select/dist/react-select.css';
import "./scss/index.scss"
// import "./assets/theme-assets/scss/style.scss";
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <BrowserRouter basename="/">
        <Provider store={store}>
            <SimpleReactLightbox>
                <App />
            </SimpleReactLightbox>
        </Provider>
    </BrowserRouter>
    , document.getElementById('root'));

serviceWorker.unregister();
