import React from 'react';
import { Link } from 'react-router-dom';
import "./search.scss";
import { slugify } from '../../helper'

export default class HeaderTop extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      suggestions: [],
      searchType: '',
      primaryKeyId: '',
      text: ''
    };
  }

  onTextChanged = (e) => {
    const value = e.target.value;
    const { searchNames } = this.props;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, 'i');
      suggestions = searchNames.length !== 0 ? searchNames.map(a => a.name).sort().filter(v => regex.test(v)) : '';
    } else {
     // suggestions = searchNames.length !== 0 ? searchNames.map(a => a.name).sort() : '';
    }
    this.setState(() => ({suggestions, text: value}));
  }

  onTextBlur = () => {
    // let suggestions = [];
    // this.setState(() => ({suggestions}));
  }

  renderSuggestions () {
    const {suggestions} = this.state;
    const { searchNames } = this.props;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <ul>
        { suggestions.map((item) => 
        <li key={item} data-type={item.type}> 
          {searchNames.find(x => x.name === item).type === "Module" && <Link onClick={this.forceUpdate} className="search-link" to={`/module/${searchNames.find(x => x.name === item).primaryKeyId}`}> 
          {/* {searchNames.find(x => x.name === item).type} */}
          {item} </Link> }
          {searchNames.find(x => x.name === item).type === "Technique" && <Link onClick={this.forceUpdate} className="search-link" to={`/techniques/${slugify(item)}`}> 
          {/* {searchNames.find(x => x.name === item).type} */}
          {item} </Link> }
          {searchNames.find(x => x.name === item).type === "Approach" && <Link onClick={this.forceUpdate} className="search-link" to={`/approaches/${slugify(item)}`}> 
          {/* {searchNames.find(x => x.name === item).type} */}
          {item} </Link> }
        </li>)}
      </ul>
    );
  }

  suggestionSelected (value) {
    const { searchNames } = this.props;

    if (searchNames && searchNames.find(x => x.name === value).type === "Module") {
      this.setState(() => ({
        text: value,
        suggestions: [],
        searchType: 'module',
        primaryKeyId: searchNames.find(x => x.name === value).primaryKeyId,
      }));
    } else if (searchNames && searchNames.find(x => x.name === value).type === "Technique") {

      this.setState(() => ({
        text: value,
        suggestions: [],
        searchType: 'technique',
        primaryKeyId: value
      })); 
    } else if (searchNames && searchNames.find(x => x.name === value).type === "Approach") {

      this.setState(() => ({
        text: value,
        suggestions: [],
        searchType: 'approach',
        primaryKeyId: value
      })); 
    }
  }

  render() {
    const { text } = this.state;

    return (
      <nav className="navbar-public navbar navbar-expand-lg navbar-light bg-white border border-hr border-top-0 border-left-0 border-right-0">
        <a className="navbar-brand" href="/">
          <img src={require("./../../../assets/images/logo-new.png")} alt="logo-new.png" className="img-responsive" />
        </a>
        
        <form className=" ml-auto d-flex mr-1">
          <div className="AutoCompleteText" onBlur={this.onTextBlur} >
            <input onChange={this.onTextChanged} onClick={this.onTextChanged} value={text} className="form-control" type="text" placeholder="Search" aria-label="Search" />
            {this.renderSuggestions()}
            {/* {searchType !== '' && searchType === 'module' &&
              <Redirect to={"/module/" + primaryKeyId} strict/>
            }
            {searchType !== '' && searchType === 'technique' && window.location.pathname !== `/techniques/${slugify(primaryKeyId)}` && 
              <Redirect to= />
            }
            {searchType !== '' && searchType === 'approach' && window.location.pathname !== `/approaches/${slugify(primaryKeyId)}` && 
              <Redirect to={`/approaches/${slugify(primaryKeyId)}`} />
            } */}
          </div>
          <div className="col-lg-2 pt-2">
            <span className="input-group-addon" id="basic-addon1">
              <i className="fa fa-search" />
            </span>
          </div>
        </form>
        <div className="row pt-2">
          <div className="col-lg-12">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
          </div>
        </div>
      </nav>  
    );
  }
}

