import React, { Component } from 'react';
import { withRouter  } from 'react-router-dom';
import { EditorState, ContentState } from 'draft-js';
import { Route, Link, Switch } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { connect } from 'react-redux';
import { addModule, updateModule } from "./../../../actions/modules";
import ImageGrid from "./../components/imageGrid";
import VideoGrid from "./../components/videoGrid";
import { processResponse } from "./../../../actions";
import APIServices from "../../../services";
import * as API from "../../../services/config";
import Select from 'react-select';
import Loader from './../../components/loader';
import {
    getDescription, onInputChange,
    onSelectChange, addVideo,
    onImageRemoveClick, onVideoRemoveClick,
    loadApproaches, loadTechniques
} from './../components/helper';
import ManageSegment from "./../segment/manageSegment";


class ManageModule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            id: -1,
            name: "",
            description: "",
            content: "",
            images: [],
            videos: [],
            type : "",
            approaches: [],
            techniques: [],
            segments: []
        }
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onImageRemoveClick = this.onImageRemoveClick.bind(this);
        this.onVideoRemoveClick = this.onVideoRemoveClick.bind(this);

        this.resetModule = this.resetModule.bind(this);
        this.initModule = this.initModule.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.initModule(this.props.match.params.moduleId);
    }

    onEditorStateChange(editorState) {
        this.setState({
            editorState,
        });
    };

    initModule(moduleId) {
        if (typeof (moduleId) != typeof (undefined) && Number(moduleId) > 0) {
            APIServices.getModule(moduleId)
                .then(processResponse)
                .then(response => {
                    // response.data
                    const contentBlock = htmlToDraft(response.data.module.content);
                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        const editorState = EditorState.createWithContent(contentState);

                        this.setState({
                            ...this.state,
                            editorState,
                            name: response.data.module.name,
                            description: response.data.module.description,
                            id: response.data.module.id,
                            images: response.data.module.images,
                            videos: response.data.module.videos,
                            type: response.data.module.type,
                            approaches: response.data.approaches.map(function name(_approach) {
                                return {
                                    id: _approach.approach.id,
                                    name: _approach.approach.name
                                }
                            }),
                            techniques: response.data.techniques.map(function name(_technique) {
                                return {
                                    id: _technique.technique.id,
                                    name: _technique.technique.name
                                }
                            }),
                            segments: response.data.segments
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            this.resetModule();
        }
    }

    resetModule() {
        this.setState({
            editorState: EditorState.createEmpty(),
            id: 0,
            name: "",
            description: "",
            content: "",
            images: [],
            videos: [],
            type : "",
            approaches: [],
            techniques: []
        });
    }

    onInputChange(event) {
        onInputChange(this, event);
    }

    handleSelectChange = (option) => {
        this.setState({ ...this.state,
            type : option.value
         });
    }

    addVideo(event) {
        addVideo(this, event);
    }

    onSaveButtonClick() {
        let model = {
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                module: {
                    name: this.state.name,
                    description: this.state.description,
                    content: getDescription(this),
                    images: this.state.images,
                    videos: this.state.videos,
                    type: this.state.type
                },
                techniques: this.state.techniques.map(function name(technique) {
                    return {
                        techniqueId: technique.id
                    }
                }),
                approaches: this.state.approaches.map(function name(_approach) {
                    return {
                        approachId: _approach.id
                    }
                })
            }
        }

        if (Number(this.state.id) > 0) {
            model = {
                ...model,
                data: {
                    ...model.data,
                    module : {
                        ...model.data.module,
                        id: this.state.id
                    }
                }
            }
            this.props.dispatch(updateModule(model));
        } else {
            this.props.dispatch(addModule(model));
            this.resetModule();
        }
        this.props.history.push('/admin/modules/moduleList');
    }

    handleChange(e) {
        let model = {
            file: e.target.files[0]
        };
        APIServices.uploadImage(model).then(response => response.json())
            .then(data => {
                let images = this.state.images;
                images.push({ url: API.ROOT + data.url, name: data.fileName, id: 0 });
                this.setState({
                    images: images
                });
            })
            .catch(err => {
                console.log(err);
            })

    }

    onImageRemoveClick(index) {
        onImageRemoveClick(this, index);
    }

    onVideoRemoveClick(index) {
        onVideoRemoveClick(this, index);
    }

    onSelectChange(name, multi, option) {
        onSelectChange(this, name, multi, option);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        //call your api and update state with new props
        //this.initModule(this.props.match.params.moduleId);

        if(this.props.match.params.moduleId != nextProps.match.params.moduleId){
            this.initModule(nextProps.match.params.moduleId);
        }

    }

    render() {
        const { editorState } = this.state;
        let moduleId = this.props.match.params.moduleId;
        
        let segments = [];

        this.state.segments.forEach((_segment, i) => {
            let _segmentLink = "/admin/modules/EditModule/"+ moduleId +"/segment/" + _segment.id ; 
            segments.push(
                <Link key={i} className="list-group-item list-group-item-action " to={_segmentLink}>{_segment.name}</Link>
            )
        });

        if (this.state.id > -1) {
            return (
                <div>
                    <div>
                        <div className="container-fluid px-4 text-center pt-4">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1 className="ml-0 h2 text-info module-title">
                                    <Link to={"/admin/modules/EditModule/"+ moduleId}>
                                     {this.state.name} </Link>
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid p-4">
                            <div className="row">
                                { Number(this.props.match.params.moduleId) > 0 &&
                                <div className="col-md-4 hide-sm">
                                    <div id="list-example" className="list-group">
                                        {segments}
                                        <Link className="list-group-item list-group-item-action " 
                                        to={"/admin/modules/editmodule/"+ moduleId + "/postTestQuestionList"}>
                                            Post Test
                                        </Link>
                                    </div> 
                                </div> }
                                <div className={ (!(Number(this.props.match.params.moduleId) > 0) ? "col-md-12" : "col-md-8") + " border border-hr bg-white"}>
                                    <div data-spy="scroll" data-target="#list-example" data-offset="0">
                                        { Number(this.props.match.params.segmentId) > 0 && <Switch>
                                                <Route path="/admin/modules/EditModule/:moduleId/segment/:segmentId" 
                                                render={routeProps => <ManageSegment {...routeProps} />} ></Route>
                                        </Switch>}
                                        { !(Number(this.props.match.params.segmentId) > 0) 
                                            && 
                                            <div>
                                            {/* <div className="p-4 text-center h2">
                                                {(Number(this.state.id) > 0) ? "Edit" : "Add"} Module
                                            </div> */}
                                            <div className="p-4">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label>Module Header</label>
                                                            <input type="text" name="name" value={this.state.name} className="form-control" id="name" onChange={this.onInputChange} placeholder="Enter Module Header" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Module description</label>
                                                            <textarea name="description" value={this.state.description} className="form-control" id="name"
                                                                onChange={this.onInputChange} placeholder="Enter Module Description" rows="8"> </textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Approaches</label>
                                                                    <Select.Async clearable={false} autoload={false}
                                                                        cache={false} multi={true}
                                                                        value={this.state.approaches}
                                                                        onChange={this.onSelectChange.bind(this, "approaches", true)}
                                                                        valueKey="id" labelKey="name" isLoading={false}
                                                                        loadOptions={loadApproaches} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Techniques</label>
                                                                    <Select.Async clearable={false} autoload={false}
                                                                        cache={false} multi={true}
                                                                        value={this.state.techniques}
                                                                        onChange={this.onSelectChange.bind(this, "techniques", true)}
                                                                        valueKey="id" labelKey="name" isLoading={false}
                                                                        loadOptions={loadTechniques} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label>Type</label>
                                                                    <Select
                                                                        name="type"
                                                                        value={this.state.type}
                                                                        onChange={this.handleSelectChange.bind(this)}
                                                                        options={[
																			{ value: "basic", label: 'Foundational' },
                                                                            { value: 'intermediate', label: 'Intermediate' },
                                                                            { value: 'advanced', label: 'Advanced' },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-4">
                                                <Editor
                                                    editorState={editorState}
                                                    wrapperClassName="demo-wrapper"
                                                    editorClassName="demo-editor bg-white"
                                                    onEditorStateChange={this.onEditorStateChange}
                                                />
                                            </div>
                                            {!this.props.model.addModulesImageUploading &&
                                                <div className="p-4">
                                                    <div className="form-group">
                                                        <label>Upload Image</label>
                                                        <input type="file" name="uploadedFile" value={this.state.uploadedFile} className="form-control" id="uploadedFile" onChange={(e) => {
                                                            this.handleChange(e)
                                                        }} ref={(input) => this.fileinput = input} />
                                                    </div>
                                                </div>
                                            }

                                            <ImageGrid images={this.state.images} onImageRemoveClick={this.onImageRemoveClick} />

                                            <div className="p-4">
                                                <div className="form-group">
                                                    <label>Video</label>
                                                    <input type="text" name="video" value={this.state.video} className="form-control" id="video"
                                                        onChange={this.onInputChange} placeholder="Enter Video Url" />
                                                </div>
                                                <div className="form-group">
                                                    <button className="btn btn-info" onClick={this.addVideo.bind(this)}>
                                                        Add Video
                                            </button>
                                                </div>
                                            </div>

                                            <VideoGrid videos={this.state.videos} onVideoRemoveClick={this.onVideoRemoveClick} />
                                            <div className="p-4">
                                                <div className="btn-group" role="group" aria-label="">
                                                    <button className="btn btn-dark mr-2" onClick={this.onSaveButtonClick.bind(this)}> Save </button>
                                                    <Link to='/' className="btn btn-dark mr-2"> Back to Home</Link>
                                                </div>
                                            </div>
                                        </div>}
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Loader />
        }
    }
}


function mapStateToProps(state) {
    return {
        model: state.store.module
    };
}

export default withRouter(connect(mapStateToProps)(ManageModule));