import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addUser, updateUser } from './../../../actions/user';
import APIServices from '../../../services';
import Select from 'react-select';
import Loader from './../../components/loader';
import {
  onInputChange,
  onSelectChange, loadCustomers
} from './../../adminDashboard/components/helper';
import { NotificationManager } from 'react-notifications';    

class ManageUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: -1,
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      password: '',
      confirmPassword: '',
      customerId: 0,
      customers: [],
      role: '',
      hideOnEdit: true
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.initUser = this.initUser.bind(this);
    this.resetUser = this.resetUser.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.initUser(this.props.match.params.id);
  }

  initUser(userId) {

    if (typeof (userId) !== typeof (undefined) && (userId !== (null || ''))) {
      APIServices.getUser(userId)
        .then(response => response.json())
        .then(response => {
          this.setState({
            ...this.state,
            id: userId,
            firstName: response.firstName,
            lastName: response.lastName,
            password: response.password,
            userName: response.userName,
            email: response.email,
            role: response.role,
            customerId: response.customerId,
            customers: response.customers.filter(x=>x.id == response.customerId).map((elem) => {
              return {
                id: elem.id,
                name: elem.name
              };
            })
          });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.resetUser();
    }
  }

  resetUser() {
    this.setState({
      id: '',
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      password: '',
      confirmPassword: '',
      customerId: 0,
      customers: [],
      role: '',
      hideOnEdit: true
    });
  }

  onInputChange(event) {
    onInputChange(this, event);
  }

  onSelectChange(name, multi, option) {
    onSelectChange(this, name, multi, option);
  }

    handleChange = (role) => {
      this.setState({ role: role.value });
    }

    onSaveButtonClick() {

      if (!this.state.firstName
			|| !this.state.lastName
			|| !this.state.userName
			|| !this.state.email
			|| !this.state.customers
			|| !this.state.customers.length	) {
        NotificationManager.error('Please enter missing fields..', 'Error');
        return false;
      }
      if (this.state.password && this.state.confirmPassword) {
        let skipPassword = false;
        if(this.state.id === '' || (this.state.id != '' && !this.state.hideOnEdit)) {
          skipPassword = true;
        }

        if (!this.state.password) {
          NotificationManager.error('Please enter Password', 'Error');
          return false;
        }
        if ((this.state.password !== this.state.confirmPassword) || !skipPassword) {
          NotificationManager.error('Confirm password is not matching..', 'Error');
          return false;
        }
      }
      if (!(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])/.test(this.state.email))) {
        NotificationManager.error('Please enter valid email address..', 'Error');
        return false;
      }
      let model = {
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          id: '',
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          userName: this.state.userName,
          email: this.state.email,
          password: this.state.password,
          customerId: this.state.customers[0].id,
          role: this.state.role
        }
      };

      if (this.state.id != (null || '')) {
        model = {
          ...model,
          data: {
            ...model.data,
            id: this.state.id
          }
        };
        this.props.dispatch(updateUser(model, this.props.history));
      } else {
        this.props.dispatch(addUser(model, this.props.history));
        this.resetUser();
      }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      //call your api and update state with new props
      //this.initModule(this.props.match.params.moduleId);
      if (this.props.match.params.id != nextProps.match.params.id) {
        this.initUser(nextProps.match.params.id);
      }
    }

    onChangePassword() {
      this.setState({
        hideOnEdit: false
      });
    }

    render() {

      if (this.state.id != -1) {

        let showPassoword = false;
        let roles = [];

        if(this.state.id === '' || (this.state.id != '' && !this.state.hideOnEdit)) {
          showPassoword = true;
        }

        // if(this.state.role === ) {
        //     roles = [
        //         { value: 'admin', label: 'Admin' },
        //         { value: 'user', label: 'User' },
        //         { value: 'super', label: 'SuperAdmin' },
        //     ]
        // }else{
        roles = [
          { value: 'admin', label: 'Admin' },
          { value: 'user', label: 'User' }
        ];
        // }

        return (
          <div>
            <div className="">
              <div className="p-2 text-center h2">
                {this.state.id != (null || '') ? 'Edit' : 'Add'} User
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="p-2">
                    <div className="form-group">
                      <label> First Name*</label>
                      <input type="text" name="firstName" value={this.state.firstName} className="form-control" id="firstName" onChange={this.onInputChange} placeholder="Enter First Name" />
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="form-group">
                      <label> Last Name*</label>
                      <input type="text" name="lastName" value={this.state.lastName} className="form-control" id="lastName" onChange={this.onInputChange} placeholder="Enter Last Name" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="p-2">
                    <div className="form-group">
                      <label>Role*</label>
                      <Select
                        name="role"
                        value={this.state.role}
                        onChange={this.handleChange}
                        options={roles}
                      />
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="form-group">
                      <label>Customers*</label>
                      <Select.Async clearable={false} autoload={false}
                        cache={false} multi
                        value={this.state.customers}
                        onChange={this.onSelectChange.bind(this, 'customers', true)}
                        valueKey="id" labelKey="name" isLoading={false}
                        loadOptions={loadCustomers} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-2">
                <label> Username*</label>
                <input type="text" name="userName" value={this.state.userName} className="form-control" id="userName"
                  onChange={this.onInputChange} placeholder="Enter Username " />
              </div>
              <div className="p-2">
                <label> Email Address*</label>
                <input type="text" name="email" value={this.state.email} className="form-control" id="email"
                  onChange={this.onInputChange} placeholder="Enter Email Address" />
              </div>
              { showPassoword &&  <div className="p-2">
                <label> Password*</label>
                <input type="password" name="password" value={this.state.password} className="form-control" id="password"
                  onChange={this.onInputChange} placeholder="Enter Password " />
              </div>
              }
              { showPassoword &&  <div className="p-2">
                <label>Confirm Password*</label>
                <input type="password" name="confirmPassword" value={this.state.confirmPassword} className="form-control" id="confirmPassword"
                  onChange={this.onInputChange} placeholder="Enter Confirm Password " />
              </div>
              }
              <div className="p-2">
                <div className="btn-group" role="group" aria-label="">
                  {!showPassoword &&
                                <button className="btn btn-dark mr-2" onClick={this.onChangePassword.bind(this)}> Change Password </button>
                  }
                  <button className="btn btn-dark mr-2" onClick={this.onSaveButtonClick.bind(this)}> Save </button>
                  <Link to="/admin/users/usersList" className="btn btn-dark mr-2"> Back to User List</Link>
                </div>
              </div>
            </div>
          </div >
        );
      } else {
        return <Loader />;
      }
    }
}


function mapStateToProps(state) {
  return {
    model: state.store.customer
  };
}

export default withRouter(connect(mapStateToProps)(ManageUser));