import React, { useState, useEffect } from "react";

const TextToSpeech = ({ text }) => {
  
  const removeHtmlTags = (inputString) => {
    return inputString.replace(/<[^>]*>/g, '');
  }

  const [isPaused, setIsPaused] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [utterance, setUtterance] = useState(null);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const plainText = removeHtmlTags(text);  
    const u = new SpeechSynthesisUtterance(plainText);

    setUtterance(u);

    return () => {
      synth.cancel();
    };
  }, [text]);

  const handlePlay = () => {
    const synth = window.speechSynthesis;
    synth.cancel();
    console.log(utterance);
    synth.speak(utterance);
    setIsPlaying(true);
    setIsPaused(false);

  };

  const handleResume = () => {
    const synth = window.speechSynthesis;

    if (isPaused) {
      synth.resume();
    } 
    console.log(utterance);
    synth.speak(utterance);

    setIsPaused(false);
  };

  const handlePause = () => {
    const synth = window.speechSynthesis;

    synth.pause();

    setIsPaused(true);
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;

    synth.cancel();

    setIsPaused(false);
    setIsPlaying(false);

};

  return (
    <div className="d-inline shadow-sm mx-3 px-2" style={{ borderRadius: '50px', background: '#efefef'}}>
      {/* <button className="btn btn-dark" onClick={handlePlay}>{isPaused ? "Resume" : "Play"}</button> */}
      <i className="fa fa-audio-description mx-1" aria-hidden="true"></i>

      { !isPlaying && <i className="fa fa-play-circle mx-1" onClick={handlePlay} aria-hidden="true"></i>}
      { isPaused && <i className="fa fa-play-circle mx-1" onClick={handleResume} aria-hidden="true"></i>}
      { isPlaying && !isPaused && <i className="fa fa-pause-circle mx-1" onClick={handlePause} aria-hidden="true"></i>}
      { (isPlaying || isPaused) && <i className="fa fa-stop-circle mx-1" onClick={handleStop} aria-hidden="true"></i>}
    </div>
  );
};

export default TextToSpeech;