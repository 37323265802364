import React from 'react';

export default class DashboardWelcomeText extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="bg-white">
                <div className="h5 p-5 text-bold text-white text-center bg-info" id="welcome-text">
                    <p>
                        Welcome to <strong>StratPsych <sup>&reg;</sup></strong> , the online learning center for mental and behavioral health practitioners. The goal of StratPsych <sup>&reg;</sup> is to help you become the most effective clinician possible, guiding you on a path of continuous improvement to reach your highest performance goals. By maximizing your expertise,technical skills, and knowledge you will increase patient satisfaction and optimize your successful outcome rates.
                    </p>
                </div>

                <div className="container-fluid  text-center text-info bg-white" id="process">
                    <div className="row">
                        <div className="col">
                            {/*<div className="h3 py-3 bg-white">History</div>*/}
                            <div className="text-left bg-white text-dark p-3">

                                <br />
                                <p><strong>The Process</strong><br />Psychotherapeutic proficiency evolves through the mastery of increasingly complex material.<strong> StratPsych <sup>&reg;</sup></strong> consumers begin their training with the basics and proceed through the topic domains common to all psychotherapeutics as dictated by their goals. </p>
                                <p><strong>The Training</strong><br />The <strong> StratPsych <sup>&reg;</sup></strong> team of experts will help you focus on training goals in order to distill what is most useful from a multitude of sources and provide you with the essential information that you need to be effective with the patients you serve. Psychotherapeutic effectiveness begins with basic modules that represent the fundamental domains of all psychotherapeutics and are the building blocks of your knowledge base. Within each domain we provide links to webinars and additional training options. Expert consultation will help guide your training so that you can utilize your time and resources optimally </p>
                                <p><strong>Who Can Benefit?</strong><br />Our consumers include mental and behavioral health practitioners including Licensed Professional Counselors (LPC), Licensed Clinical Social Workers (LSCW), Licensed Marriage and Family Therapists, Advanced Practice Nurses (APRN), Licensed Psychologists, and Psychiatrists. From the domain modules to the advanced level of developing treatment packages for individualized care, <strong> StratPsych <sup>&reg;</sup></strong> will target the training that you seek.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}