import React from 'react';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import { getApproachSearch } from "./../../../actions/approaches";
import { getModuleSearch } from "./../../../actions/modules";
import { getTechniqueSearch } from "./../../../actions/techniques";
import { getCustomerSearch } from "./../../../actions/user";

//CONSTANTS

//Functions 
export const getDescription = (me) => {
    let str = draftToHtml(convertToRaw(me.state.editorState.getCurrentContent()));
    return str;
}

export const convertFirstCharacterCapital = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const toSeoUrl = (url) => {

    // make the url lowercase         
    var encodedUrl = url.toString().toLowerCase();

    // replace & with and           
    encodedUrl = encodedUrl.split(/\&+/).join("-and-")

    // remove invalid characters 
    encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");

    // remove duplicates 
    encodedUrl = encodedUrl.split(/-+/).join("-");

    // trim leading & trailing characters 
    encodedUrl = encodedUrl.trim('-');

    return encodedUrl;
}


//#region search helpers

export const loadApproaches = (searchText) => {

    if (searchText.length > 2) {
        return getApproachSearch(searchText);
    } else {
        return Promise.resolve({ options: [] });
    }
}

export const loadTechniques = (searchText) => {

    if (searchText.length > 2) {
        return getTechniqueSearch(searchText);
    } else {
        return Promise.resolve({ options: [] });
    }
}

export const loadModules = (searchText) => {

    if (searchText.length > 2) {
        return getModuleSearch(searchText);
    } else {
        return Promise.resolve({ options: [] });
    }
}

export const loadCustomers = (searchText) => {

    if (searchText.length > 2) {
        return getCustomerSearch(searchText);
    } else {
        return Promise.resolve({ options: [] });
    }
}

//#endregion


//#region table

export const onToggleDropDown = (toggleDropDown) => {
    toggleDropDown();
}

export const renderSizePerPageDropDown = (props) => {
    return (
        // eslint-disable-next-line react/jsx-no-undef
        <SizePerPageDropDown className='my-size-per-page' btnContextual='btn-info' variation='dropup' onClick={() => onToggleDropDown(props.toggleDropDown)} />
    );
}

export const renderCustomClearSearch = (onClick) => {
    return (
        <button
            className='ml-2 btn btn-success'
            onClick={onClick}>
            clear
      </button>
    );
}

export const createCustomExportCSVButton = (onClick) => {
    return (
        <button className="btn btn-info" onClick={onClick}>Custom Export CSV Btn</button>
    );
}

export const renderShowsTotal = (start, to, total) => {
    return (
        <p style={{ color: 'blue' }}>
            From {start} to {to}, totals is {total}&nbsp;&nbsp;(its a customize text)
      </p>
    );
}

//#endregion


//#region dynamic form events

export const onInputChange = (me, event, callback) => {
    me.setState({
        ...me.state,
        [event.target.name]: event.target.value
    }, () => {
        // callbacks
        if (typeof (undefined) != typeof (callback)) {
            callback(me);
        }
    });
}


export const convertYoutubeUrl = (url) => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return "//www.youtube.com/embed/" + match[2];
    } else {
        return url;
    }
}

export const onSelectChange = (me, name, multi, option, callback) => {
    if (multi) {
        me.setState({
            [name]: option
        }, () => {
            if (typeof (undefined) != typeof (callback)) {
                callback(me);
            }
        });
    } else {
        me.setState({
            [name]: option.value
        }, () => {
            if (typeof (undefined) != typeof (callback)) {
                callback(me);
            }
        });
    }
}


//#endregion


//#region common image-video functions

export const addVideo = (me, event, callback) => {

    let videos = me.state.videos;
    videos.push({ url: convertYoutubeUrl(me.state.video), name: me.state.video, id: 0 });
    me.setState({
        videos: videos,
        video: ""
    }, () => {
        // callbacks
        if (typeof (undefined) != typeof (callback)) {
            callback(me);
        }
    });

}

export const onImageRemoveClick = (me, index, callback) => {
    let images = me.state.images;
    images.splice(index, 1);
    me.setState({
        images: images
    }, () => {
        // callbacks
        if (typeof (undefined) != typeof (callback)) {
            callback(me);
        }
    });
}

export const onVideoRemoveClick = (me, index, callback) => {
    let videos = me.state.videos;
    videos.splice(index, 1);
    me.setState({
        videos: videos
    }, () => {
        // callbacks
        if (typeof (undefined) != typeof (callback)) {
            callback(me);
        }
    });
}


//#endregion