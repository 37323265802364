import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllApproaches } from "./../../../actions/approaches";
import SearchPanel from "./../components/searchPanel";
import { deleteApproach } from "./../../../actions/approaches";
import ConfirmDialog from './../../components/models/confirm-dialog';
import { onToggleDropDown, renderSizePerPageDropDown, renderCustomClearSearch, createCustomExportCSVButton, renderShowsTotal } from './../../adminDashboard/components/helper';

class ApproachList extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          deleteModelShow: false
      }
    this.toggleDelete = this.toggleDelete.bind(this);

  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(getAllApproaches());
  }

  cellButton(cell, row, enumObject, rowIndex) {
    return (
      <div>
        <Link to={"/admin/approaches/EditApproach/" + cell} className="btn btn-info p-1"> <small>Edit</small> </Link>
        <button className="btn btn-danger p-1" onClick={this.setDelete.bind(this, cell)}> <small>Delete</small> </button>
      </div>
    )
  }

  setDelete(id, $this) {
    let me = this;
    me.setState({
      deleteModelShow: !me.state.deleteModelShow,
      modelId: id
    });
  }

  toggleDelete(bool) {
    if (bool) {
      this.props.dispatch(deleteApproach(this.state.modelId));
    }
    this.setState({
      deleteModelShow: !this.state.deleteModelShow,
      modelId: 0
    });
  }

  render() {
    const options = {
      page: 1,  // which page you want to show as default
      sizePerPageList: [{
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
              text: 'All', value: this.props.model.approaches ? this.props.model.approaches.length : 0
      }], // you can change the dropdown list for size per page
		sizePerPage: this.props.model.approaches ? this.props.model.approaches.length : 25,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      //paginationPosition: 'top'  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
      exportCSVBtn: createCustomExportCSVButton,
      clearSearch: true,
      clearSearchBtn: renderCustomClearSearch,
      searchPanel: (props) => (<SearchPanel {...props} />),
      sizePerPageDropDown: renderSizePerPageDropDown
    };
    

    if (this.props.model.approaches) {
      return (<div>
        <div className="p-3">
          <BootstrapTable data={this.props.model.approaches} exportCSV search striped hover info version='4' className="thead-inverse" options={options} pagination>
            <TableHeaderColumn isKey dataField='id' dataSort caretRender={this.getCaret}>#</TableHeaderColumn>
            <TableHeaderColumn dataField='name' dataSort caretRender={this.getCaret}>Header</TableHeaderColumn>
            <TableHeaderColumn dataField='description' dataSort caretRender={this.getCaret}>Description</TableHeaderColumn>
            <TableHeaderColumn
              dataField='id'
              dataFormat={this.cellButton.bind(this)}
            />
          </BootstrapTable>
          <ConfirmDialog name="wow" isOpen={this.state.deleteModelShow} toggle={this.toggleDelete} modelTitle={"Confirm"} modelBody={"Are you sure, you want to delete approach?"} />
        </div>
      </div>
      );
    } else {
      return (<div>
        <div className="p-3 text-center">
            Loading ...
        </div>
      </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    model: state.store.approach
  };
}

export default connect(mapStateToProps)(ApproachList);