import React from 'react';
import { connect } from 'react-redux';
import { getAllModules } from "./../../actions/modules";
// import "../../assets/theme-assets/scss/style.scss";
import Modalities from "./pages/modalities/index"
 
class TestingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: 'basic',
            searchTerm: '',
            viewMode: 'grid',
          };
    }
    UNSAFE_componentWillMount() {
        this.props.dispatch(getAllModules());
    }
    handleCategoryChange = (event) => {
        this.setState({ selectedCategory: event.target.value });
    };
    
    handleSearchChange = (event) => {
        this.setState({ searchTerm: event.target.value });
    }; 
    handleViewModeClick = (mode) => {
        this.setState({ viewMode: mode });
    };
    render() {
		// eslint-disable-next-line no-undef
		FreshWidget.init("", { "queryString": "&widgetType=popup&formTitle=Ask+the+Experts&submitTitle=Ask+Question&submitThanks=Thank+you+for+your+question.++Our+experts+will+review+and+will+follow-up+shortly.&attachFile=no", "utf8": "?", "widgetType": "popup", "buttonType": "text", "buttonText": "Ask the Experts", "buttonColor": "white", "buttonBg": "#0000FF", "alignment": "2", "offset": "235px", "submitThanks": "Thank you for your question.  Our experts will review and will follow-up shortly.", "formHeight": "500px", "url": "https://stratpsychexperts.freshdesk.com" });
       
        const allModules = this.props.model.modules;
        const { selectedCategory, searchTerm, viewMode } = this.state;
        const filteredModules = allModules.filter(module =>
          module.type === selectedCategory &&
          module.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return (
        <div>
            <Modalities />
            {/* <ModuleDisplay /> */}
            <div className="rbt-page-banner-wrapper">
                {/* <!-- Start Banner BG Image  --> */}
                <div className="rbt-banner-image"></div>
                {/* <!-- End Banner BG Image  --> */}
                <div className="rbt-banner-content">
                    {/* <!-- Start Banner Content Top  --> */}
                    <div className="rbt-banner-content-top">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            {/* <!-- Start Breadcrumb Area  --> */}
                            <ul className="page-list">
                            <li className="rbt-breadcrumb-item"><a href="/">Home</a></li>
                            <li>
                                <div className="icon-right"><i className="feather-chevron-right"></i></div>
                            </li>
                            <li className="rbt-breadcrumb-item active">All Modules</li>
                            </ul>
                            {/* <!-- End Breadcrumb Area  --> */}
                            <div className=" title-wrapper">
                            <h1 className="title mb--0">All Modules</h1>
                            <span className="rbt-badge-2">
                                <div className="image">🎉</div> {this.props.model.modules.length} Modules
                            </span>
                            </div>
                            <p className="description"> <strong>Welcome!</strong> Get started by selecting a level or searching for a topic.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                    {/* <!-- End Banner Content Top  --> */}
                    {/* <!-- Start Course Top  --> */}
                    <div className="rbt-course-top-wrapper mt--40">
                    <div className="container">
                        <div className="row g-5 align-items-center">
                        <div className="col-xl-5 col-lg-12 col-md-12">
                            <div className="rbt-sorting-list d-flex flex-wrap align-items-center">
                            <div className="rbt-short-item switch-layout-container">
                                <ul className="course-switch-layout">
                                    <li className="course-switch-item">
                                        <button 
                                            className={`rbt-grid-view ${viewMode === 'grid' ? 'active' : ''}`} 
                                            title="Grid Layout"
                                            onClick={() => this.handleViewModeClick('grid')}>
                                            <i className="feather-grid"></i>                                             
                                            <span className="text">Grid</span>
                                        </button>
                                    </li>
                                    <li className="course-switch-item">
                                        <button 
                                            className={`rbt-list-view ${viewMode === 'list' ? 'active' : ''}`} 
                                            title="List Layout"
                                            onClick={() => this.handleViewModeClick('list')}>
                                            <i className="feather-list"></i> 
                                            <span className="text">List</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="rbt-short-item">
                                <span className="course-index">Showing {filteredModules.length} results</span>
                            </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-12 col-md-12">
                            <div className="rbt-sorting-list d-flex flex-wrap align-items-end justify-content-start justify-content-xl-end">
                            <div className="rbt-short-item">
                                <span className="rbt-search-style me-0">
                                    <input 
                                        type="text" 
                                        id="searchInput"
                                        placeholder="Search Your Module.."
                                        value={searchTerm}
                                        onChange={this.handleSearchChange}
                                    />
                                    {/* <button type="submit" className="rbt-search-btn rbt-round-btn">
                                        <i className="feather-search"></i>
                                    </button> */}
                                </span>
                            </div>
                            <div className="rbt-short-item">
                                <div className="filter-select">
                                <span className="select-label d-block">Select Module Type</span>
                                <div className="filter-select rbt-modern-select search-by-category">
                                    <select data-size="7" onChange={this.handleCategoryChange} value={selectedCategory}>
                                        <option value="basic">Foundational</option>
                                        <option value="intermediate">Intermediate</option>
                                        <option value="advanced">Advanced</option>
                                    </select>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    {/* <!-- End Course Top  --> */}
                </div>
            </div>
            {/* <!-- Start Gird Card Style  --> */}
            <div className="rbt-section-overlayping-top rbt-section-gapBottom pt-5">
                <div className="container pt-2">
                    <div className="row">
                            <div className="col-lg-12">
                                <div className={`rbt-course-grid-column ${viewMode === 'grid' ? 'active-grid-view' : 'active-list-view'}`}>
                                    {filteredModules.map((module) => (       
                                        <div key={module.id}  className="course-grid-3">
                                            <div className={`rbt-card variation-01 rbt-hover ${viewMode === 'list' ? 'card-list-2' : ''}`}>
                                                <div className="rbt-card-img">
                                                    <a href={`module/${module.id}`}>
                                                        <img src={require("../../assets/theme-assets/images/course/course-online-01.jpg")} alt={module.name}/>
                                                    </a>
                                                </div>
                                                <div className="rbt-card-body">                                        
                                                    <h4 className="rbt-card-title">
                                                        <a href={`module/${module.id}`}>
                                                            {module.name}
                                                        </a>
                                                    </h4>
                                                    <p className="rbt-card-text">
                                                        {module.description}
                                                    </p> 
                                                    <div className="rbt-card-bottom"> 
                                                        <a className="rbt-btn-link" href={`module/${module.id}`}>
                                                            Learn More<i className="feather-arrow-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Gird Card Style --> */}
            <div className="rbt-callto-action-area bg-color-white rbt-section-gap">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <div className="rbt-callto-action callto-action-default bg-color-white rbt-radius shadow-1">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="inner">
                                            <div className="rbt-category mb--20">
                                                <a href="/approaches">Approaches</a>
                                            </div>
                                            <h4 className="title mb--10">Free Online Courses from Histudy School To Education</h4>
                                            <p className="mb--15">Top instructors from around the world</p>
                                            <div className="read-more-btn">
                                                <a className="rbt-btn rbt-switch-btn btn-gradient btn-sm" href="/approaches">
                                                    <span data-text="Join Now">View Now</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="rbt-callto-action callto-action-default bg-color-white rbt-radius shadow-1">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="inner">
                                            <div className="rbt-category mb--20">
                                                <a href="/techniques">Techniques</a>
                                            </div>
                                            <h4 className="title mb--10">Free Online Courses from Histudy School To Education</h4>
                                            <p className="mb--15">Top instructors from around the world</p>
                                            <div className="read-more-btn">
                                                <a className="rbt-btn rbt-switch-btn btn-gradient btn-sm" href="/techniques">
                                                    <span data-text="Join Now">View Now</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rbt-separator-mid">
                <div className="container">
                    <hr className="rbt-separator m-0"/>
                </div>
            </div>
        </div>
        );
    };
}
function mapStateToProps(state) {
    return {
        model: state.store.module
    };
}
export default connect(mapStateToProps)(TestingPage);