import React, {  } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import ApproachList from './approachList';
import ManageApproach from './manageApproach.js'

class Approach extends React.Component {
    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {

    }

    render() {
        return (<div className="approach">
            <Switch>
                <Route component={ApproachList} path="/admin/approaches/approachList"></Route>
                <Route component={ManageApproach} path="/admin/approaches/addApproach"></Route>
                <Route path="/admin/approaches/EditApproach/:id" render={ routeProps => <ManageApproach approach={this.props.approach} {...routeProps} />} >
                </Route>
            </Switch>   
        </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state,
        approach: state.store.approach
    };
}

export default connect(mapStateToProps)(Approach);