import React from 'react';
import { Link } from 'react-router-dom';

export default class SeminarsHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<div className="p-3">
            <div className="btn-group" role="group" aria-label="">
                <Link to='/admin/seminars/addSeminar' className="btn btn-dark mr-2"> Add Seminars</Link>
                <Link to='/admin/seminars/seminarList' className="btn btn-dark mr-2"> List</Link>
            </div>
        </div>
        );
    }
}