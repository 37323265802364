import React, { Component, PropTypes } from 'react';
import { BrowserRouter as Router, Route, Link, hashHistory, Switch } from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { getAllUsers } from "./../../../actions/user";
import ManageUser from './manageUser';
import UserList from './userList';
import AddQuickUser from './addQuickUser';

class User extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (<div className="customer">
            <Switch>
                <Route component={UserList} path="/admin/users/usersList"></Route>
                <Route component={ManageUser} path="/admin/users/addUser"></Route>
                <Route component={AddQuickUser} path="/admin/users/addQuickUser"></Route>
                <Route path="/admin/users/editUser/:id" render={
                    routeProps => <ManageUser {...routeProps} />} >
                </Route>
            </Switch>
        </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        model: state.store.user
    };
}

export default connect(mapStateToProps)(User);