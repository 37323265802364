import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
export default class ConfirmDialog extends React.Component {

  constructor(props) {
    super(props); 
  }
  toggle(bool, e) {
    e.stopPropagation();
    this.props.toggle(bool);
  }
  render() {
    let { isOpen } = this.props;
          
    return (
      <div>
        <Modal isOpen={isOpen} toggle={this.toggle.bind(this, false)} className={this.props.className}>
          <ModalHeader toggle={this.toggle.bind(this, false)}> {this.props.modelTitle} </ModalHeader>
          <ModalBody>
            {this.props.modelBody}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle.bind(this, true)}>Ok</Button>{' '}
            <Button color="secondary" onClick={this.toggle.bind(this, false)}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
                
    );
  }
}
