import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { recoverPassword } from './../../actions/user';
import { connect } from 'react-redux';
import Auth from './../login/auth';
import { getUserInfo } from './../../actions/user';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

class Recover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
  }

  UNSAFE_componentWillMount() {

  }

  componentDidMount() {
  }

  onInputChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value
    }, () => {
      // callbacks
    });
  }

  onSaveButtonClick() {
 
    let model = {
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        email: this.state.username,
      }
    };
    this.props.dispatch(recoverPassword(model, this.props.history));
  }
  render() {

    return (

      <div className="login-page recovery">
        <div className="container d-flex align-items-center">
          <div className="form-holder has-shadow">
            <div className="row">
              <div className="col-lg-6">
                <div className="d-flex align-items-center justify-content-center login-logo-box">
                  <div className="content">
                    <div className="logo">
                      <a href="/" >
                        <h1 className="h1 text-white">
                          <strong>
                            <img src={require('./../../assets/images/logo-new.png')} className="img-responsive login-image" /></strong>
                        </h1>
                      </a>
                    </div>
                    <div className="text-info login-logo-text">
                      The on-line learning center for mental health professionals.</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 bg-white">
                <div className="form d-flex align-items-center justify-content-center">
                  <div className="content">

                    <div id="login-form" method="post">
                      <div className="form-group">
                        <label className="label-material">Email</label>
                        <input id="username" name="username"
                          value={this.state.username}
                          onChange={this.onInputChange}
                          required="" type="email" className="form-control" />

                      </div>

                      <button id="login" onClick={this.onSaveButtonClick} className="btn btn-primary btn-block">
                      Send Recovery Email
                      </button>
                      {/* <small className="p-1 text-danger"> { this.props.auth.hasError  && <div> {this.props.auth.error} </div> } </small> */}
                      <hr />
                      <div className="form-group">
                        <label className="label-material">
                        Please enter the email address for your account.  A recovery email with a verification link will be sent to you.  After clicking the link, you will be directed to a page where you can change your password
                        </label>
                        <Link className="text-info" to={`/login`}> Back to the Login Page</Link>  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.store.user
  };
}

export default withRouter(connect(mapStateToProps)(Recover));