import React from 'react';

import './modalities copy.scss';

const Modalities = () => {
  return (
    <div className="learning-new-modalities">
      <div className="rbt-categories-area rbt-section-gap">
        <div className="m-5">      
          <div className="row g-5">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
              <div className="rbt-cat-box rbt-cat-box-1 variation-5 text-start">
                <div className="inner">
                  <div className="thumbnail">
                    <a href="/module/45">
                      <img className="custom-modalities-img" src={require("./images/img1.jpg")} alt="Category Images" />
                    </a>
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <a className="title-link" href="/module/45">Group Psychotherapy</a>
                    </h5>
                    <div className="read-more-btn d-flex align-items-center">
                      <a className="title-link" href="/module/45">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
              <div className="rbt-cat-box rbt-cat-box-1 variation-5 text-start">
                <div className="inner">
                  <div className="thumbnail">
                    <a href="/module/46">
                      <img className="custom-modalities-img" src={require("./images/img2.jpg")} alt="Category Images" />
                    </a>
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <a className="title-link" href="/module/46">Family Psychotherapy</a>
                    </h5>
                    <div className="read-more-btn d-flex align-items-center">
                      <a className="title-link" href="/module/46">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
              <div className="rbt-cat-box rbt-cat-box-1 variation-5 text-start">
                <div className="inner">
                  <div className="thumbnail">
                    <a href="/module/47">
                      <img className="custom-modalities-img" src={require("./images/img3.jpg")} alt="Category Images" />
                    </a>
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <a className="title-link" href="/module/47">Couples Psychotherapy</a>
                    </h5>
                    <div className="read-more-btn d-flex align-items-center">
                      <a className="title-link" href="/module/47">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg>                    
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-3">
              <div className="rbt-cat-box rbt-cat-box-1 variation-5 text-start">
                <div className="inner">
                  <div className="thumbnail">
                    <a href="/module/48">
                      <img className="custom-modalities-img" src={require("./images/img4.jpg")} alt="Category Images" />
                    </a>
                  </div>
                  <div className="content">
                    <h5 className="title">
                      <a className="title-link" href="/module/48">Individual Psychotherapy</a>
                    </h5>
                    <div className="read-more-btn d-flex align-items-center">
                      <a className="title-link" href="/module/48">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l14 0"></path><path d="M15 16l4 -4"></path><path d="M15 8l4 4"></path></svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modalities;