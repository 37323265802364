import React from 'react';
import 'react-rater/lib/react-rater.css';
import $ from 'jquery';
import APIServices from "../../services";
import './seminar.scss';
import { Link } from 'react-router-dom';
import { SRLWrapper } from "simple-react-lightbox";
import TextToSpeech from '../components/text-to-speech';

export default class Module extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      name: "",
      description: "",
      content: "",
      images: [],
      videos: [],
      approaches: [],
      techniques: [],
      segments: [],
      seminars: [],
      videoLinks: [],
      postTestAttemptData: {},
      isPostTestAlreadyAppeared: 'no',
      hoursRemaining: 0,
      lastPostTest: false,
    };

    this.resetModule = this.resetModule.bind(this);
    this.initModule = this.initModule.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.initModule(this.props.match.params.moduleId);
  }
  scrollNav() {
    $('.list-group a').click(function () {
      let main = $('.scrollspy-example');
      main.stop().animate({
        scrollTop: $($(this).attr('href'))[0].offsetTop + 1
      }, 400);
      return false;
    });
    $('.list-group a:first').click();
  }

  componentDidMount() {
    this.scrollNav();
  }

  initModule(moduleId) {
    if (typeof (moduleId) !== typeof (undefined) && Number(moduleId) > 0) {
      APIServices.getModule(moduleId)
        .then(response => response.json())
        .then(response => {
          // response.data
          this.setState({
            ...this.state,
            name: response.module.name,
            description: response.module.description,
            id: response.module.id,
            images: response.module.images,
            videos: response.module.videos,
            approaches: response.approaches,
            techniques: response.techniques,
            seminars: response.seminars,
            isPostTestAlreadyAppeared: response.isPostTestAlreadyAppeared,
            hoursRemaining: response.hoursRemaining,
            lastPostTest: response.lastPostTest,
            videoLinks: response.videoLinks,
            segments: response.segments.map((_segment) => {
              return {
                ..._segment,
                isMore: true
              };
            })
          });

        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.resetModule();
    }
  }
  resetModule() {
    this.setState({
      id: 0,
      name: "",
      description: "",
      content: "",
      images: [],
      videos: [],
      approaches: [],
      techniques: [],
      seminars: [],
      segments: [],
      videoLinks: [],
      isPostTestAlreadyAppeared: 'no',
      hoursRemaining: 0,
      lastPostTest: false,
    });
  }

  moreButtonClick = (i) => {
    let articles = this.state.segments.slice();
    articles[i].isMore = false;
    this.setState({
      segments: articles
    });
  }
  seminarURL(url) {
    if (!url.match(/^[a-zA-Z]+:\/\//)) {
      url = 'http://' + url;
    }
    return url;
  }

  render() {


    const myComponentStyle = {
      'pointer-events': 'auto',
      'cursor': 'not-allowed'
    }

    let model = {};
    model.articles = [];
    model.articleTitles = [];
    model.videoLinks = [];
    model.videoLinks = this.state.videoLinks;

    let moreButtonClick = this.moreButtonClick;


    let articles = this.state.segments.map(function (article, i) {
      let listItemName = window.location.origin + window.location.pathname + "#list-item-" + Number(i + 1);
      let listItemId = "list-item-" + Number(i + 1);
      let isMoreButton = (article.description.length > 500) ? article.isMore : false;


      model.articleTitles.push(
        <a key={i} className="list-group-item list-group-item-action" href={listItemName}>{article.name}</a>
      );

      return (
        <div key={i} className={"text-auto-adjust p-2 border border-hr border-left-0 border-right-0 border-bottom-0 bg-white" + ((i % 2) == 0 ? "" : " bg-white text-dark") + ((i == 0 ? " border-top-0" : ""))}>
          <div className="media">
            <div className="media-body">
              <h4 id={listItemId} className="">{article.name} 
              
              <TextToSpeech text={article.name + ": " + article.content} />
              
             </h4>
              {/* {article.image != '' &&
                                <img className="d-flex img-fluid rounded w-36 wm-100 mr-3 float-left" src={require('./../../assets/images/' + article.image) } />
                            }*/}
              {article.images.length > 0 &&
                article.images.map((image, index) => {
                  return (
                    <SRLWrapper key={image.url}>
                      <img className="d-flex img-fluid rounded w-36 wm-100 mr-3 float-left" key={image.url} src={image.url} />
                    </SRLWrapper>
                  );
                })
              }
              {article.videos.length > 0 &&
                article.videos.map((video) => {

                  const poster = model.videoLinks.find(obj => {
                    return obj.videoUrl === video.url;
                  });

                  let _posterURL = "https://www.stratpsych.com/static/media/logo-new.2908e1ee.png";

                  if(poster && poster.posterUrl){
                    _posterURL = poster.posterUrl;
                  }

                  if(video.url.indexOf("content.eventease.com") > 0){
                    return (<div className="embed-responsive embed-responsive-16by9 w-36 wm-100 mr-3 float-left" key={video.url}>
                    <video className="embed-responsive-item" poster={_posterURL} controls="controls" src={video.url} allowFullScreen controlsList="nodownload">
                      Video tag is not supported in this browser.
                    </video>
                  </div>);
                  }
                  else{
                    return (<div className="embed-responsive embed-responsive-16by9 w-36 wm-100 mr-3 float-left" key={video.url}>
                    <iframe className="embed-responsive-item" src={video.url} allowFullScreen />
                  </div>);
                  }

                })
              }
              {/* {
                                !isMoreButton &&
                                <p className="" dangerouslySetInnerHTML={{ __html: article.description }} />
                            } 
                            {
                                isMoreButton &&
                                <p className="" dangerouslySetInnerHTML={{ __html: description }} />
                            } */}
              <p className="" dangerouslySetInnerHTML={{ __html: article.content }} />
              {
                isMoreButton &&
                <div className="d-flex justify-content-center">
                  <button type="button" onClick={moreButtonClick.bind(this, i)} className="btn btn-dark">More+</button>
                </div>
              }
            </div>
          </div>
        </div>
      );
    });

    const getValidUrl = (url = "") => {
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, "");

      if (/^(:\/\/)/.test(newUrl)) {
        return `http${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `http://${newUrl}`;
      }

      return newUrl;
    };

    if (this.state.segments.length > 0) {

      let approachIndex = Number(this.state.segments.length + 1);
      let listItemNameApproaches = window.location.origin + window.location.pathname + "#list-item-" + approachIndex;
      let listItemNameApproachesId = "list-item-" + approachIndex;

      model.articleTitles.push(
        <a key={model.articleTitles.length + 1} className="list-group-item list-group-item-action" href={listItemNameApproaches}>Approaches</a>
      );

      articles.push(<div key={approachIndex} className={"text-auto-adjust p-2 border border-hr border-left-0 border-right-0 border-bottom-0 bg-white" + ((approachIndex % 2) == 0 ? "" : " bg-white text-dark") + ((approachIndex == 0 ? " border-top-0" : ""))}>
        <div className="media">
          <div className="media-body">
            <h4 id={listItemNameApproachesId} className=""> Approaches </h4>
            <p />
            {this.state.approaches.map((approach, k) => {
              const approachKey = 'approach' + approach.approach.id;
              return <a key={k} href={`#${approachKey}`} data-toggle="modal" data-target={`#${approachKey}`}> {k > 0 && ", "} {approach.approach.name}
                <div className="modal fade bd-example-modal-lg1 show" id={approachKey} tabIndex="-1" role="dialog">
                  <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title"> {approach.approach.name} </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
                      <div className="modal-body" >
                        <div className="h5 overflow-hidden">
                          <p className="" dangerouslySetInnerHTML={{ __html: approach.approach.content }} />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </a>;
            })}
          </div>
        </div>
      </div>);


      let techniqueIndex = Number(this.state.segments.length + 2);
      let listItemNametechniques = window.location.origin + window.location.pathname + "#list-item-" + techniqueIndex;
      let listItemNametechniquesId = "list-item-" + techniqueIndex;

      model.articleTitles.push(
        <a key={techniqueIndex} className="list-group-item list-group-item-action" href={listItemNametechniques}>Techniques</a>
      );

      articles.push(<div key={techniqueIndex} className={"text-auto-adjust p-2 border border-hr border-left-0 border-right-0 border-bottom-0 bg-white" + ((techniqueIndex % 2) == 0 ? "" : " bg-white text-dark") + ((techniqueIndex == 0 ? " border-top-0" : ""))}>
        <div className="media">
          <div className="media-body">
            <h4 id={listItemNametechniquesId} className=""> Techniques </h4>
            <p />
            {this.state.techniques.map((technique, k) => {
              const techniqueKey = 'technique' + technique.technique.id;
              return <a key={k} href={`#${techniqueKey}`} data-toggle="modal" data-target={`#${techniqueKey}`}> {k > 0 && ", "} {technique.technique.name}
                <div className="modal fade bd-example-modal-lg1 show" id={techniqueKey} tabIndex="-1" role="dialog">
                  <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title"> {technique.technique.name} </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
                      <div className="modal-body" >
                        <div className="h5 overflow-hidden">
                          <p className="" dangerouslySetInnerHTML={{ __html: technique.technique.content }} />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </a>;
            })}
          </div>
        </div>
      </div>);

      if (this.state.seminars.length > 0) {
        model.articleTitles.push(
          <span key={model.articleTitles.length + 1}>
            <a key={model.articleTitles.length + 1} className="list-group-item list-group-item-action seminar-button" href={`#seminar${Number(this.state.id)}`} data-toggle="modal" data-target={`#seminar${Number(this.state.id)}`}>
              <img src={require("../../assets/images/webinar_icon.png")} className="mt-2" width="100" /> <h1 className="align-middle d-inline-block">Webinars</h1>
            </a>
            <div className="modal fade " id={`seminar${Number(this.state.id)}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header text-center">
                    <h4 className="modal-title seminar-title w-100 font-weight-bold ds-title">Seminars</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body seminarModal">
                    {this.state.seminars.length > 0 &&
                      this.state.seminars.map((seminar, k) => {
                        return (<div key={k} className="seminar-info">
                          <div className="avatar">
                            <img src={require("../../assets/images/webinar_icon.png")} alt="webinar-image" className="img-fluid" onClick={() => window.open(getValidUrl(seminar.seminar.url), "_blank")} />
                          </div>
                          <div className="info">
                            <p className="seminarName" onClick={() => window.open(getValidUrl(seminar.seminar.url), "_blank")}>{seminar.seminar.title}</p>
                            <span className="seminar-detail font-italic">Presenter: {seminar.seminar.author}</span>
                            <span className="seminar-detail">${seminar.seminar.price}</span>
                            <span className="seminar-detail">Use Coupon Code: <strong>{seminar.seminar.seminarCompany.couponCode}</strong></span>
                          </div>
                          <p className="seminar-detail ml-3">{seminar.seminar.description}</p>
                        </div>);
                      })
                    }
                    {
                      this.state.seminars.length == 0 &&
                      <div className="text-primary text-center"> The seminars are not available yet.</div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </span>
        );
      }
    }


    // eslint-disable-next-line no-undef
    FreshWidget.init("", { "queryString": "&widgetType=popup&formTitle=Ask+the+Experts&submitTitle=Ask+Question&submitThanks=Thank+you+for+your+question.++Our+experts+will+review+and+will+follow-up+shortly.&attachFile=no", "utf8": "?", "widgetType": "popup", "buttonType": "text", "buttonText": "Ask the Experts", "buttonColor": "white", "buttonBg": "#0000FF", "alignment": "2", "offset": "235px", "submitThanks": "Thank you for your question.  Our experts will review and will follow-up shortly.", "formHeight": "500px", "url": "https://stratpsychexperts.freshdesk.com" });

    return (
      <div>
        <div className="container-fluid px-4 text-center bg-white pt-4">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="ml-0 h2 text-info module-title"> {this.state.name} </h1>
            </div>
          </div>
        </div>
        <div className="container-fluid p-4 bg-white">
          <div className="row">
            <div className="col-md-4 hide-sm">
              <div id="list-example" className="list-group">
                {model.articleTitles}
              </div>
            </div>
            <div className="col-md-8 border border-hr bg-white">
              <div data-spy="scroll" data-target="#list-example" data-offset="0" className="scrollspy-example">
                {articles}
              </div>
            </div>
          </div>
        </div>
        <hr className="hr-color" />


        <div className="container-fluid p-4 bg-white">
          <div className="d-flex justify-content-center py-2">
            {(this.state.isPostTestAlreadyAppeared != 'no' && !this.state.isPostTestAlreadyAppeared && this.state.hoursRemaining == 0) &&
              <Link to={"/module/" + this.props.match.params.moduleId + "/post-test"}
                className="btn btn-dark"> Post-Test </Link>
            }
            {this.state.isPostTestAlreadyAppeared &&
              <div>
                You have already completed PostTest for {this.state.name} module
              </div>
            }
            {(!this.state.isPostTestAlreadyAppeared && this.state.hoursRemaining > 0) &&
              <div className="text-center">
                <p>
                You have not received a passing score for the {this.state.name} module.  You may retake the test after {this.state.hoursRemaining} hours.
                </p>
                <a className="btn btn-dark disabled" style={myComponentStyle}>
                  Post-Test
                </a>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
