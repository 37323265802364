import {
    createStore, applyMiddleware, combineReducers
} from "redux";
import {routerReducer, routerMiddleware} from 'react-router-redux';
import {createLogger} from 'redux-logger';
import reducers from "../reducers";
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
    
const history = createBrowserHistory();
let middlewareBean = [routerMiddleware(history), thunk];
let logger = createLogger();
middlewareBean = [...middlewareBean, logger];

if (process.env.NODE_ENV !== 'production') {
    middlewareBean = [...middlewareBean, logger];
}

const middleware = applyMiddleware(...middlewareBean);

const store = createStore(combineReducers({
   store: reducers,
   router: routerReducer
}), middleware);

export default store;

if (module.hot) {
   module.hot.accept('./../reducers', () => {
       const nextRootReducer = require('./../reducers');
       store.replaceReducer(buildRootReducer(nextRootReducer.reducers));
   });
}

function buildRootReducer(allReducers) {
    return combineReducers({
        store: allReducers,
        router: routerReducer
    });
}
