import {combineReducers} from "redux";
import * as actions from "../actiontypes/customers";

const initialState = {
    isFetching: false,
    firstName: '',
    lastName: '',
    userName:'',
    customers:[],
    customer: null
};

export default function Customer(state = initialState, action) {
    switch (action.type) {
        case actions.CUSTOMERS:
            return {
                ...state,
                customers: action.payload.response
            };
              
        case actions.CUSTOMERS_GET:
            return {
                ...state,
                customer: action.payload.response.data
            };
        case actions.CUSTOMERS_GET_START:
            return {
                ...state,
                customer : null
            };
        default:
            return state;
    }
};