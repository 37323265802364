export const TECHNIQUES = 'TECHNIQUES/ALL';
export const TECHNIQUES_WITH_DETAILS = 'TECHNIQUES/ALL_WITH_DETAILS';
export const TECHNIQUES_GET = 'TECHNIQUES/GET';
export const TECHNIQUES_GET_START =  'TECHNIQUES/GET_START';
export const TECHNIQUES_GET_DONE = 'TECHNIQUES/GET_DONE';
export const UPLOADING_IMAGE_RESET =  'APPROACHES/UPLOADING_IMAGE_RESET';
export const UPLOADING_IMAGE_START = 'APPROACHES/UPLOADING_IMAGE_START';
export const UPLOADING_IMAGE_DONE = 'APPROACHES/UPLOADING_IMAGE_DONE';




 



