import React from 'react';
import {BrowserRouter as Router, Route, Link, hashHistory} from 'react-router-dom';
import HeaderTop from './header/header-top';
import HeaderBottom from './header/header-bottom';
import HeaderModal from './header/header-modal';
import { connect } from 'react-redux';
import { getUserInfo } from "./../../actions/user";
import { getModulesTechniquesApproachesNames } from "../../actions/approaches";

class Header extends React.Component {

  componentDidMount() {
    this.props.dispatch(getModulesTechniquesApproachesNames());
  }
 
  render() {

    const { user } = this.props.user;

    return (
      <div>
        <HeaderTop {...this.props}/>
        <HeaderBottom />
        <HeaderModal />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { searchNames } = state.store.approach;
  return {
    user: state.store.user,
    searchNames
  };
}

export default connect(mapStateToProps)(Header);