import {combineReducers} from "redux";
import user from "./userReducer";
import auth from "./authReducer";
import approach from "./approachReducer";
import technique from "./techniqueReducers";
import module from "./moduleReducers";
import customer from "./customerReducer";
import segment from "./segmentReducer";
import posttest from "./posttestReducer";
import seminar from "./seminarReducer";
import video from "./videoReducer";

export default combineReducers({
    user,
    auth,
    approach,
    technique,
    module,
    customer,
    segment,
    posttest,
    seminar,
    video
})