import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllTechniquesWithDetails } from "./../../../../actions/techniques";
import LoaderFull from "./../../loader-full";
import { slugify } from '../../../helper';
import $ from 'jquery'; 

class Technique extends React.Component {
    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        this.props.dispatch(getAllTechniquesWithDetails());
    }

    componentDidMount = () => {
        this.setPositionForApproach();
    };

    componentDidUpdate = () => {
        this.setPositionForApproach();
    };

    setPositionForApproach(){
        if($(`#${this.props.match.params.techniqueName}`).length > 0){
            $(`#${this.props.match.params.techniqueName}`)[0].scrollIntoView({})
        }
    }

    render() {

        if (this.props.technique && this.props.technique.techniquesWithDetails) {

            let techniques = this.props.technique.techniquesWithDetails;

            return (<div className="technique bg-white">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-info h3 p-4 mx-4 text-center border border-left-0 border-top-0 border-right-0">
                            Techniques
                        </div>
                    </div>
                </div>
                <div>
                    {techniques.map((technique, index) => {
                        return (<div key={index} className="p-2 m-3 border border-left-0 border-top-0 border-right-0">
                         
                            <div className="row">
                                <div className="col-md-3">
                                {technique.technique.images.length > 0 && 
                                    technique.technique.images.map((image, index) => {
                                    return(<img key={index} className="d-flex img-fluid rounded float-left" 
                                        src={image.url} />);
                                    })
                                } 
                                {technique.technique.videos.length > 0 && 
                                    technique.technique.videos.map((video, index) => {
                                    return(<div key={index} className="embed-responsive embed-responsive-16by9 float-left">
                                        <iframe className="embed-responsive-item" src={video.url} allowFullScreen />
                                    </div>);
                                    })
                                } 
                                </div>
                                <div className="col-md-9" id={slugify(technique.technique.name)}>
                                    <div className="h4 text-info">
                                        {technique.technique.name}
                                    </div>
                                    <div className="h5 overflow-hidden">
										<p className="" dangerouslySetInnerHTML={{ __html: technique.technique.content }} />
                                    </div>
                                    <div className="h6 overflow-hidden">
                                        Modules : {technique.modules.length > 0 &&
                                                    technique.modules.map((module, index) => {
                                            return (<Link to={`/module/${module.module.id}`} key={index}>
												{index > 0 && ", "} {module.module.name}
                                                    </Link>);
                                                    })
                                                } 
                                    </div>
                                    <div className="h6 overflow-hidden">
                                        Approaches : {technique.approaches.length > 0 &&
                                                    technique.approaches.map((approach, index) => {
                                            return (<Link to={`/approaches/${slugify(approach.approach.name)}`} key={index}>
												{index > 0 && ", "} {approach.approach.name}
                                                    </Link>);
                                                    })
                                                } 
                                    </div>
                                </div>
                            </div>
                        </div>);
                    })}
                </div>
            </div>
            );
        } else {
            return (<LoaderFull />);
        }

    }
}

function mapStateToProps(state) {
    return {
        state: state,
        technique: state.store.technique
    };
}

export default connect(mapStateToProps)(Technique);