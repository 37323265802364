import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SearchPanel from "./../components/searchPanel";
import { getAllVideos, deleteVideo, deleteVideoPoster } from '../../../actions/video/index.js';
import ConfirmDialog from './../../components/models/confirm-dialog';
import VideoPoster from '../../components/models/video-poster';
import { renderSizePerPageDropDown, renderCustomClearSearch, createCustomExportCSVButton } from './../../adminDashboard/components/helper';
import {NotificationManager} from 'react-notifications';    

class VideoList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteModelShow: false,
      uploadImageModelShow: false,
      deleteImageModelShow: false
    };

    this.toggleDelete = this.toggleDelete.bind(this);
    this.toggleImageDelete = this.toggleImageDelete.bind(this);
    this.toggleUploadImage = this.toggleUploadImage.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(getAllVideos());
  }

  videoFormatter(url, row) {
    return <div className="video-grid">
      <div className="embed-responsive embed-responsive-16by9">
      <video poster={row.posterUrl} controls="controls" src={row.videoUrl} allowFullScreen controlsList="nodownload">
          Video tag is not supported in this browser.
        </video>
      </div>
    </div>
  }

  videoLinkFormatter(url) {
    return <div className="video-link">
      {url}
      <button className="btn btn-info" onClick={() =>  {navigator.clipboard.writeText(url); NotificationManager.success('Copied!!', 'Success',200);}}> Copy to Clipboard </button>
    </div>
  }

  posterActionCellButton(cell, row, enumObject, rowIndex) {
    return (      
      <div>
        {row.posterUrl !== null && (
          <div className="grid-container">
            <div className="grid-image-box d-inline-block p-2">
                  <img src={row.posterUrl} className="m-1 shadow rounded grid-image mx-auto" alt={row.posterUrl} />
            </div>
          </div>
        )}
        <button className="btn btn-danger" onClick={this.setUploadImage.bind(this, cell)}> Upload </button>
        {row.posterUrl !== null && (
          <button className="btn btn-danger" onClick={this.setImageDelete.bind(this, cell)}> Remove </button>
        )}
      </div>
    );
  }

  videoActionCellButton(cell, row, enumObject, rowIndex) {
    return (
      <div>
        <button className="btn btn-danger" onClick={this.setDelete.bind(this, cell)}> Delete </button>
      </div>
    );
  }

  setUploadImage(id, $this) {
    let me = this;
    me.setState({
      uploadImageModelShow: !me.state.uploadImageModelShow,
      modelId: id
    });
  }

  setImageDelete(id, $this) {
    let me = this;
    me.setState({
      deleteImageModelShow: !me.state.deleteImageModelShow,
      modelId: id
    });
  }

  setDelete(id, $this) {
    let me = this;
    me.setState({
      deleteModelShow: !me.state.deleteModelShow,
      modelId: id
    });
  }

  toggleImageDelete(bool) {
    if (bool) {
      this.props.dispatch(deleteVideoPoster(this.state.modelId));
    }
    this.setState({
      deleteImageModelShow: !this.state.deleteImageModelShow,
      modelId: 0
    });
  }

  toggleDelete(bool) {
    if (bool) {
      this.props.dispatch(deleteVideo(this.state.modelId));
    }
    this.setState({
      deleteModelShow: !this.state.deleteModelShow,
      modelId: 0
    });
  }

  toggleUploadImage(bool, data) {
    this.setState({
      uploadImageModelShow: !this.state.uploadImageModelShow,
      modelId: 0
    });
  }

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    // this.setState({ copySuccess: 'Copied!' });
  };


  render() {

    const options = {
      page: 1,  // which page you want to show as default
      sizePerPageList: [{
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: this.props.model.videos.length
      }], // you can change the dropdown list for size per page
      sizePerPage: this.props.model.videos.length,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      exportCSVBtn: createCustomExportCSVButton,
      clearSearch: true,
      clearSearchBtn: renderCustomClearSearch,
      searchPanel: (props) => (<SearchPanel {...props} />),
      sizePerPageDropDown: renderSizePerPageDropDown
    };
    if (this.props.model.videos.length > 0) {
      return (<div>
        <div className="p-3">
          <Link to="/admin" className="btn btn-dark mr-2"> Back to Home</Link>
        </div>
        <div className="p-3">
          <BootstrapTable data={this.props.model.videos} exportCSV search striped hover info version="4"
            className="thead-inverse" options={options} pagination>
            <TableHeaderColumn width="5%" isKey dataField="no" dataSort>#</TableHeaderColumn>
            <TableHeaderColumn dataField="videoName">Name</TableHeaderColumn>
			      <TableHeaderColumn dataField="videoUrl" dataFormat={this.videoLinkFormatter}>Permanent Url</TableHeaderColumn>
            <TableHeaderColumn dataField="videoUrl" dataFormat={this.videoFormatter}>Video Preview</TableHeaderColumn>
            <TableHeaderColumn dataField="id" dataFormat={this.posterActionCellButton.bind(this)}>Poster Preview</TableHeaderColumn>
            <TableHeaderColumn dataField="id" dataFormat={this.videoActionCellButton.bind(this)}>Video Action</TableHeaderColumn>
          </BootstrapTable>
        </div>
        <ConfirmDialog name="wow" isOpen={this.state.deleteModelShow} toggle={this.toggleDelete} modelTitle={"Confirm"} modelBody={"Are you sure, you want to delete Video?"} />
        <ConfirmDialog name="wow" isOpen={this.state.deleteImageModelShow} toggle={this.toggleImageDelete} modelTitle={"Confirm"} modelBody={"Are you sure, you want to delete Poster?"} />
        <VideoPoster name="wow2" isOpen={this.state.uploadImageModelShow} modelId={this.state.modelId} toggle={this.toggleUploadImage} modelTitle={"Upload Poster"} modelBody={"Are you sure, you want to delete Video?"} />
      </div>
      );
    } else {
      return (<div>
        <div className="p-3 text-center">
          Loading ...
        </div>
      </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    model: state.store.video
  };
}

export default connect(mapStateToProps)(VideoList);