import React, { Component } from 'react';
import { withRouter  } from 'react-router-dom';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addTechniques, updateTechnique } from "./../../../actions/techniques";
import ImageGrid from "./../components/imageGrid";
import VideoGrid from "./../components/videoGrid";
import { processResponse } from "./../../../actions";
import APIServices from "../../../services";
import * as API from "../../../services/config";
import Select from 'react-select';
import Loader from './../../components/loader';
import { getDescription, onInputChange, 
    onSelectChange, addVideo,
    onImageRemoveClick, onVideoRemoveClick ,
    loadModules, loadApproaches } from './../components/helper';

class ManageTechniques extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            id: -1,
            name: "",
            description: "",
            content: "",
            images : [],
            videos : [],
            modules : [],
            approaches : []
        }
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onImageRemoveClick = this.onImageRemoveClick.bind(this);
        this.onVideoRemoveClick = this.onVideoRemoveClick.bind(this);

        this.resetTechnique = this.resetTechnique.bind(this);
    }

    UNSAFE_componentWillMount() {
        // this.props.dispatch(uploadImageReset());
        if (typeof (this.props.match.params.id) != typeof (undefined) && Number(this.props.match.params.id) > 0) {
            APIServices.getTechniques(this.props.match.params.id)
                .then(processResponse)
                .then(response => {
                    // response.data
                    const contentBlock = htmlToDraft(response.data.technique.content);
                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        const editorState = EditorState.createWithContent(contentState);

                        this.setState({
                            ...this.state,
                            editorState,
                            name: response.data.technique.name,
                            description: response.data.technique.description,
                            id: response.data.technique.id,
                            images: response.data.technique.images,
                            videos: response.data.technique.videos,
                            modules: response.data.modules.map(function name(_module) {
                                return {
                                    id: _module.moduleId,
                                   name : _module.module.name
                                }
                            }),
                            approaches: response.data.approaches.map(function name(_approach) {
                                return {
                                    id: _approach.approachId,
                                   name : _approach.approach.name
                                }
                            })
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            this.resetTechnique();
        }
    }

    onEditorStateChange(editorState) {
        this.setState({
            editorState,
        });
    };
    resetTechnique(){
        this.setState({
            editorState: EditorState.createEmpty(),
            id: 0,
            name: "",
            description: "",
            content: "",
            images: [],
            videos: [],
            modules : [],
            approaches : []
        });
    }

    onInputChange(event) {
        onInputChange(this, event);
    }

    addVideo(event) {
        addVideo(this, event);
    }

    onSelectChange(name, multi, option) {
        onSelectChange(this, name, multi, option);
    }

    onSaveButtonClick() {
        let model = {
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                technique : {
                    name: this.state.name,
                    description: this.state.description,
                    content: getDescription(this),
                    images: this.state.images,
                    videos: this.state.videos
                },
                approaches : this.state.approaches.map(function name(approach) {
                    return {
                        approachId: approach.id
                    }
                }),
                modules : this.state.modules.map(function name(_module) {
                    return {
                        moduleId : _module.id
                    }
                })
            }
        }

        if(Number(this.state.id) > 0){
            model = {
                ...model,
                data : {
                    ...model.data,
                    technique: {
                        ...model.data.technique,
                        id: this.state.id
                    }
                    
                }
            }
            this.props.dispatch(updateTechnique(model));
        }else{
            this.props.dispatch(addTechniques(model));
            this.resetTechnique();
        }
        this.props.history.push('/admin/techniques/techniquesList');
    }

    handleChange(e) {
        let model = {
            file: e.target.files[0]
        };
        let me = this;
        console.log(me);
        APIServices.uploadImage(model).then(response => response.json())
              .then(data => {
                  let images = this.state.images;
                  images.push({ url : API.ROOT + data.url, name : data.fileName, id : 0 });
                  this.setState({
                    images : images
                  });
        })
        .catch(err => {
            console.log(err);
       })

    }

    onImageRemoveClick(index) {
        onImageRemoveClick(this, index);
    }

    onVideoRemoveClick(index) {
        onVideoRemoveClick(this, index);
    }

    render() {
        const { editorState } = this.state;

        if (this.state.id > -1) {
            return (
                <div>
                    <div>
                        <div className="p-4 text-center h2">
                        { (Number(this.state.id) > 0) ? "Edit" : "Add" } Techniques
                        </div>
                        <div className="p-4">
                            <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Techniques Header</label>
                                            <input type="text" name="name" value={this.state.name} className="form-control" id="name" onChange={this.onInputChange} placeholder="Enter Techniques Header" />
                                        </div>
                                        <div className="form-group">
                                            <label>Techniques description</label>
                                            <textarea name="description" value={this.state.description} className="form-control" id="name" 
                                            onChange={this.onInputChange} placeholder="Enter Techniques Description" rows="8"> </textarea>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Approaches</label>
                                                    <Select.Async clearable={false} autoload={false}
                                                        cache={false} multi={true}
                                                        value={this.state.approaches}
                                                        onChange={this.onSelectChange.bind(this, "approaches", true)}
                                                        valueKey="id" labelKey="name" isLoading={false}
                                                        loadOptions={loadApproaches} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Modules</label>
                                                    <Select.Async clearable={false} autoload={false}
                                                        cache={false} multi={true}
                                                        value={this.state.modules}
                                                        onChange={this.onSelectChange.bind(this, "modules", true)}
                                                        valueKey="id" labelKey="name" isLoading={false}
                                                        loadOptions={loadModules} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                        </div>
                        <div className="p-4">
                            
                        </div>
    
                        <div className="p-4">
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor bg-white"
                                onEditorStateChange={this.onEditorStateChange}
                            />
                        </div>
                        {!this.props.model.addTechniqueImageUploading &&
                            <div className="p-4">
                                <div className="form-group">
                                    <label>Upload Image</label>
                                    <input type="file" name="uploadedFile" value={this.state.uploadedFile} className="form-control" id="uploadedFile" onChange={(e) => {
                                        this.handleChange(e)
                                    }} ref={(input) => this.fileinput = input} />
                                </div>
                            </div>
                        }

                    <ImageGrid images={this.state.images} onImageRemoveClick={this.onImageRemoveClick} />

                    <div className="p-4">
                            <div className="form-group">
                                <label>Video</label>
                                <input type="text" name="video" value={this.state.video} className="form-control" id="video" 
                                onChange={this.onInputChange} placeholder="Enter Video Url" />
                            </div>
                            <div className="form-group">
                                <button className="btn btn-info" onClick={this.addVideo.bind(this)}> 
                                    Add Video
                                </button>
                            </div>
                        </div>

                    <VideoGrid videos={this.state.videos} onVideoRemoveClick={this.onVideoRemoveClick} />
                        <div className="p-4">
                            <div className="btn-group" role="group" aria-label="">
                                <button className="btn btn-dark mr-2" onClick={this.onSaveButtonClick.bind(this)}> Save </button>
                                <Link to='/' className="btn btn-dark mr-2"> Back to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            );

        } else {
            return <Loader />
        }
    }
}


function mapStateToProps(state) {
    return {
        model: state.store.technique    
    };
}

export default withRouter(connect(mapStateToProps)(ManageTechniques));