import React, { Component } from 'react';
import { withRouter  } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllSeminarCompanies, addSeminar, updateSeminar } from "./../../../actions/seminar";
import { processResponse } from "./../../../actions";
import APIServices from "../../../services";
import * as API from "../../../services/config";
import Select from 'react-select';
import Loader from './../../components/loader';
import {  onInputChange, 
    onSelectChange,
    loadModules, loadApproaches } from './../components/helper';
import { NotificationManager } from 'react-notifications';    

class ManageSeminars extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: -1,
            title: "",
            url: "",
            author: "",
            price : 0,
            description : "",
            seminarCompanyId: 0,
            modules : [],
            approaches : []
        }
        this.onInputChange = this.onInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.resetSeminar = this.resetSeminar.bind(this);
    }

    UNSAFE_componentWillMount() {
      this.props.dispatch(getAllSeminarCompanies());
        if (typeof (this.props.match.params.id) != typeof (undefined) && Number(this.props.match.params.id) > 0) {
            APIServices.getSeminar(this.props.match.params.id)
                .then(processResponse)
                .then(response => {
                  console.log(response);

                        this.setState({
                            ...this.state,
                            id: response.data.seminar.id,
                            title: response.data.seminar.title,
                            url: response.data.seminar.url,
                            author: response.data.seminar.author,
                            price : response.data.seminar.price,
                            description : response.data.seminar.description,
                            seminarCompanyId: response.data.seminar.seminarCompanyId,
                            modules: response.data.modules.map(function name(_module) {
                                return {
                                    id: _module.moduleId,
                                   name : _module.module.name
                                }
                            }),
                            approaches: response.data.approaches.map(function name(_approach) {
                                return {
                                    id: _approach.approachId,
                                   name : _approach.approach.name
                                }
                            })
                        });
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            this.resetSeminar();
        }
    }
    resetSeminar(){
        this.setState({
            id: 0,
            title: "",
            url: "",
            author: "",
            price : 0,
            description : "",
            seminarCompanyId: 0,
            modules : [],
            approaches : []
        });
    }

    onInputChange(event) {
        onInputChange(this, event);
    }


    onSelectChange(name, multi, option) {
        onSelectChange(this, name, multi, option);
    }

    onSaveButtonClick() {
        if (!this.state.title
			|| !this.state.url || Number(this.state.seminarCompanyId) === 0	) {
        NotificationManager.error('Please enter required fields..', 'Error');
        return false;
      }

      if (!this.state.title) {
        NotificationManager.error('Please enter seminar title', 'Error');
        return false;
      }
      if (!this.state.url) {
        NotificationManager.error('Please enter URL', 'Error');
        return false;
      }
      if (this.state.seminarCompanyId == 0) {
        NotificationManager.error('Please select company for seminar', 'Error');
        return false;
      }
      if (!this.state.title
        || !this.state.url || this.state.seminarCompanyId == 0	) {
            NotificationManager.error('Please enter required fields..', 'Error');
            return false;
        }

        let model = {
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                seminar : {
                  title: this.state.title,
                  url: this.state.url,
                  author: this.state.author,
                  price: this.state.price,
                  description: this.state.description,
                  seminarCompanyId: this.state.seminarCompanyId
                },
                modules : this.state.modules.map(function name(_module) {
                  return {
                      moduleId : _module.id
                  }
              }),
                approaches : this.state.approaches.map(function name(approach) {
                    return {
                        approachId: approach.id
                    }
                })
            }
        }

        if(Number(this.state.id) > 0){
            model = {
                ...model,
                data : {
                    ...model.data,
                    seminar: {
                        ...model.data.seminar,
                        id: this.state.id
                    }
                    
                }
            }
            this.props.dispatch(updateSeminar(model));
        }else{
            this.props.dispatch(addSeminar(model));
            this.resetSeminar();
        }
        this.props.history.push('/admin/seminars/seminarList');
    }

    handleChange = (seminarCompany) => {
      this.setState({ seminarCompanyId: seminarCompany.value });
    }


    render() {
        let seminarCompanies = [];
        if (this.props.model.seminarCompanies != null && this.props.model.seminarCompanies.length > 0) {
          this.props.model.seminarCompanies.map((element, i) => { 
            if (i == 0 && this.state.seminarCompanyId == 0) {
              this.setState({ seminarCompanyId: element.id });
            }   
            seminarCompanies.push({value: element.id, label: element.name});
          });
        }

        if (this.state.id > -1) {
            return (
                <div>
                    <div>
                        <div className="p-4 text-center h2">
                        { (Number(this.state.id) > 0) ? "Edit" : "Add" } Seminar
                        </div>
                        <div className="p-4">
                            <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label>Title*</label>
                                            <input type="text" name="title" value={this.state.title} className="form-control" id="title" onChange={this.onInputChange} placeholder="Enter Seminar Title" />
                                        </div>
                                        <div className="form-group">
                                            <label>URL*</label>
                                            <input type="text" name="url" value={this.state.url} className="form-control" id="url" onChange={this.onInputChange} placeholder="Enter URL" />
                                        </div>
                                        <div className="form-group">
                                            <label>Price</label>
                                            <input type="number" step=".01"  min="0" name="price" value={this.state.price} className="form-control" id="price" onChange={this.onInputChange} placeholder="Enter Price" />
                                        </div>
                                        <div className="form-group">
                                            <label>Seminar description</label>
                                            <textarea name="description" value={this.state.description} className="form-control" id="description" 
                                            onChange={this.onInputChange} placeholder="Enter Seminar Description" rows="5"> </textarea>
                                        </div>
                                    </div>
                                    <div className="col">
                                    <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Presenter</label>
                                                    <input type="text" name="author" value={this.state.author} className="form-control" id="author" onChange={this.onInputChange} placeholder="Enter Presenter Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Company*</label>
                                                    <Select
                                                      name="seminarCompanyId"
                                                      value={this.state.seminarCompanyId}
                                                      onChange={this.handleChange}
                                                      options={seminarCompanies}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Approaches</label>
                                                    <Select.Async clearable={false} autoload={false}
                                                        cache={false} multi={true}
                                                        value={this.state.approaches}
                                                        onChange={this.onSelectChange.bind(this, "approaches", true)}
                                                        valueKey="id" labelKey="name" isLoading={false}
                                                        loadOptions={loadApproaches} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-group">
                                                    <label>Modules</label>
                                                    <Select.Async clearable={false} autoload={false}
                                                        cache={false} multi={true}
                                                        value={this.state.modules}
                                                        onChange={this.onSelectChange.bind(this, "modules", true)}
                                                        valueKey="id" labelKey="name" isLoading={false}
                                                        loadOptions={loadModules} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                        </div>

                        <div className="p-4">
                            <div className="btn-group" role="group" aria-label="">
                                <button className="btn btn-dark mr-2" onClick={this.onSaveButtonClick.bind(this)}> Save </button>
                                <Link to='/' className="btn btn-dark mr-2"> Back to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            );

        } else {
            return <Loader />
        }
    }
}


function mapStateToProps(state) {
    return {
        model: state.store.seminar    
    };
}

export default withRouter(connect(mapStateToProps)(ManageSeminars));