import {combineReducers} from "redux";
import * as actions from "../actiontypes/modules";

const initialState = {
    isFetching: false,
    firstName: '',
    lastName: '',
    modules:[],
    addModuleImage : "",
    addModuleImageUploading : false,
    module : null
};

export default function Module(state = initialState, action) {
    switch (action.type) {
        case actions.MODULES:
            return {
                ...state,
                modules: action.payload.response
            };
        case actions.UPLOADING_IMAGE_RESET:
            return {
                ...state, 
                addModuleImage : "",
                addModuleImageUploading : false
        };
        case actions.UPLOADING_IMAGE_START:
            return {
                ...state,
                addModuleImageUploading : true
            };
        case actions.UPLOADING_IMAGE_DONE:
            return {
                ...state,
                addModuleImage : window.location.origin + action.payload.response.url,
                addModuleImageUploading : false
            };
        case actions.MODULES_GET:
            return {
                ...state,
                module : action.payload.response.data
            };
        case actions.MODULES_GET_START:
            return {
                ...state,
                module : null
            };
        default:
            return state;
    }
};