export const MODULES = 'MODULES/ALL';
export const MODULES_GET = 'MODULES/GET';
export const MODULES_GET_START =  'MODULES/GET_START';
export const MODULES_GET_DONE = 'MODULES/GET_DONE';
export const UPLOADING_IMAGE_RESET =  'APPROACHES/UPLOADING_IMAGE_RESET';
export const UPLOADING_IMAGE_START = 'APPROACHES/UPLOADING_IMAGE_START';
export const UPLOADING_IMAGE_DONE = 'APPROACHES/UPLOADING_IMAGE_DONE';




