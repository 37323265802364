import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
export default class TermsConditionDialog extends React.Component {

  toggle(bool, e) {
    e.stopPropagation();
    this.props.toggle(bool);
  }
  render() {
    let { isOpen } = this.props;
    const modalBodyStyle = {
      maxHeight: '600px',
      overflow: 'auto'
    };
    return (
      <div>
        <Modal isOpen={isOpen}  className={this.props.className}>
          <ModalHeader>  USER/LICENSE AGREEMENT </ModalHeader>
          <ModalBody style={modalBodyStyle}>
            <div className="py-1 text-left">
                This USER/LICENSE AGREEMENT (the “User/License Agreement”) is entered into by Strategic Psychotherapeutics®, LLC, for access to and use of <span className="text-primary">StratPsych®</span> and the customer who accepts this License Agreement by signing below (“User/Licensee”).
            </div>
            <div className="py-1 text-left">
                In consideration of the mutual promises and covenants contained herein, Strategic Psychotherapeutics®, LLC and User/Licensee agree as follows:
            </div>
            <div  className="py-1 text-left">
                1. <u>User/License.</u>  The user/licensee granted for access and use of <span className="text-primary">StratPsych®</span> shall be for the Trial Period only, unless terminated by Strategic Psychotherapeutics®, LLC pursuant to Section 2.  User/Licensee acknowledges that <span className="text-primary">StratPsych®</span> is licensed to Licensee for sole use only, and that this User/License Agreement does not transfer title to the Platform/Software to User/Licensee, but only the limited rights to use the Designated Product for the user during the Trial Term.  Strategic Psychotherapeutics®, LLC retains all rights, title and interest in and to the Platform/Software, including without limitation all proprietary rights, copyrights, patents, trademarks, content, trade secrets and confidential information rights associated therewith.  Licensee agrees not to copy, decompile, disassemble, reverse engineer or otherwise attempt to discover the source code for the Software, in whole or in part.  Further, Licensee agrees not to transfer, assign or disclose the Software to any third parties without Strategic Psychotherapeutics® LLC’s prior written consent.
            </div>
            <div  className="py-1 text-left">
                2. <u>Delivery; Acceptance.</u>  Strategic Psychotherapeutics®, LLC shall provide username and password to access StratPsych®, the on-line resource for psychotherapists. Acceptance of access to StratPsych® shall be deemed to occur upon completion of this user agreement (“Acceptance”).
            </div>
            <div  className="py-1 text-left">
                3. <u>Limited Platform/Software Warranty.</u>  Strategic Psychotherapeutics®, LLC warrants that, for the sole benefit of User/Licensee, for a period of 6 (six) months after Acceptance, the Software will operate in substantial conformance to the specifications.  Strategic Psychotherapeutics, LLC DOES NOT WARRANT THAT THE PLATFORM WILL OPERATE UNINTERRUPTED OR ERROR FREE. Content may be added or modified during the course of this agreement. Strategic Psychotherapeutic®, LLC’s sole obligation, and User/Licensee’s exclusive remedy under this warranty, shall be, at Strategic Psychotherapeutic®, LLC’s option and expense, to use commercially reasonable efforts to correct any non-conformity or errors reported to Strategic Psychotherapeutic®, LLC by telephone or e-mail so that the Platform operates in substantial compliance to the specifications or to remedy non-conforming aspects. Strategic Psychotherapeutics®, LLC shall have no obligation if User/Licensee or any third party has performed any maintenance or modification to Platform or Platform has been misused or damaged.   EXCEPT FOR THE FOREGOING WARRANTY, Strategic Psychotherapeutics®, LLC MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
            </div>
            <div  className="py-1 text-left">
                4. <u>License Termination.</u>  In the event User/Licensee breaches any of its obligations under this License/User Agreement, Strategic Psychotherapeutics®, LLC shall have the right to immediately terminate User/Licensee’s license and access to and use of the <span className="text-primary">StratPsych®</span>. In such event, User/Licensee shall immediately terminate all such use of the Platform and permit Strategic Psychotherapeutics®, LLC or its authorized agent to prevent the User/License from accessing <span className="text-primary">StratPsych®</span>.  Further, in the event of any breach of User/Licensee’s obligations under Section 2 above, Licensee acknowledges that Strategic Psychotherapeutics®, LLC will suffer immediate and irreparable harm for which monetary damages alone would not be sufficient and  will be Strategic Psychotherapeutics®, LLC entitled to seek immediate injunctive relief to protect its interests.
            </div>
            <div  className="py-1 text-left">
                5. <u>Product Support.</u>  Strategic Psychotherapeutics®, LLC will provide technical support and maintenance for StratPsych® until the expiration of the warranty period to Licensee pursuant to the terms and conditions.
            </div>
            <div  className="py-1 text-left">
                6. <u>Indemnity.</u>  Strategic Psychotherapeutics®, LLC shall, at its own expense, indemnify, hold harmless and defend User/Licensee against any claim, suit or proceeding brought against Licensee asserting that the Software or Licensee’s use thereof constitutes an infringement of any patent, trademark or copyright, provided Licensee promptly notifies Strategic Psychotherapeutics®, LLC such claim and gives Strategic Psychotherapeutics®, LLC full and complete authority and support to properly defend such claim.
            </div>
            <div  className="py-1 text-left">
                7. <u>Limitation of Liability.</u>  IN NO EVENT SHALL Strategic Psychotherapeutics®, LLC BE LIABLE TO USER/LICENSEE FOR ANY SPECIAL, PUNITIVE, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF DATA, LOSS OR REVENUES OR PROFITS, OR INTERRUPTION OF BUSINESS, EVEN IF Strategic Psychotherapeutics, LLC HAS BEEN  INFORMED OF THE POSSIBILITY OF SUCH DAMAGES.  THE USER ACKNOWLEGES THAT THE USE OF <span className="text-primary">StratPsych®</span> DOES NOT SERVE AS CLINICAL SUPERVISION OR CONSULTATION ABOUT SPECIFIC TREATMENT ISSUES OR CONCERNS. THIS LIMITATION SHALL APPLY NOTWITHSTANDING ANY FAILURE OF THE ESSENTIAL PURPOSE OF ANY LIMITED REMEDY PROVIDED HEREUNDER. THE USER/LICENSE AGREES THAT USE OF <span className="text-primary">StratPsych ®</span> DOES NOT CONFER EXPERTISE OR IS A SUBSITUTE FOR PSYCHOTHERAPEUTIC EDUCATION, TRAINING, AND SUPERVISION AND USE OF <span className="text-primary">StratPsych®</span> DOES NOT QUALIFY THE USER/LICENSEE AS A PSYCHOTHERPIST. THE USE/LICENSEE ADHERES TO PROFESSIONAL LICENSING AND ETHICAL GUIDELINES OF HIS OR HER PROFESSION AND HOLDS STRATEGIC PSYCHOTHERAPETUIC®, LLC HARMLESS IN ANY CASE OF PROFESSIONAL MALAPRACTICE.
            </div>
            <div  className="py-1 text-left">
                8. <u>Notices.</u>  All notices hereunder shall be in writing and shall be sent by mail or by e-mail (in each case with return confirmation of receipt) to the User/Licensee at its registered address or to Strategic Psychotherapeutics®, LLC at: Strategic Psychotherapeutics®, LLC, Glastonbury Medical Arts Center, 300 Hebron Ave. Suite 215, Glastonbury, CT 06033, Telephone 1-860.659.1202, or via email at <a href="#" className=""><u>strategicpsychotherapeutics@gmail.com</u></a> .
            </div>
            <div  className="py-1 text-left">
                9. <u>Assignment.</u>  This User/License Agreement may not be assigned, transferred or pledged and the Platform licensed hereunder may not be sublicensed, assigned or transferred without the prior written consent of Strategic Psychotherapeutics®, LLC, except that either party may assign this Agreement to its successor by merger or acquisition or to its lines of business or branches, each of whom will assume all responsibilities and obligations hereunder of the assigning party.
            </div>
            <div  className="py-1 text-left">
                10.	<u>Entire Agreement.</u>  This User/License Agreement constitutes the entire agreement between Strategic Psychotherapeutics®, LLC and User/Licensee with respect to the subject matter hereof and supersedes any prior proposals, agreements or representations, oral or written, with respect to the subject matter hereof.  If one of more of the terms herein is held to be unenforceable, then this License Agreement shall be construed as if such unenforceable term(s) have never been contained herein and the balance of the License Agreement shall remain in full force and effect. <small>11/19/19 <span className="font-italic">Strategic Psychotherapeutics®, LLC</span></small>
            </div>
            <div className="pt-2 font-weight-bold text-left">
                USER/LICENSEE ACKNOWLEDGES THAT LICENSEE HAS READ AND UNDERSTOOD THIS LICENSE AGREEMENT IN FULL AND BY SIGNING HAS AGREED TO AND ACCEPTED ITS TERMS AND CONDITIONS. 
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle.bind(this, true)}>Agree</Button>{' '}
            <Link to="/logout" className="btn btn-secondary"> Logout</Link>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
