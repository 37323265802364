import * as actions from '../../actiontypes/user';
import APIServices from '../../services';
import { processResponse } from '../../actions';
import {logout} from './../../actions/auth/index';
import { NotificationManager } from 'react-notifications';

export const initUser = () => {
  return {
    type: actions.ADD_NAME,
    user: {
      firstName : ''
    }
  };
};

export const addUser = (model, history) => {
  return dispatch => APIServices.addUser(model)
    .then(response => {
      return response.json();
    })
    .then(data => {
      if(data.length > 0) {
        const errorMessage = data.map(x=> {
          return x.description;
        }).join('\n');
        NotificationManager.error(errorMessage, 'Error');
      }else{
        history.push('/admin/users/usersList');
        dispatch(getAllUsers());
        NotificationManager.success(`${model.data.userName} added Successfully.`, 'Success');
      }

    })
    .catch(error => {
      // NotificationManager.success('User added Successfully.', 'Success');
    });
};

export const updateUser = (model, history) => {

  return dispatch => APIServices.updateUser(model)
    .then(response => response.json())
    .then(data => {
      if(data.length > 0) {
        const errorMessage = data.map(x=> {
          return x.description;
        }).join('\n');
        NotificationManager.error(errorMessage, 'Error');
      }else{
        history.push('/admin/users/usersList');
        dispatch(getAllUsers());
        NotificationManager.success(`${model.data.userName} updated Successfully.`, 'Success');
      }
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
};

export const successUser = (response) => {
  return {
    type: actions.USER_SUCCESS,
    payload: {
      response: response
    }
  };
};

export const logoutUser = (response) => {
  return {
    type: actions.USER_LOGOUT
  };
};

export const errorUser = (response) => {
  return {
    type: actions.USER_ERROR,
    payload: {
      response: response
    }
  };
};

export function getUserInfo() {
  return dispatch => APIServices.userInfo()
    .then(processResponse)
    .then(response => {
      if(response.status == 200) {
        dispatch(userInfoSuccess(response.data));
      }
    })
    .catch(response => {
      if(response.status == 200) {
        // dispatch(receiveLogin(response.data));
      }else if(response.status == 401) {
        dispatch(logout());
        // dispatch(loginError(response.data.error_description));
      }
    });
}

export const addAgreementTimeStamp = (userName) => {
  return dispatch => APIServices.addAgreementTimeStamp(userName)
    .then(processResponse)
    .then(response => {
      if(response.status === 200) {
        dispatch(getUserInfo());
      }
    })
    .catch(response => {
      if(response.status === 200) {
        // dispatch(receiveLogin(response.data));
      } else if(response.status === 401) {
        dispatch(logout());
      }
    });
};


export const userInfoRequest = (error) => {
  return {
    type: actions.USER_INFO_REQUEST,
    isFetching: false,
    isAuthenticated: false
  };
};

export const userInfoSuccess = (data) => {
  return {
    type: actions.USER_INFO_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
    data
  };
};

export const userInfoError = (error) => {
  return {
    type: actions.USER_INFO_ERROR,
    isFetching: false,
    isAuthenticated: false,
    error
  };
};

export const getAllUsers = () => {
  return dispatch => APIServices.getAllUsers()
    .then(response => response.json())
    .then(response => {
      //console.log(response);
      dispatch(usersReceived(response));
    })
    .catch(err => {
      console.log(err);
    });
};

export const usersReceived = (response) => {
  return {
    type: actions.USERS,
    payload: {
      response: response
    }
  };
};


export const getCustomerSearch = (searchText) => {
  return APIServices.getCustomerSearch(searchText)
    .then(response => response.json())
    .then(response => {
      return {
        options: response.map(function (elem) {
          return { 'name': elem.name, 'id': elem.id };
        })
      };
    })
    .catch(err => {
      return { options: [] };
    });

};


export const deleteUser = (id) => {
  return dispatch => APIServices.deleteUser(id)
    .then(response => response.json())
    .then(data => {
      dispatch(getAllUsers());
      NotificationManager.success('User deleted Successfully..', 'Success');
    })
    .catch(err => {

    });
};



export const validateResetPasswordCode = (code) => {
  return APIServices.validateResetPasswordCode(code)
    .then(response => response.json())
    .then(response => {
      return response;
    })
    .catch(err => {
      return {  };
    });

};

export const sendRecoverPassword = (response) => {
  return {
    type: actions.USER_RECOVERY_PASSWORD,
    payload: {
      response: response
    }
  };
};

export const recoverPassword = (model, history) => {
  return dispatch => APIServices.recoverPassword(model)
    .then(response => response.json())
    .then(response => {
      dispatch(sendRecoverPassword(response));

      if(response.status){
        history.push('/login');
        NotificationManager.success('Request sent successfully..', 'Success');
      }else{
        NotificationManager.error('Please check email address', 'Error');
      }
    })
    .catch(err => {
      NotificationManager.success('Please check email address..', 'Error');
    });

};

export const resetPassword = (model, history) => {
  console.log(model);
  return dispatch => APIServices.resetPassword(model)
    .then(response => response.json())
    .then(response => {
      dispatch(sendRecoverPassword(response));
      if(response.status){
        history.push('/login');
        NotificationManager.success('Password has been updated successfully..', 'Success');
      }else{
        NotificationManager.error(response.message, 'Error');
        if(response.linkExpired){
          history.push('/login');
        }
      }
    })
    .catch(err => {
      NotificationManager.success('Please check email address..', 'Error');
    });

};

export const setchangePassword = (response) => {
  return {
    type: actions.USER_CHANGE_PASSWORD,
    payload: {
    }
  };
};

export const openChangePassword = (response) => {
  return {
    type: actions.USER_OPEN_CHANGE_PASSWORD,
    payload: {
    }
  };
};

export const closeChangePassword = (response) => {
  return {
    type: actions.USER_CLOSE_CHANGE_PASSWORD,
    payload: {
    }
  };
};

export const changePassword = (model) => {
  console.log(model);
  return dispatch => APIServices.changePassword(model)
    .then(response => response.json())
    .then(response => {
      if(response.status){
        dispatch(setchangePassword(response));
        NotificationManager.success('Password has been updated successfully..', 'Success');
      }else{
        NotificationManager.error(response.message, 'Error');
      }
    })
    .catch(err => {
      NotificationManager.success('Please check email address..', 'Error');
    });

};