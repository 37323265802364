import React, { Component, PropTypes } from 'react';
import { BrowserRouter as Router, Route, Link, hashHistory, Switch } from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { getAllTechniques } from "./../../../actions/techniques";
import TechniquesList  from './techniquesList';
import ManageTechniques from './manageTechniques.js'
import TechniquesHeader from './techniquesHeader';


class Techniques extends React.Component {
    constructor(props) {
        super(props);
        
      }

    UNSAFE_componentWillMount(){
        this.props.dispatch(getAllTechniques());  
    }

    render() {
          return (<div className="technique">
                    <Switch>
                            <Route component={TechniquesList} path="/admin/techniques/techniquesList"></Route>
                            <Route component={ManageTechniques} path="/admin/techniques/addTechniques"></Route>
                            <Route path="/admin/techniques/EditTechniques/:id" render={routeProps => 
                            <ManageTechniques technique={this.props.technique} {...routeProps} />} ></Route>
                            <Route component={TechniquesHeader}/>
                    </Switch>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state,
        technique: state.store.technique
    };
}

export default withRouter(connect(mapStateToProps)(Techniques));