import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addSegment, updateSegment } from "./../../../actions/segments";
import ImageGrid from "./../components/imageGrid";
import VideoGrid from "./../components/videoGrid";
import { processResponse } from "./../../../actions";
import APIServices from "../../../services";
import * as API from "../../../services/config";
import Loader from './../../components/loader';
import {
    onInputChange, addVideo, getDescription, onSelectChange,
    onImageRemoveClick, onVideoRemoveClick,
} from './../components/helper';

class ManageSegment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: -1,
            description: "",
            content: "",
            name: "",
            images: [],
            videos: [],
        }
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onImageRemoveClick = this.onImageRemoveClick.bind(this);
        this.onVideoRemoveClick = this.onVideoRemoveClick.bind(this);

        this.resetSegment = this.resetSegment.bind(this);
        this.initSegment = this.initSegment.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.initSegment(this.props.match.params.segmentId);
    }

    onEditorStateChange(editorState) {
        this.setState({
            editorState
        });
    };

    initSegment(segmentId){
        if (typeof (segmentId) != typeof (undefined) && Number(segmentId) > 0) {
            this.setState({
                editorState: EditorState.createEmpty(),
                id: -1,
                name: "",
                description: "",
                content: "",
                images: [],
                videos: []
            }, () => {
                APIServices.getSegment(segmentId)
                .then(processResponse)
                .then(response => {
                    // response.data
                    const contentBlock = htmlToDraft(response.data.content);
                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        const editorState = EditorState.createWithContent(contentState);
                        this.setState({
                            ...this.state,
                            editorState,
                            name: response.data.name,
                            description: response.data.description,
                            id: response.data.id,
                            images: response.data.images,
                            videos: response.data.videos,

                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            });
        } else {
            this.resetSegment();
        }
    }
    
    resetSegment() {
        this.setState({
            editorState: EditorState.createEmpty(),
            id: 0,
            name: "",
            description: "",
            content: "",
            images: [],
            videos: []

        });
    }

    onInputChange(event) {
        onInputChange(this, event);
    }

    addVideo(event) {
        addVideo(this, event);
    }

    onSaveButtonClick() {
        console.log(this.props.match.params);
        let model = {
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                name: this.state.name,
                description: this.state.description,
                content: getDescription(this),
                images: this.state.images,
                videos: this.state.videos,
                moduleId: this.props.match.params.moduleId
            }
        }

        if (Number(this.state.id) > 0) {
            model = {
                ...model,
                data: {
                    ...model.data,
                    id: this.state.id
                }
            }
            this.props.dispatch(updateSegment(model));
        } else {
            this.props.dispatch(addSegment(model));
            this.resetSegment();
        }
    }

    handleChange(e) {
        let model = {
            file: e.target.files[0]
        };
        let me = this;
        APIServices.uploadImage(model).then(response => response.json())
            .then(data => {
                let images = this.state.images;
                images.push({ url: API.ROOT + data.url, name: data.fileName, id: 0, segmentId : this.state.id});
                this.setState({
                    images: images
                });
            })
            .catch(err => {
                console.log(err);
            })

    }

    onImageRemoveClick(index) {
        onImageRemoveClick(this, index);
    }

    onVideoRemoveClick(index) {
        onVideoRemoveClick(this, index);
    }

    onSelectChange(name, multi, option) {
        onSelectChange(this, name, multi, option);
    }
    UNSAFE_componentWillReceiveProps(nextProps){
        //call your api and update state with new props
        if(this.props.match.params.segmentId != nextProps.match.params.segmentId){
            this.initSegment(nextProps.match.params.segmentId);
        }
    }

    render() {
        const { editorState } = this.state;
        if (this.state.id > -1) {
            return (
                <div>
                    <div>
                        <div className="p-4 text-center h2">
                            {/* {(Number(this.state.id) > 0) ? "Edit" : "Add"} segment */}

                            {this.state.name}
                        </div>
                        <div className="p-4">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label for="name">Segment Header</label>
                                        <input type="text" name="name" value={this.state.name} className="form-control" id="name" onChange={this.onInputChange} placeholder="Enter segment Header" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label for="name">Description</label>
                                <textarea name="description" value={this.state.description} className="form-control" id="name"
                                    onChange={this.onInputChange} placeholder="Enter Segment Description" rows="8"> </textarea>
                            </div>
                        </div>

                        <div className="p-4">
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor bg-white"
                                onEditorStateChange={this.onEditorStateChange}
                            />
                        </div>

                        {!this.props.model.addSegmentImageUploading &&
                            <div className="p-4">
                                <div className="form-group">
                                    <label for="primaryImage">Upload Image</label>
                                    <input type="file" name="uploadedFile" value={this.state.uploadedFile} className="form-control" id="uploadedFile" onChange={(e) => {
                                        this.handleChange(e)
                                    }} ref={(input) => this.fileinput = input} />
                                </div>
                            </div>
                        }

                        <ImageGrid images={this.state.images} onImageRemoveClick={this.onImageRemoveClick} />

                        <div className="p-4">
                            <div className="form-group">
                                <label for="primaryImage">Video</label>
                                <input type="text" name="video" value={this.state.video} className="form-control" id="video"
                                    onChange={this.onInputChange} placeholder="Enter Video Url" />
                            </div>
                            <div className="form-group">
                                <button className="btn btn-info" onClick={this.addVideo.bind(this)}>
                                    Add Video
                                </button>
                            </div>
                        </div>

                        <VideoGrid videos={this.state.videos} onVideoRemoveClick={this.onVideoRemoveClick} />
                        <div className="p-4">
                            <div className="btn-group" role="group" aria-label="">
                                <button className="btn btn-dark mr-2" onClick={this.onSaveButtonClick.bind(this)}> Save </button>
                                {/* <Link to='/' className="btn btn-dark mr-2"> Back to Home</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Loader />
        }
    }
}


function mapStateToProps(state) {
    return {
        model: state.store.segment
    };
}

export default connect(mapStateToProps)(ManageSegment);