import {combineReducers} from "redux";
import * as actions from "../actiontypes/video";

const initialState = {
    initDone : false,
    isFetching: false,
    hasError: false,
    error : "",
    videos : []
};

export default function Auth(state = initialState, action) {
    switch (action.type) {

        case actions.VIDEOS:
            var videos = [];

            if(action.payload.response.length > 0){
                for (let index = 0; index < action.payload.response.length; index++) {
                    var element = action.payload.response[index];
                    element = {
                        ...element, 
                        no: (index+1)
                    }
                    videos.push(element);
                    
                }
            }

            return {
                ...state,
                initDone: true,
                videos : videos
            };
        default:
            return state;
    }
};