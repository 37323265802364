import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addVideo } from './../../../actions/video';
import { NotificationManager } from 'react-notifications';
import Loader from './../../components/loader';

class ManageVideo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      poster: null,
      isLoader: false,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.changeHandlerPoster = this.changeHandlerPoster.bind(this);
  }

  onSaveButtonClick() {
    if (!this.state.file) {
      NotificationManager.error('Please add video...', 'Error');
      return false;
    }

    if (!this.state.poster) {
      NotificationManager.error('Please add poster...', 'Error');
      return false;
    }
    var data = new FormData();
    data.append('file', this.state.file);
    data.append('poster', this.state.poster);

    let model = {
      headers: {
      },
      data: data
    };
    this.props.dispatch(addVideo(model, this.props.history));
    this.setState({
      isLoader: true
    });
  }

  changeHandler = (event) => {
    this.setState({ file: event.target.files[0] });
	};

  changeHandlerPoster = (event) => {
    this.setState({ poster: event.target.files[0] });
	};

  UNSAFE_componentWillReceiveProps(nextProps) {
    //call your api and update state with new props
    //this.initModule(this.props.match.params.moduleId);

  }

  render() {
    if(this.state.isLoader){
      return <div className='video-loader'>
            <Loader /> 
      </div>
    }
    return (
      <div>
        <div className="">
          <div className="p-2 text-center h2">
            Add Video
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="p-2">
                <div className="form-group">
                  <label> Video*</label>
                  <input type="file" name="file" onChange={this.changeHandler} className="form-control" id="file" placeholder="Video" />
                </div>
                <div className="form-group">
                  <label> Poster*</label>
                  <input type="file" name="poster" onChange={this.changeHandlerPoster} className="form-control" id="poster" placeholder="Poster" />
                </div>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="btn-group" role="group" aria-label="">
              <button className="btn btn-dark mr-2" onClick={this.onSaveButtonClick.bind(this)}> Save </button>
              <Link to="/admin/videos/videoList" className="btn btn-dark mr-2"> Back to Video List</Link>
            </div>
          </div>
        </div>
      </div >
    );
    
  }
}


function mapStateToProps(state) {
  return {
    model: state.store.customer
  };
}

export default withRouter(connect(mapStateToProps)(ManageVideo));