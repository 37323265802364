export const ROOT                   = window.location.origin;
export const API_ROOT               = ROOT + "/api/";

export const AUTH_LOGIN             = API_ROOT + "connect/token";
export const AUTH_LOGOUT            = API_ROOT + "connect/logout";
export const AUTH_LOGIN_USERINFO    = API_ROOT + "connect/userinfo";
export const AUTH_LOGIN_AGREEMENT_TIMESTAMP = API_ROOT + "connect/AgreementTimeStamp/";

export const API_UPLOAD_IMAGE       = API_ROOT + "Image/Post/Upload";
export const SLIDER_DATA            = API_ROOT + "Relation/SliderData";
export const NAMES_OF_MODULES_TECHNIQUES_APPROACHES = API_ROOT + "Relation/ModulesTechniquesApproachesName";

export const API_APPROACH_ALL       = API_ROOT + "Approach/All";
export const API_APPROACH_ALL_WITHDETAILS = API_ROOT + "Approach/AllApproaches";
export const API_APPROACH_ADD       = API_ROOT + "Approach/Post";
export const API_APPROACH_GET       = API_ROOT + "Approach/Get/";
export const API_APPROACH_UPDATE    = API_ROOT + "Approach/Update";
export const API_APPROACH_DELETE    = API_ROOT + "Approach/Delete/";
export const API_APPROACH_SEARCH    = API_ROOT + "Relation/Approach/";

export const API_MODULE_ALL         = API_ROOT + "Module/All";
export const API_MODULE_ADD         = API_ROOT + "Module/Post";
export const API_MODULE_GET         = API_ROOT + "Module/Get/";
export const API_MODULE_UPDATE      = API_ROOT + "Module/Update";
export const API_MODULE_DELETE      = API_ROOT + "Module/Delete/";
export const API_MODULE_SEARCH      = API_ROOT + "Relation/Module/";

export const API_POSTTEST_ALL         = API_ROOT + "PostTest/All";
export const API_POSTTEST_ADD         = API_ROOT + "PostTest/Post";
export const API_POSTTEST_GET         = API_ROOT + "PostTest/Get/";
export const API_POSTTEST_UPDATE      = API_ROOT + "PostTest/Update";
export const API_POSTTEST_DELETE      = API_ROOT + "PostTest/Delete/";
export const API_POSTTEST_BY_MODULE = API_ROOT + "PostTest/GetQuestionsByModule/";
export const API_POSTTEST_GetPostTestUserFeedBackReportQuestions = API_ROOT + "PostTest/GetPostTestUserFeedBackReportQuestions/";
export const API_POSTTEST_FEEDBACK_ADD = API_ROOT + "PostTest/PostTestUserFeedBack/Post";
export const API_POSTTEST_REPORT      = API_ROOT + "PostTest/GetPostTestUserFeedBackReport";



export const API_TECHNIQUE_ALL      = API_ROOT + "Techniques/All";
export const API_TECHNIQUE_ALL_WITH_DETAILS      = API_ROOT + "Techniques/AllTechniques";
export const API_TECHNIQUE_ADD      = API_ROOT + "Techniques/Post";
export const API_TECHNIQUE_GET      = API_ROOT + "Techniques/Get/";
export const API_TECHNIQUE_UPDATE   = API_ROOT + "Techniques/Update";
export const API_TECHNIQUE_DELETE   = API_ROOT + "Techniques/Delete/";
export const API_TECHNIQUE_SEARCH   = API_ROOT + "Relation/Technique/";

export const API_SEMINAR_COMPANY_ALL   =  API_ROOT + "Seminar/SeminarCompany/All";
export const API_SEMINAR_COMPANY_DELETE = API_ROOT + "Seminar/SeminarCompany/Delete/";
export const API_SEMINAR_COMPANY_ADD  = API_ROOT + "Seminar/SeminarCompany/Post";
export const API_SEMINAR_COMPANY_UPDATE = API_ROOT + "Seminar/SeminarCompany/Update";
export const API_SEMINAR_COMPANY_GET = API_ROOT + "Seminar/SeminarCompany/Get/";

export const API_SEMINAR_ALL   =  API_ROOT + "Seminar/All";
export const API_SEMINAR_DELETE = API_ROOT + "Seminar/Delete/";
export const API_SEMINAR_ADD  = API_ROOT + "Seminar/Post";
export const API_SEMINAR_UPDATE = API_ROOT + "Seminar/Update";
export const API_SEMINAR_GET = API_ROOT + "Seminar/Get/";

export const API_SEGMENT_ALL         = API_ROOT + "Segment/All";
export const API_SEGMENT_ADD         = API_ROOT + "Segment/Post";
export const API_SEGMENT_GET         = API_ROOT + "Segment/Get/";
export const API_SEGMENT_UPDATE      = API_ROOT + "Segment/Update";
export const API_SEGMENT_DELETE      = API_ROOT + "Segment/Delete/";

export const API_CUSTOMER_ALL       = API_ROOT + "Customer/All";
export const API_CUSTOMER_ADD       = API_ROOT + "Customer/Post";
export const API_CUSTOMER_GET       = API_ROOT + "Customer/Get?id=";
export const API_CUSTOMER_UPDATE    = API_ROOT + "Customer/Update";
export const API_CUSTOMER_DELETE    = API_ROOT + "Customer/Delete/";
export const API_CUSTOMER_SEARCH   = API_ROOT + "Relation/Customer/";


export const API_USER_ALL       = API_ROOT + "User/All";
export const API_USER_ADD       = API_ROOT + "User/Post";
export const API_USER_GET       = API_ROOT + "User/Get?id=";
export const API_USER_UPDATE    = API_ROOT + "User/Update";
export const API_USER_DELETE    = API_ROOT + "User/Delete/";
export const API_USER_DASHBOARD    = API_ROOT + "User/GetDashboardData";
export const API_USER_VALIDATE_RESET_PASSWORD_TOKEN    = API_ROOT + "User/ValidateResetPasswordCode";
export const API_USER_RECOVER_PASSWORD    = API_ROOT + "User/RecoverPassword";
export const API_USER_RESET_PASSWORD    = API_ROOT + "User/ResetPassword";
export const API_USER_CHANGE_PASSWORD    = API_ROOT + "User/ChangePassword";



export const API_VIDEO_ALL            = API_ROOT + "S3Video/files/GetAllFiles";
export const API_VIDEO_ADD            = API_ROOT + "S3Video/files/UploadFile";
export const API_VIDEO_POSTER_ADD     = API_ROOT + "S3Video/files/UploadFilePoster";
export const API_VIDEO_DELETE         = API_ROOT + "S3Video/files/DeleteFile/";
export const API_VIDEO_POSTER_DELETE  = API_ROOT + "S3Video/files/DeleteFilePoster";
