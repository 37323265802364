import React, { Component, PropTypes } from 'react';
import { BrowserRouter as Router, Route, Link, hashHistory, Switch } from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { getAllSeminarCompanies } from "./../../../actions/seminar";
import CompaniesList  from './companiesList';
import ManageCompanies from './manageCompanies';
import CompaniesHeader from './companiesHeader';


class SeminarCompanies extends React.Component {
    constructor(props) {
        super(props);
        
      }

    UNSAFE_componentWillMount(){
        this.props.dispatch(getAllSeminarCompanies());  
    }

    render() {
          return (<div className="seminarCompany">
                    <Switch>
                            <Route component={CompaniesList} path="/admin/companies/companyList"></Route>
                            <Route component={ManageCompanies} path="/admin/companies/addCompany"></Route>
                            <Route path="/admin/companies/EditCompany/:id" render={routeProps => 
                            <ManageCompanies seminarCompany={this.props.seminar} {...routeProps} />} ></Route>
                            <Route component={CompaniesHeader}/>
                    </Switch>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state,
        seminar: state.store.seminar
    };
}

export default withRouter(connect(mapStateToProps)(SeminarCompanies));