import React, {  } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import ModuleList from './moduleList';
import ManageModule from './manageModule.js'
import PostTestList from './postTestList';
import ManagePostTest from './managePostTest';


class Module extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (<div className="module">
            <Switch>
                <Route component={ModuleList} path="/admin/modules/moduleList"></Route>
                <Route component={ManageModule} path="/admin/modules/addModule"></Route>
                <Route path="/admin/modules/editModule/:moduleId/postTest/:postTestId" render={routeProps => <ManagePostTest {...routeProps} />} ></Route>
                <Route path="/admin/modules/editModule/:moduleId/postTestQuestionList" render={routeProps => <PostTestList {...routeProps} />} ></Route>
                <Route path="/admin/modules/EditModule/:moduleId/segment/:segmentId" render={routeProps => <ManageModule module={this.props.module} {...routeProps} />} ></Route>
                <Route path="/admin/modules/EditModule/:moduleId" render={routeProps => <ManageModule module={this.props.module} {...routeProps} />} ></Route>
            </Switch>
        </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state,
        module: state.store.module
    };
}

export default connect(mapStateToProps)(Module);