import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllSegments } from "./../../../actions/segments";
import SearchPanel from "./../components/searchPanel";
import { deleteSegment } from "./../../../actions/segments";

class SegmentList extends React.Component {
  constructor(props) {
    super(props);
    this.DeletebuttonClick = this.DeletebuttonClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(getAllSegments());
  }


  onToggleDropDown = (toggleDropDown) => {
    toggleDropDown();
  }

  renderSizePerPageDropDown = (props) => {
    return (
      // eslint-disable-next-line react/jsx-no-undef
      <SizePerPageDropDown
        className='my-size-per-page'
        btnContextual='btn-info'
        variation='dropup'
        onClick={() => this.onToggleDropDown(props.toggleDropDown)} />
    );
  }


  renderCustomClearSearch = (onClick) => {
    return (
      <button
        className='ml-2 btn btn-success'
        onClick={onClick}>
        clear
          </button>
    );
  }


  createCustomExportCSVButton = (onClick) => {
    return (
      <button className="btn btn-info" onClick={onClick}>Custom Export CSV Btn</button>
    );
  }

  renderShowsTotal(start, to, total) {
    return (
      <p style={{ color: 'blue' }}>
        From {start} to {to}, totals is {total}&nbsp;&nbsp;(its a customize text)
          </p>
    );
  }
  filterType(cell, row) {
    // just return type for filtering or searching.
    return cell.type;
  }

  DeletebuttonClick(id, row, rowIndex) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to delete segment ?")) {
      console.log("id-", id);
      this.props.dispatch(deleteSegment(id));
    }
  }
  cellButton(cell, row, enumObject, rowIndex) {
    return (
      <div>
        <Link to={"/admin/segments/EditSegment/" + cell} className="btn btn-info"> Edit </Link>
        <Link to={"#"} className="btn btn-danger" onClick={() =>
          this.DeletebuttonClick(cell, row, rowIndex)}> Delete </Link>
      </div>
    )
  }

  render() {
    const options = {
      page: 1,  // which page you want to show as default
      sizePerPageList: [{
		text: '25', value: 25
	  }, {
		text: '50', value: 50
		}, {
      text: 'All', value: this.props.model.segments.length
      }], // you can change the dropdown list for size per page
	  sizePerPage: this.props.model.segments.length,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      //paginationPosition: 'top'  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
      exportCSVBtn: this.createCustomExportCSVButton,
      clearSearch: true,
      clearSearchBtn: this.renderCustomClearSearch,
      searchPanel: (props) => (<SearchPanel { ...props } />),
      sizePerPageDropDown: this.renderSizePerPageDropDown
    };


    if (this.props.model.segments.length) {
      return (<div>
        <div className="p-3">
          <Link to='/' className="btn btn-dark mr-2"> Back to Home</Link>
        </div>
        <div className="p-3">
          <BootstrapTable data={this.props.model.segments} exportCSV search striped hover info version='4' className="thead-inverse" options={options} pagination>
            <TableHeaderColumn isKey dataField='id' dataSort caretRender={this.getCaret}>#</TableHeaderColumn>
            <TableHeaderColumn dataField='name' dataSort caretRender={this.getCaret}>Header</TableHeaderColumn>
            <TableHeaderColumn
              dataField='id'
              dataFormat={this.cellButton.bind(this)} >
              Actions</TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
      );
    } else {
      return (<div>
        <div className="p-3 text-center ">
          Loading ...
                        </div>
      </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    model: state.store.segment
  };
}

export default connect(mapStateToProps)(SegmentList);