import React from 'react';
import { processResponse } from "./../../../actions";
import { Link } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { connect } from 'react-redux';
import { addPosttest, updatePosttest } from "./../../../actions/posttest";
import { onInputChange } from './../components/helper';
import APIServices from "../../../services";
import Loader from './../../components/loader';
import * as API from "../../../services/config";
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';

class ManagePostTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            question: "",
            options: [],
            rightAnswer: 0
        }
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputQuestionChange = this.onInputQuestionChange.bind(this);
        this.initPosttest = this.initPosttest.bind(this);
        this.resetPosttest = this.resetPosttest.bind(this);
        this.loadAnswers = this.loadAnswers.bind(this);
    }
    UNSAFE_componentWillMount() {
        this.initPosttest(this.props.match.params.postTestId);
    }
    handleChange = (rightAnswer) => {
        this.setState({
            ...this.state,
            rightAnswer: rightAnswer.value
          });
    }
    initPosttest(postTestId) {
        if (typeof (postTestId) != typeof (undefined) && Number(postTestId) > 0) {
            APIServices.getPosttest(postTestId)
                .then(processResponse)
                .then(response => {
                    this.setState({
                        ...this.state,
                        question: response.data.name,
                        options: response.data.answers,
                        rightAnswer: response.data.rightAnswer,
                    });

                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            this.resetPosttest();
        }
    }

    resetPosttest() {
        this.setState({
            question: "",
            options: [{ answer: "", postTestQuestionId: 0, id: 0 },
            { answer: "", postTestQuestionId: 0, id: 0 },
            { answer: "", postTestQuestionId: 0, id: 0 },
            { answer: "", postTestQuestionId: 0, id: 0 }]
        });
    }

    onInputChange(event) {

        let me = this;
        let object = me.state.options;

        object[event.target.dataset.index].answer = event.target.value;
        me.setState({
            ...me.state,
            options: object
        }, () => {

        });
    }

    onInputQuestionChange(event) {
        onInputChange(this, event);
    }


    onSaveButtonClick() {
        let model = {
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                name: this.state.question,
                answers: this.state.options,
                rightAnswer: this.state.rightAnswer,
                moduleId: this.props.match.params.moduleId
            },
        }
        if (Number(this.props.match.params.postTestId) > 0) {
            model = {
                ...model,
                data: {
                    ...model.data,
                    id: this.props.match.params.postTestId
                }
            }
        
            APIServices.updatePosttest(model)
            .then(processResponse)
            .then(response => {
                // redirect user to 
                NotificationManager.success('Test Question updated Successfully.', 'Success');
                this.props.history.push("/admin/modules/editmodule/"+ this.props.match.params.moduleId + "/postTestQuestionList");
            })
            .catch(err => {
                console.log(err);
            })

        } else {

            APIServices.addPosttest(model)
            .then(processResponse)
            .then(response => {
                // redirect user to 
                NotificationManager.success('Test Question added Successfully.', 'Success');
                this.props.history.push("/admin/modules/editmodule/"+ this.props.match.params.moduleId + "/postTestQuestionList");
            })
            .catch(err => {
                console.log(err);
            })

            this.resetPosttest();
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        //call your api and update state with new props
        if (this.props.match.params.postTestId != nextProps.match.params.postTestId) {
            this.initPosttest(nextProps.match.params.postTestId);
        }
    }

    loadAnswers() {
        let me = this;
        return me.state.options.map(function name(answer, i) {
            return <div className="form-group">
                <label for={"answer.[" + i + "]"}>Answer {Number(i + 1)}</label>
                <input name={"answer.[" + i + "]"} value={answer.answer}
                    className="form-control" data-index={i} id={"answer.[" + i + "]"}
                    onChange={me.onInputChange}
                    placeholder="" />
            </div>
        })
    }

    

    render() {
        const options = [
            { value: 1, label: 'Answer 1' },
            { value: 2, label: 'Answer 2' },
            { value: 3, label: 'Answer 3' },
            { value: 4, label: 'Answer 4' }
          ];

        if (this.props.match.params.postTestId > -1) {
            const { rightAnswer } = this.state;

            return (
                <div>
                    <div className="p-4">
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label for="name">PostTest question</label>
                                    <input type="text" name="question" value={this.state.question} className="form-control" id="question" onChange={this.onInputQuestionChange} placeholder="Enter PostTest Question" />
                                </div>
                            </div>
                        </div>
                        {this.loadAnswers()}
                        <div className="form-group">
                            <label>Correct Answer</label>
                            <Select
                            value={rightAnswer}
                            onChange={this.handleChange}
                            options={options}
                        />
                        </div>
                    </div>
                    <div className="p-4">
                        <div className="btn-group" role="group" aria-label="">
                            <button className="btn btn-dark mr-2" onClick={this.onSaveButtonClick.bind(this)}> Save </button>
                            <Link to={"/admin/modules/editModule/" + this.props.match.params.moduleId + "/postTestQuestionList"} className="btn btn-dark mr-2"> Back to Question List</Link>
                            <Link to={"/admin/modules/editModule/" + this.props.match.params.moduleId} className="btn btn-dark mr-2"> Back to Module</Link>
                        </div>
                    </div>
                </div >

            );
        }
        else {
            return <Loader />
        }
    }
}


function mapStateToProps(state) {
    return {
        model: state.store.posttest
    };
}

export default connect(mapStateToProps)(ManagePostTest);
