import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Header extends React.Component {

  UNSAFE_componentWillMount() {
  }

  render() {
    return (
      <nav className="navbar navbar-expand navbar-dark bg-primary">
        <a className="navbar-brand" href="/"><i className="fa fa-code-branch" /> StratPsych <sup>&reg;</sup> </a>
        <a className="sidebar-toggle text-light mr-3">
          <i className="fa fa-align-justify" />
        </a>
        <div className="navbar-collapse collapse">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown">
                {!!this.props.user.user.name && <span>
                  {this.props.user.user.name}
                </span>}
              </a>
               
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                <Link className="dropdown-item" to='/logout'> Logout </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>

    );
  }
}


function mapStateToProps(state) {
  return {
    user: state.store.user
  };
}

export default connect(mapStateToProps)(Header);