import * as actions from "../../actiontypes/user";
import * as authActions from "../../actiontypes/auth";
import APIServices from "../../services";
// import CALL_API from "../../services/api";
import * as API from "../../services/config";
import { processResponse } from "../../actions";
import Auth from './../../app/login/auth';
import {NotificationManager} from 'react-notifications';

export const requestLogin = (creds) => {
    return {
        type: authActions.AUTH_LOGIN_REQUEST,
        isFetching: true,
        isAuthenticated: false,
        creds
    }
}

export const receiveLogin = (token) => {
    return {
        type: authActions.AUTH_LOGIN_SUCCESS,
        isFetching: false,
        isAuthenticated: true,
        token
    }
}

export const loginError = (error) => {
    return {
        type: authActions.AUTH_LOGIN_ERROR,
        isFetching: false,
        isAuthenticated: false,
        error
    }
}

export const logout = () => {
    Auth.logOut();
    return {
        type: authActions.AUTH_LOGOUT,
        isFetching: false,
        isAuthenticated: false
    }
}

export const login = (model) => {

  // let config = {
  //   body : `username=${model.username}&password=${String(model.password)}&grant_type=${model.grant_type}&scope=${model.scope}`
  // };

  var config = [];
  for (var property in model) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(model[property]);
    config.push(encodedKey + "=" + encodedValue);
  }
  config = config.join("&");

  return dispatch => APIServices.login(config)
    .then(processResponse)
    .then(response => {
      if(response.status == 200){
        dispatch(receiveLogin(response.data));
        NotificationManager.success('You are logged in successfully', 'Welcome..');
      }
    })
    .catch(response => {
      if(response.status == 200){
        dispatch(receiveLogin(response.data));
      }else if(response.status == 400){
        dispatch(loginError(response.data.error_description));
      }
    });
};

export function getUserInfo() {
  return dispatch => APIServices.userInfo()
    .then(processResponse)
    .then(response => {
      console.log(response);
      if(response.status === 200){
        // dispatch(receiveLogin(response.data));
      }
    })
    .catch(response => {
      console.log(response);
      if(response.status === 200){
        // dispatch(receiveLogin(response.data));
      }else if(response.status === 400){
        // dispatch(loginError(response.data.error_description));
      }
    });
}


export const userInfoRequest = (error) => {
  return {
    type: actions.USER_INFO_REQUEST,
    isFetching: false,
    isAuthenticated: false
  };
};

export const userInfoSuccess = (error) => {
  return {
    type: actions.USER_INFO_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
    error
  };
};

export const userInfoError = (error) => {
  return {
    type: actions.USER_INFO_ERROR,
    isFetching: false,
    isAuthenticated: false,
    error
  };
};
