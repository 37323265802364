import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { login } from './../../actions/auth';
import { connect } from 'react-redux';
import Auth from './auth';
import { getUserInfo } from './../../actions/user';
import { Link } from 'react-router-dom';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: ''
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
  }
  UNSAFE_componentWillMount() {
    Auth.logOut();
  }
  componentDidMount() {
    Auth.logOut();
  }

  onInputChange(event) {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value
    }, () => {
      // callbacks
    });
  }

  onSaveButtonClick() {
    const model = {
      username: this.state.username,
      password: encodeURI(this.state.password),
      grant_type: 'password',
      scope: 'offline_access profile email roles'
    };
    this.props.dispatch(login(model));
    localStorage.setItem('redirect', true);
  }
  render() {

    if (Auth.IsAuthenticated()) {
      this.props.dispatch(getUserInfo());
      return <Redirect push to="/redirect"/>;
    }

    return (

      <div className="login-page">
        <div className="container d-flex align-items-center">
          <div className="form-holder has-shadow">
            <div className="row">
              <div className="col-lg-6">
                <div className="d-flex align-items-center justify-content-center login-logo-box">
                  <div className="content">
                    <div className="logo">
                      <a href="/" >
                        <h1 className="h1 text-white">
                          <strong>
                            <img src={require('./../../assets/images/logo-new.png')} className="img-responsive login-image" /></strong>
                        </h1>
                      </a>
                    </div>
                    <div className="text-info login-logo-text">
                                            The on-line learning center for mental health professionals.</div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 bg-white">
                <div className="form d-flex align-items-center justify-content-center">
                  <div className="content">

                    <div id="login-form" method="post">
                      <div className="form-group">
                        <label className="label-material">User Name</label>
                        <input id="username" name="username"
                          value={this.state.username}
                          onChange={this.onInputChange}
                          required="" type="email" className="form-control" />

                      </div>
                      <div className="form-group">
                        <label className="label-material">Password</label>
                        <input id="password" type="password"
                          value={this.state.password}
                          onChange={this.onInputChange}
                          name="password" required="" className="form-control" />
                      </div>
                      <button id="login" onClick={this.onSaveButtonClick} className="btn btn-primary btn-block">
                                                Login
                      </button>
                    </div>

                    <small className="p-1 text-danger"> { this.props.auth.hasError  && <div> {this.props.auth.error} </div> } </small>
                   
                    <div className="form-group">
                      <div className="text-dark disclaimer">
                            Disclaimer: <strong> StratPsych <sup>&reg;</sup> </strong> is an educational resource and a product of <strong>  Strategic Psychotherapeutics <sup>&reg;</sup>, LLC </strong>  for increasing psychotherapeutic effectiveness but does not serve as clinical supervision. Users must adhere to their professional code of ethics and state laws regarding behavioral and mental health practice. The user agrees that all communication with our experts excludes specific case material and holds <strong> StratPsych <sup>&reg; </sup> </strong>  harmless in patient treatment.
                      </div>
                    </div>
                    <div className="form-group">
                      <Link className="text-info" to={`/recover`}> Forgot Password?</Link> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    auth: state.store.auth
  };
}

export default withRouter(connect(mapStateToProps)(Login));