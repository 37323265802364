import React from 'react';
import { connect } from 'react-redux';
import { getAllModules } from "./../../actions/modules";
import Modalities from "./pages/modalities copy/index"
import './testing-page copy.scss';
import CustomImage from './CustomImage';

class TestingPage2 extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: 'basic',
            searchTerm: '',
            viewMode: 'grid',
          };
        this.maxWords = 40;
    }

    UNSAFE_componentWillMount() {
        this.props.dispatch(getAllModules());
    };

    handleCategoryChange = (event) => {
        this.setState({ selectedCategory: event.target.value });
    };
    
    handleSearchChange = (event) => {
        this.setState({ searchTerm: event.target.value });
    };

    handleViewModeClick = (mode) => {
        this.setState({ viewMode: mode });
    };

    truncateDescription = (description) => {
        const words = description.split(' ');
        const truncatedWords = words.slice(0, this.maxWords);
        const truncatedDescription = truncatedWords.join(' ');
    
        return words.length > this.maxWords
          ? `${truncatedDescription} ...`
          : description;
    };
    
    render() {
        // eslint-disable-next-line no-undef
		FreshWidget.init("", { "queryString": "&widgetType=popup&formTitle=Ask+the+Experts&submitTitle=Ask+Question&submitThanks=Thank+you+for+your+question.++Our+experts+will+review+and+will+follow-up+shortly.&attachFile=no", "utf8": "?", "widgetType": "popup", "buttonType": "text", "buttonText": "Ask the Experts", "buttonColor": "white", "buttonBg": "#0000FF", "alignment": "2", "offset": "235px", "submitThanks": "Thank you for your question.  Our experts will review and will follow-up shortly.", "formHeight": "500px", "url": "https://stratpsychexperts.freshdesk.com" });
        
        const allModules = this.props.model.modules;
        const { selectedCategory, searchTerm, viewMode } = this.state;
        const filteredModules = allModules.filter(module =>
          module.type === selectedCategory &&
          module.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return (
        <div className="learning-new">

            <Modalities />

            <div className="rbt-page-banner-wrapper">

                <div className="rbt-banner-image"></div>

                <div className="rbt-banner-content">
                
                    <div className="rbt-course-top-wrapper mt--40">
                    <div className="mx-5">
                        <div className="row g-5 align-items-center">
                        <div className="col-xl-5 col-lg-12 col-md-12 mb-md-0 mb-sm-3">
                            <div className="rbt-sorting-list d-flex flex-wrap align-items-center">
                            <div className="rbt-short-item switch-layout-container">
                                <ul className="course-switch-layout">
                                    <li className="course-switch-item">
                                        <button 
                                            className={`d-flex align-items-center rbt-grid-view ${viewMode === 'grid' ? 'active' : ''}`} 
                                            title="Grid Layout"
                                            onClick={() => this.handleViewModeClick('grid')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-layout-grid" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /><path d="M14 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" /></svg>                                           
                                            <span className="text">Grid</span>
                                        </button>
                                    </li>
                                    <li className="course-switch-item">
                                        <button 
                                            className={`d-flex align-items-center rbt-list-view ${viewMode === 'list' ? 'active' : ''}`} 
                                            title="List Layout"
                                            onClick={() => this.handleViewModeClick('list')}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-list" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l11 0" /><path d="M9 12l11 0" /><path d="M9 18l11 0" /><path d="M5 6l0 .01" /><path d="M5 12l0 .01" /><path d="M5 18l0 .01" /></svg>
                                            <span className="text">List</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="rbt-short-item">
                                <span className="course-index">Showing {filteredModules.length} results</span>
                            </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-12 col-md-12 mb-3">
                            <div className="rbt-sorting-list d-flex flex-wrap align-items-end justify-content-start justify-content-xl-end">
                            <div className="rbt-short-item">
                                <span className="rbt-search-style me-0">
                                    <input 
                                        type="text" 
                                        id="searchInput"
                                        placeholder="Search Your Module.."
                                        value={searchTerm}
                                        onChange={this.handleSearchChange}
                                    />
                                </span>
                            </div>
                            <div className="rbt-short-item">
                                <div className="filter-select">
                                    <span className="select-label d-block">Select Module Type</span>
                                    <div className="filter-select rbt-modern-select search-by-category">
                                        <select id="custom-select-1" onChange={this.handleCategoryChange} value={selectedCategory}>
                                            <option value="basic">Foundational</option>
                                            <option value="intermediate">Intermediate</option>
                                            <option value="advanced">Advanced</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                </div>
            </div>

            <div className="rbt-section-overlayping-top rbt-section-gapBottom pt-5">
                <div className="mx-5 pt-2">
                    <div className="row">
                            <div className="col-lg-12">
                                <div className={`rbt-course-grid-column ${viewMode === 'grid' ? 'active-grid-view' : 'active-list-view'}`}>
                                    {filteredModules.map((module) => (       
                                        <div key={module.id}  className="course-grid-3">
                                            <div className={`rbt-card variation-01 rbt-hover ${viewMode === 'list' ? 'card-list-2' : ''}`}>
                                                <div className="rbt-card-img">
                                                    <a className="d-flex align-items-center rbt-card-title-link" href={`module/${module.id}`}>
                                                        {module.images.length > 0 ? (
                                                            <img id="custom-image" src={module.images[0].url} alt={module.images[0].name} />
                                                        ) : (
                                                            // <CustomImage altName={module.name} />
                                                            <img id="custom-image" src={require("../../assets/images/logo-new.png")} alt="webinar-image"/>
                                                        )}
                                                    </a>
                                                </div>
                                                <div className="rbt-card-body">                                        
                                                    <h4 className="d-flex align-items-center rbt-card-title custom-h4">
                                                        <a className="rbt-card-title-link" href={`module/${module.id}`}>
                                                            {module.name}
                                                        </a>
                                                    </h4>
                                                    <p className="rbt-card-text custom-p">
                                                        {this.truncateDescription(module.description)}
                                                    </p> 
                                                    <div className="d-flex align-items-center justify-content-end rbt-card-bottom"> 
                                                        <a className="rbt-btn-link rbt-card-title-link" href={`module/${module.id}`}>
                                                            <span className="mr-2">
                                                                Learn More
                                                            </span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-right" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M5 12l14 0"></path><path d="M13 18l6 -6"></path><path d="M13 6l6 6"></path></svg>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {filteredModules.length == 0 && 
                                        <div className="d-flex justify-content-center w-100">
                                            Sorry! No data found
                                        </div>
                                    }
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            
            <div className="rbt-callto-action-area bg-color-white rbt-section-gap">
                <div className="m-5">
                    <div className="row g-5">
                        <div className="col-lg-6 mb-3">
                            <div className="rbt-callto-action callto-action-default bg-color-white rbt-radius shadow-1">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="inner">
                                            <div className="rbt-category mb--20">
                                                <a href="/approaches">Approaches</a>
                                            </div>
                                            <h4 className="title mb--10 custom-h4">
                                                Approaches
                                            </h4>
                                            <p className="mb--15 custom-p">
                                                Find more about the Approaches
                                            </p>
                                            <div className="read-more-btn">
                                                <a className="rbt-btn rbt-switch-btn btn-gradient btn-sm" href="/approaches">
                                                    <span data-text="Join Now">View Now</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                            <div className="rbt-callto-action callto-action-default bg-color-white rbt-radius shadow-1">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className="inner">
                                            <div className="rbt-category mb--20">
                                                <a href="/techniques">Techniques</a>
                                            </div>
                                            <h4 className="title mb--10 custom-h4">
                                                Techniques
                                            </h4>
                                            <p className="mb--15 custom-p">
                                                Find out more about our Techniques
                                            </p>
                                            <div className="read-more-btn">
                                                <a className="rbt-btn rbt-switch-btn btn-gradient btn-sm" href="/techniques">
                                                    <span data-text="Join Now">View Now</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rbt-separator-mid">
                <div className="container">
                    <hr className="rbt-separator m-0"/>
                </div>
            </div>
        </div>
        );
    };
}
function mapStateToProps(state) {
    return {
        model: state.store.module
    };
}
export default connect(mapStateToProps)(TestingPage2);