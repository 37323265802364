import * as actions from '../../actiontypes/video';
import APIServices from '../../services';
import { processResponse } from '../../actions';
import {logout} from './../../actions/auth/index';
import { NotificationManager } from 'react-notifications';

export const addVideo = (model, history) => {
  return dispatch => APIServices.addVideo(model)
    .then(response => {

      console.log(response);

      return response.text();
    })
    .then(data => {

      console.log(data);

      if(data.length <= 0) {
        NotificationManager.error('Something went wrong. please check file..', 'Error');
      }else {
        history.push('/admin/videos/videoList');
        dispatch(getAllVideos());
        NotificationManager.success(`Video added Successfully.`, 'Success');
      }
    })
    .catch(error => {
    });
};


export const addVideoPoster = (model, history) => {
  return dispatch => APIServices.addVideoPoster(model)
    .then(response => {
      return response.text();
    })
    .then(data => {
      if(data.length <= 0) {
        NotificationManager.error('Something went wrong. please check file..', 'Error');
      }else {
        history.push('/admin/videos/videoList');
        dispatch(getAllVideos());
        NotificationManager.success(`Poster added Successfully.`, 'Success');
      }
    })
    .catch(error => {
    });
};


export const getAllVideos = () => {
    return dispatch => APIServices.getAllVideos()
      .then(response => response.json())
      .then(response => {
        dispatch(videosReceived(response));
      })
      .catch(err => {
        console.log(err);
      });
  };

export const videosReceived = (response) => {
return {
    type: actions.VIDEOS,
    payload: {
    response: response
    }
};
};

export const deleteVideoPoster = (id) => {
  return dispatch => APIServices.deleteVideoPoster(id)
    .then(response => response.text())
    .then(data => {
      dispatch(getAllVideos());
      NotificationManager.success('Poster deleted Successfully..', 'Success');
    })
    .catch(err => {

    });
};

export const deleteVideo = (id) => {
  return dispatch => APIServices.deleteVideo(id)
    .then(response => response.text())
    .then(data => {
      dispatch(getAllVideos());
      NotificationManager.success('Video deleted Successfully..', 'Success');
    })
    .catch(err => {

    });
};