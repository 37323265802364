import {combineReducers} from "redux";
import * as actions from "../actiontypes/techniques";

const initialState = {
    isFetching: false,
    firstName: '',
    lastName: '',
    techniques: null,
    addTechniqueImage : "",
    addTechniqueImageUploading : false,
    technique : null,
    techniquesWithDetails: null
    
};

export default function techniques(state = initialState, action) {
    switch (action.type) {
        case actions.TECHNIQUES:
            return {
                ...state,
                techniques: action.payload.response
            };
        case actions.UPLOADING_IMAGE_RESET:
            return {
                ...state, 
                addTechniqueImage : "",
                addTechniqueImageUploading : false
        };
        case actions.UPLOADING_IMAGE_START:
            return {
                ...state,
                addTechniqueImageUploading : true
            };
        case actions.UPLOADING_IMAGE_DONE:
            return {
                ...state,
                addTechniqueImage : window.location.origin + action.payload.response.url,
                addTechniqueImageUploading : false
            };
        case actions.TECHNIQUES_GET:
            return {
                ...state,
                technique : action.payload.response.data
            };
        case actions.TECHNIQUES_WITH_DETAILS:
            return {
                ...state,
                techniquesWithDetails : action.payload.response.data
            };
        case actions.TECHNIQUES_GET_START:
            return {
                ...state,
                technique : null
            };
        default:
            return state;
    }
};