import React, { Component, PropTypes } from 'react';
import { BrowserRouter as Router, Route, Link, hashHistory, Switch } from 'react-router-dom';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { getAllSegments } from "./../../../actions/segments";
import ManageSegment from './manageSegment.js'
import SegmentList from './segmentList.js'


class Segment extends React.Component {
    constructor(props) {
        super(props);
        
      }

    UNSAFE_componentWillMount(){
        
    }

    render() {
     
          return (<div className="segment">
                    <Switch>
                            
                            <Route component={ManageSegment} path="/admin/segments/addSegment"></Route>
                            <Route component={SegmentList} path="/admin/segments/segmentList"></Route>
                            <Route path="/admin/segments/EditSegment/:segmentId" render={routeProps => 
                            <ManageSegment segment={this.props.segment} {...routeProps} />} ></Route>
                           
                    </Switch>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state,
        segment: state.store.segment
    };
}

export default connect(mapStateToProps)(Segment);