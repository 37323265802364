import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllApproachesWithDetails } from "./../../../../actions/approaches";
import LoaderFull from "./../../loader-full"
import { slugify } from '../../../helper';
import '../../../module/seminar.scss';
import $ from 'jquery'; 

class Approach extends React.Component {
    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        this.props.dispatch(getAllApproachesWithDetails());
    }

    componentDidMount = () => {
        this.setPositionForApproach();
    };

    componentDidUpdate = () => {
        this.setPositionForApproach();
    };

    setPositionForApproach(){
        if($(`#${this.props.match.params.approachName}`).length > 0){
            $(`#${this.props.match.params.approachName}`)[0].scrollIntoView({})
        }
        else{
            $(`body`)[0].scrollIntoView({});
        }
    }

    seminarURL(url) {
        if (!url.match(/^[a-zA-Z]+:\/\//))
        {
          url = 'http://' + url;
        }
        return url;
      }

    render() {

        
        const getValidUrl = (url = "") => {
            let newUrl = window.decodeURIComponent(url);
            newUrl = newUrl.trim().replace(/\s/g, "");
        
            if(/^(:\/\/)/.test(newUrl)){
                return `http${newUrl}`;
            }
            if(!/^(f|ht)tps?:\/\//i.test(newUrl)){
                return `http://${newUrl}`;
            }
        
            return newUrl;
        };

        if (this.props.approach && this.props.approach.approachesWithDetails) {
            this.setPositionForApproach();

            let approaches = this.props.approach.approachesWithDetails;

            return (<div className="approach bg-white">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-info h3 p-4 mx-4 text-center border border-left-0 border-top-0 border-right-0">
                            Approaches
                        </div>
                    </div>
                </div>
                <div>
                    {approaches.map((approach, index) => {
                        return (<div key={index} className="p-2 m-3 border border-left-0 border-top-0 border-right-0">
                         
                            <div className="row">
                                <div className="col-md-3 text-center">
                                {approach.approach.images.length > 0 && 
                                    approach.approach.images.map((image, index) => {
                                    return(<img key={index} className="d-flex img-fluid rounded float-left" 
                                        src={image.url} />);
                                    })
                                } 
                                {approach.approach.videos.length > 0 && 
                                    approach.approach.videos.map((video, index) => {
                                    return(<div key={index} className="embed-responsive embed-responsive-16by9 float-left">
                                        <iframe className="embed-responsive-item" src={video.url} allowFullScreen />
                                    </div>);
                                    })
                                } 
                                {
                                    approach.seminars.length > 0 && 
                                    <a href={`#seminar${approach.approach.id}`} className="mt-2" data-toggle="modal" data-target={`#seminar${approach.approach.id}`}> 
                                    <img src={require("../../../../assets/images/webinar_icon.png")}  className="mt-2" width="100" />
                                    </a>
                                }
                                </div>
                                <div className="col-md-9" id={slugify(approach.approach.name)}>
                                    <div className="h4 text-info">
                                        {approach.approach.name}
                                    </div>
                                    <div className="h5 overflow-hidden">
										<p className="" dangerouslySetInnerHTML={{ __html: approach.approach.content }} />
                                    </div>
                                    <div className="h6 overflow-hidden">
                                        Modules : {approach.modules.length > 0 &&
                                                    approach.modules.map((module, index) => {
											return (<Link to={`/module/${module.module.id}`} key={index}>
												{index > 0 && ", "} {module.module.name}
                                                    </Link>);
                                                    })
                                                } 
                                    </div>
                                    <div className="h6 overflow-hidden">
                                        Techniques : {approach.techniques.length > 0 &&
                                                    approach.techniques.map((technique, index) => {
                                            return (<Link to={`/techniques/${slugify(technique.technique.name)}`} key={index}>
												{index > 0 && ", "} {technique.technique.name}
                                                    </Link>);
                                                    })
                                                } 
                                    </div>
                                    <div className="h6 overflow-hidden">
                                        <div className="modal fade " id={`seminar${approach.approach.id}`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-lg" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header text-center">
                                                        <h4 className="modal-title seminar-title w-100 font-weight-bold ds-title">Seminars</h4>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body seminarModal">
                                                    {approach.seminars.length > 0 &&
                                                        approach.seminars.map((seminar, index) => {
                                                             return (<div key={index} className="seminar-info">
                                                                <div className="avatar">
                                                                    <img src={require("../../../../assets/images/webinar_icon.png")} alt="webinar-image" className="img-fluid" onClick={()=> window.open(getValidUrl(seminar.seminar.url), "_blank")} />
                                                                </div>
                                                                <div className="info">
                                                                    <p className="seminarName" onClick={()=> window.open(getValidUrl(seminar.seminar.url), "_blank")}>{seminar.seminar.title}</p>
                                                                    <span className="seminar-detail font-italic">Presenter: {seminar.seminar.author}</span>
                                                                    <span className="seminar-detail">${seminar.seminar.price}</span>
                                                                    <span className="seminar-detail">Use Coupon Code: <strong>{seminar.seminar.seminarCompany.couponCode}</strong></span>
                                                                </div>
                                                                <p className="seminar-detail ml-3">{seminar.seminar.description}</p>
                                                            </div>);
                                                        })
                                                    }
                                                    {
                                                        approach.seminars.length == 0 && 
                                                        <div className="text-primary text-center"> The seminars are not available yet.</div>
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>);
                    })}
                </div>
            </div>
            );
        } else {
            return (<LoaderFull />);
        }

    }
}

function mapStateToProps(state) {
    return {
        state: state,
        approach: state.store.approach
    };
}

export default connect(mapStateToProps)(Approach);