import * as actions from "../../actiontypes/customers";
import APIServices from "../../services";
import { processResponse } from "../../actions";
import {NotificationManager} from 'react-notifications';

export const getAllCustomers = () => {
    return dispatch => APIServices.getAllCustomers()
        .then(response => response.json())
        .then(response => {
            dispatch(customersReceived(response));
        })
        .catch(err => {
            console.log(err);
        })
};

export const customersReceived = (response) => {
    return {
        type: actions.CUSTOMERS,
        payload: {
            response: response
        }
    };
};

export const addCustomer = (model) => {
    console.log("tus",model);
    return dispatch => APIServices.addCustomer(model)
        .then(response => response.json())
        .then(data => {
            dispatch(getAllCustomers());
            NotificationManager.success('Customer added Successfully..', 'Success');
        })
        .catch(err => {
            // dispatch(errorUser("error"))
        })
};
export const updateCustomer = (model) => {
    
    return dispatch => APIServices.updateCustomer(model)
        .then(response => response.json())
        .then(data => {
            dispatch(getAllCustomers());
            NotificationManager.success('Customer updated Successfully..', 'Success');
        })
        .catch(err => {
            
        })
};

export const deleteCustomer = (model) => {
    return dispatch => APIServices.deleteCustomer(model)
        .then(response => response.json())
        .then(data => {
            dispatch(getAllCustomers());
            NotificationManager.success('Customer deleted Successfully..', 'Success');
        })
        .catch(err => {
        })
};

export const getCustomer = (model) => {
   
    return dispatch => APIServices.getCustomer(model)
        .then(processResponse)
        .then(data => {
            dispatch(getCustomerReceived(data))
        })
        .catch(err => {
            // dispatch(errorUser("error"))
        })
};

export const getCustomerStart = () => { 
    return {
        type: actions.CUSTOMERS_GET_START
    };
};

export const getCustomerReceived = (response) => {
    return {
        type: actions.CUSTOMERS_GET,
        payload: {
            response: response
        }
    };
};





