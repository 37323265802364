import React, { Component, PropTypes } from 'react';
import { BrowserRouter as Router, Route, Link, hashHistory, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery'; 
import Login from './login';
import Recover from './recover';
import ResetPassword from './recover/resetPassword';
import Auth from './login/auth';
import Logout from './login/logout';
import Home from './home';
import Notfound from './notfound';
import AdminDashboard from './adminDashboard';
import RedirectPage from './common/redirect';
import { NotificationContainer } from 'react-notifications';
import { getUserInfo } from "./../actions/user";

class App extends Component {

    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        // this.props.dispatch(getUserInfo());
    }


    componentDidMount(){
        $(document).ready(function () {
            // toggle sidebar when button clicked
            $('.sidebar-toggle').on('click', function () {
                $('.sidebar').toggleClass('toggled');
            });

            // auto-expand submenu if an item is active
            var active = $('.sidebar .active');

            if (active.length && active.parent('.collapse').length) {
                var parent = active.parent('.collapse');

                parent.prev('a').attr('aria-expanded', true);
                parent.addClass('show');
            }
        });
    }

    render() {
       
                return (
                    <div>
                        <Switch>
                            <Route exact path='/redirect' component={RedirectPage} />
                            <Route exact path='/login' component={Login} />
                            <Route exact path='/recover' component={Recover} />
                            <Route exact path='/reset-password' component={ResetPassword} />
                            <Route exact path='/logout' component={Logout} />
                            <PrivateRoute path="/admin" component={AdminDashboard} />
                            <PrivateRoute path='/' component={Home} />
                            <Route component={Notfound} />
                        </Switch>
                        <NotificationContainer />
                    </div>
                );
    }
    
    requireAuth() {
        return this.props.auth;
    }

}

//Private router function
const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props =>
                        Auth.IsAuthenticated() === true ? (
                        <Component {...props} />
                    ) : redirectComponents(props) }
      />
    );
  };


function redirectComponents(props) {

    console.log(props.location);

    switch (props.location) {
        case 'recover':
            return <Redirect
                to={{ pathname: "/recover", state: { from: props.location } }}
            />;
        default:
            return <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
            />;
    }
    
}

function mapStateToProps(state) {
    return {
        user: state.store.user
        //auth: state.store.auth.isAuthenticated
    };
}

export default withRouter(connect(mapStateToProps)(App));
