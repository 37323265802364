import React, { } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllCustomers } from "./../../../actions/customers";
import CustomersList from './customerList';
import CustomerHeader from './customerHeader';
import ManageCustomer from './manageCustomer';


class Customer extends React.Component {
    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        this.props.dispatch(getAllCustomers());
    }

    render() {
        return (<div className="customer">
            <Switch>
                <Route component={CustomersList} path="/admin/customer/customerList"></Route>
                <Route component={ManageCustomer} path="/admin/customer/addCustomer"></Route>
                <Route path="/admin/customer/editCustomer/:id" render={routeProps =>
                    <ManageCustomer customer={this.props.customer} {...routeProps} />} ></Route>
                <Route component={CustomerHeader} />
            </Switch>
        </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state,
        customer: state.store.customer
    };
}

export default connect(mapStateToProps)(Customer);